/* eslint-disable */ 
// "initialized": "0"
import { webviewCheck } from './webviewCheck';
function androidFixMono (idLogin) {
    const androidLogin = document.getElementById(idLogin);
    try{
        if (webviewCheck() == 'android_webview') {
            let s;
            const androidBrov = Android.getDisplayCutoutTop();
            
            if (window.devicePixelRatio > 1.0) {
                s = androidBrov / window.devicePixelRatio;
            }
            if (androidBrov != 0) {
                if(androidLogin){
                    androidLogin.style.paddingTop =  s + 'px';
                }
            }
        }
    } catch(err) {
        // console.log(err);
    }
}
export { androidFixMono }