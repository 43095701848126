const lang = {
    "EnterWidth": "Prisijungti su:",
    "Registration": "Registruotis",
    "enter": "Įeiti",
    "EntrWidthEmailOrPhone": "Prisijunkite el. paštu arba telefonu",
    "login": "Autorizacija",
    "NoAkkGoReg": "Neturite paskyros? Registruotis",
    "Password": "Slaptažodis",
    "Name": "vardas",
    "LastName": "Pavardė",
    "Phone": "Telefonas",
    "Email": "Paštas",
    "RepeatPassword": "Pakartokite slaptažodį",
    "Сancel": "Atšaukti",
    "Save": "Sutaupyti",
    "Language": "Kalba",
    "ConfirmThisMail": "Jūsų el. paštas patvirtintas",
    "EMailOrPhone": "Telefonu arba paštu",
    "Saved": "Išsaugota",
    "Error": "Klaida",
    "FillInAllTheFields": "Užpildykite visus laukus",
    "Next": "Toliau",
    "MinPassLength": "Minimalus slaptažodžio ilgis 4 simboliai",
    "ForgotYourPassword": "Pamiršai slaptažodį",
    "RestorePassword": "Atkurti slaptažodį",
    "UserMustBeRegistered": "Vartotojas turi būti registruotas",
    "Confirm": "Patvirtinti",
    "WrongFormat": "Blogas formatas",
    "WrongPassword": "Neteisingas slaptažodis",
    "WrongLoginOrPassword": "neteisingas prisijungimo vardas arba slaptažodis",
    "PhoneAlreadyRegistered": "Telefonas jau registruotas",
    "EmailAlreadyRegistered": "Paštas jau užregistruotas",
    "FillInTheFieldsCorrectly": "Teisingai užpildykite laukus",
    "ThisAccountDoesNotExist": "Šios paskyros nėra",
    "LinkIsNotValid": "Nuoroda negalioja",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Nurodytu vartotojo vardu išsiųstas pranešimas",
    "IncorrectCaptcha": "Neteisingas CAPTCHA kodas",
    "CaptchaIsNotValid": "„Captcha“ nebegalioja",
    "EnterCaptcha": "Įveskite captcha",
    "EnterSMS": "Įveskite kodą iš SMS",
    "SMSCodeIsNoLongerValid": "SMS kodas nebegalioja",
    "SMSCodeIsIncorrect": "SMS kodas neteisingas",
    "MailNotVerifiedLinkTimedOut": "Paštas nepatvirtintas, nuoroda nebegalioja",
    "DemoLogin": "Demonstracinis prisijungimas",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Ar tikrai norite įvesti demonstracinę versiją ir išbandyti?",
    "FillInAdditionalInformation": "Užpildykite papildomą informaciją",
    "ToCompleteRegistration": "Norėdami užbaigti registraciją",
    "LinkThirdPartyServices": "Susieti trečiųjų šalių paslaugas",
    "Complete": "Užbaigti",
    "AccountAlreadyLinked": "Paskyra jau susieta",
    "AccountNotRegistered": "Paskyra neregistruota",
    "NotMyNumber": "Ne mano numeris",
    "Back": "Atgal",
    "numberOfAttemptsToCheckAuth": "Bandymų prisijungti skaičius:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Neteisingas slaptažodis, prisijungimo bandymų skaičius:",
    "TryAgainAfterMinute": "Po minutės bandykite dar kartą",
    "Help": "Palaikymas",
    "y": "Taip",
    "n": "Ne",
    "doYouConfirmEmail": "Ar tikrai norite pakeisti el. pašto adresą?",
    "success": "Sėkmingai",
    "goToAutorize": "Eikite į įgaliojimą",
    "UserEmailAlreadyExists": "Vartotojas su šiuo el. pašto adresu jau egzistuoja",
    "UserPhoneAlreadyExists": "Vartotojas su šiuo telefono numeriu jau yra",
    "TermOfUse": "Naudojimo sąlygos",
    "PrivacyPolicy": "Privatumo politika",
    "accountNotRegistered": "sąskaita neregistruota",
    "wantToReg": "Ar norėtumėte užsiregistruoti?",
    "weSuccessChangeYouEmail": "Sėkmingai pakeitėme jūsų el. pašto adresą",
    "youCanCloseThisWindow": "Galite uždaryti šį langą",
    "countryConfirm": "Šalies patvirtinimas",
    "EmailAlreadyTied": "Paštas jau susietas su kita paskyra",
    "areYourCountry": "ar čia tavo šalis?",
    "EmergencyEntrance": "Senas leidimas",
    "enterCorrectNumber": "Įveskite numerį tarptautiniu formatu",
    "thisPhoneRegister": "Šis telefono numeris jau užregistruotas sistemoje",
    "wantUsePhoneForAuth": "Ar norėtumėte prisijungti naudodami šį telefono numerį?",
    "ok": "Gerai",
    "regAgree": "Užregistruodamas paskyrą sutinku",
    "wantBind": "Norite prisijungti ir susieti paslaugą",
    "bind": "įpareigoti",
    "yoursMailBinded": "Jūsų el. paštas yra esamoje paskyroje",
    "wantUsePhoneAndBind": "jau registruotas sistemoje",
    "notGetDataFromApple": "Nepavyko gauti duomenų iš „Apple“."
}

export default function() {
    return lang;
}