<template>
    <smallModal @escape="$emit('close')">
        <label for="">{{ lang['EnterSMS'] }}</label>
        <b-form-input type="text" :state="smsState" v-model="sms" class="sms-input" autofocus />
        <hr>
        <div class="buttons">
            <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
            <button class="btn btn-success" @click="save">{{ lang['Save'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';
import { store } from '@/store.js';
import { regAPi } from '@/API.js';
const apiList = new regAPi();
export default {
    props: ['personLogin'],
    components: {
        smallModal
    },
    data() {
        return {
            sms: ''
        }
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () => this.save())
    },
    methods: {
        save() {
            try {
                if (this.smsState) {
                    apiList.forgotPasswordPhone({ sms: this.sms, login: this.personLogin, lang: store.lang  }).then(resp => {
                        if (resp.data.status == 'done') {
                            location.href = resp.data.data;
                        } else {
                            if (resp.data.error == 'SMS code dont exist') {
                                window.say.messageError(this.lang['SMSCodeIsNoLongerValid']);
                            } else if (resp.data.error == 'SMS code is incorrect') {
                                window.say.messageError(this.lang['SMSCodeIsIncorrect']);
                            }
                            window.say.messageError(this.lang['Error']);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['FillInTheFieldsCorrectly']);
                }
            } catch(err) {
                // console.log(err)
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        smsState() {
            if (this.sms.length == 6) {
                return true
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>
.buttons{
    display: flex;
    justify-content: space-between;
}
.btn-success, .btn-secondary{
    width: 49%;
}
</style>