<template>
    <div class="container">
        <a href="/"><headloginreg /></a>
        <br>
        <div class="special">
            <div class="currentSpan" @click="goLogin"><b-icon icon="chevron-left" style="margin-right:3px;" font-scale="1.25"></b-icon><span>{{ currentLang === 'ru' ? currentSpan.descRU : currentLang === 'uk' ? currentSpan.descUK : currentSpan.descEN  }}</span></div>
            <div class="specialSelect">
                <SelectLangs @changeLang="changeLang($event)" :utilityLang="currentLang" :limited="language" />
            </div>
        </div>
        <hr>
        <div v-if="currentLang==='ru'">
            <h2>УДАЛЕНИЕ АККАУНТА И СОБРАНЫХ ДАННЫХ О ВАС</h2>
            <div class="info-text">
                <h5 style="text-align:center">Инструкция по удалению аккаунта Skyservice:</h5>
                <p class="text">
                    Чтобы удалить свой аккаунт переходим по ссылке: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                </p>
                <p class="text">
                    Далее авторизуемся в свой аккаунт Skyservice
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/autorization.png" alt="">
                    </div>
                <p class="text">
                    Далее переходим во вкладку "Настройки" по клику в верхнем правом меню:
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/settingsBtn.png" alt="">
                    </div>
                <p class="text">
                    После того как мы перешли во вкладку "Настройки" в левом нижнем углу будеть кнопка "Удалить аккаунт", кликаем ее:
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/delAccBtn.png" alt="">
                    </div>
                <p class="text">
                    Вводим качу с картинки, вводим пароль и кликаем кнопку "Подтвердить"
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/delAccModal.png" alt="">
                    </div>
                <p class="text">
                    После этих действий вы успешно удалите ваш аккаунт Skyservice
                </p>
            </div>
        </div>
        <div v-if="currentLang==='uk'">
            <h2>ВИДАЛЕННЯ АКАУНТУ ТА ЗІБРАНИХ ДАНИХ ПРО ВАС</h2>
            <div class="info-text">
                <h5 style="text-align:center">Інструкція з видалення акаунта Skyservice:</h5>
                <p class="text">
                    Щоб видалити Ваш акаунт переходимо за посиланням: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                </p>
                <p class="text">
                    Далі авторизуємося у свій акаунт Skyservice
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/autorizationUA.png" alt="">
                    </div>
                <p class="text">
                    Далі переходимо у вкладку "Налаштування" по кліку у верхньому правому меню:
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/settingsBtnUA.png" alt="">
                    </div>
                <p class="text">
                    Після того як ми перейшли у вкладку "Налаштування" в лівому нижньому куті буде кнопка "Видалити акаунт", клікаємо по ній:
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/delAccBtnUA.png" alt="">
                    </div>
                <p class="text">
                    Вводимо качу з картинки, вводимо пароль і натискаємо кнопку "Підтвердити"
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/delAccModalUA.png" alt="">
                    </div>
                <p class="text">
                    Після цих дій Ви успішно видалите ваш акаунт Skyservice
                </p>
            </div>
        </div>
        <div v-if="currentLang==='en'">
            <h2>DELETE YOUR ACCOUNT AND DATA ABOUT YOU</h2>
            <div class="info-text">
                <h5 style="text-align:center">Account deletion instruction Skyservice:</h5>
                <p class="text">
                    To delete Your account, follow the link:: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                </p>
                <p class="text">
                    Next, log into your account Skyservice
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/autorizationEN.png" alt="">
                    </div>
                <p class="text">
                    Next, go to the "Settings" tab by clicking in the upper right menu:
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/settingsBtnEN.png" alt="">
                    </div>
                <p class="text">
                    After we went to the "Settings" tab in the lower left corner there will be a "Delete account" button, click it:
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/delAccBtnEN.png" alt="">
                    </div>
                <p class="text">
                    Enter the download from the picture, enter the password and click the "Confirm" button
                </p>
                    <div class="delImg">
                        <img  class="img" src="@/assets/delAccModalEN.png" alt="">
                    </div>
                <p class="text">
                    After these steps, You will successfully delete your Skyservice account
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store.js";
import SelectLangs from '../LangSelectComponent.vue';
import headloginreg from "../heads/HeaderLoginAndReg.vue";
export default {
    components: {
        headloginreg,
        SelectLangs
    },
    data () {
        return {
            currentLang: "",
            language: [
                { name: 'uk', text: 'UK' },
                { name: 'ru', text: 'RU' },
                { name: 'en', text: 'EN' }
            ],
            currentSpan: {
                descUK: 'Авторизація',
                descRU: 'Авторизация',
                descEN: 'Authorization'
            }
        }
    },
    created() {
        // костыль для политик
        switch(store.lang){
            case "ru":  
            case "uk":
            case "en":
                this.currentLang = store.lang;
                break;
            default:
                this.currentLang = 'en';
                break;

        }
    },
    computed: {
    },
    methods: {
        changeLang(l) {
            localStorage.setItem("lang_user", l);
            store.lang = l;
            window.lang = store.langs[store.lang]();
            this.currentLang = l
        },
        goLogin() {
            this.$router.push("/");
        },
    },
};
</script>
<style scoped>
.img{
    max-width: 100%;
}
.text{
    color: black;
    font-weight: bold;
    margin-bottom: 2rem;
}
.delImg{
   margin-bottom: 2rem;
}
h2{ 
    margin-top: 2rem;
    margin-top: 2rem;
    text-align:center;
    color: black;
}
h5{ 
    color: black;
    text-align: center;
    margin-bottom: 2rem;
}
.currentSpan{
    cursor: pointer;
    color:#106090;
    margin-top: 12px;
}
.custom-select{
    cursor: pointer;
}
.special {
    display: flex;
    justify-content: space-between;
    height: 35px;
}
body{
    overflow: scroll;
}
</style>