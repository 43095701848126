<template>
    <smallModal @escape="$emit('close')">
        <div v-if="country" class="confirm-country">
            <span>{{ countryName + " - " + lang['areYourCountry'] }}</span>
        </div>
        <hr>
        <div class="buttons">
            <button class="btn btn-secondary" @click="$emit('n')">{{ lang['n'] }}</button>
            <button class="btn btn-success" @click="save">{{ lang['y'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import smallModal from '../modals/smallModal';
import { keyevent } from '@/main.js';

export default {
    props: ['country'],
    components: {
        smallModal
    },
    data() {
        return {
        }
    },
    mounted(){
        keyevent.$off('enter');
        keyevent.$on('enter', () => {
            this.save();
        });
    },
    methods: {
        save() {
           console.log("save");
           this.$emit("y")
        }
    },
    computed: {
        lang() {
            return window.lang
        },
        countryName(){
          return this.country.englishname
        }
    }
}
</script>
<style scoped>
.btn{
    width: 49%;
}
.confirm-country{
    text-align: center;
}
.buttons{
    display: flex;
    justify-content: space-between;
}
</style>