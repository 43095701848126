const lang = {
    "EnterWidth": "登录：",
    "Registration": "登记",
    "enter": "进来",
    "EntrWidthEmailOrPhone": "通过电子邮件或电话登录",
    "login": "授权",
    "NoAkkGoReg": "没有帐户？登记",
    "Password": "密码",
    "Name": "姓名",
    "LastName": "姓",
    "Phone": "电话",
    "Email": "邮件",
    "RepeatPassword": "重复输入密码",
    "Сancel": "取消",
    "Save": "节省",
    "Language": "语言",
    "ConfirmThisMail": "您的电子邮件已被确认",
    "EMailOrPhone": "电话或邮件",
    "Saved": "已保存",
    "Error": "错误",
    "FillInAllTheFields": "填写所有字段",
    "Next": "更远",
    "MinPassLength": "最小密码长度 4 个字符",
    "ForgotYourPassword": "忘记密码了吗",
    "RestorePassword": "恢复密码",
    "UserMustBeRegistered": "用户必须注册",
    "Confirm": "确认",
    "WrongFormat": "格式错误",
    "WrongPassword": "密码错误",
    "WrongLoginOrPassword": "登录名或密码错误",
    "PhoneAlreadyRegistered": "手机已注册",
    "EmailAlreadyRegistered": "邮件已注册",
    "FillInTheFieldsCorrectly": "正确填写字段",
    "ThisAccountDoesNotExist": "此帐户不存在",
    "LinkIsNotValid": "链接无效",
    "AMessageHasBeenSentToTheSpecifiedLogin": "一条消息已发送到指定的用户名",
    "IncorrectCaptcha": "错误验证码",
    "CaptchaIsNotValid": "验证码不再有效",
    "EnterCaptcha": "输入验证码",
    "EnterSMS": "输入短信验证码",
    "SMSCodeIsNoLongerValid": "短信代码不再有效",
    "SMSCodeIsIncorrect": "短信代码不正确",
    "MailNotVerifiedLinkTimedOut": "邮件未确认，链接已过期",
    "DemoLogin": "演示登录",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "您确定要进入<b>演示</b>试用吗？",
    "FillInAdditionalInformation": "填写附加信息",
    "ToCompleteRegistration": "完成注册",
    "LinkThirdPartyServices": "链接第三方服务",
    "Complete": "去完成",
    "AccountAlreadyLinked": "帐号已关联",
    "AccountNotRegistered": "帐号未注册",
    "NotMyNumber": "不是我的号码",
    "Back": "后退",
    "numberOfAttemptsToCheckAuth": "登录尝试次数：",
    "InvalidPassNumberOfAttemptsToCheckAuth": "密码错误，登录尝试次数：",
    "TryAgainAfterMinute": "请稍后再试",
    "Help": "支持",
    "y": "是的",
    "n": "不是",
    "doYouConfirmEmail": "您确定要更改电子邮件吗？",
    "success": "成功地",
    "goToAutorize": "去授权",
    "UserEmailAlreadyExists": "使用此电子邮件的用户已存在",
    "UserPhoneAlreadyExists": "使用此电话号码的用户已存在",
    "TermOfUse": "使用条款",
    "PrivacyPolicy": "隐私政策",
    "accountNotRegistered": "帐号未注册",
    "wantToReg": "你想注册吗？",
    "weSuccessChangeYouEmail": "我们已成功更改您的电子邮件地址",
    "youCanCloseThisWindow": "您可以关闭此窗口",
    "countryConfirm": "国家确认",
    "EmailAlreadyTied": "邮件已链接到另一个帐户",
    "areYourCountry": "这是你的国家吗？",
    "EmergencyEntrance": "舊授權",
    "enterCorrectNumber": "以國際格式輸入號碼",
    "thisPhoneRegister": "此电话号码已在系统中注册",
    "wantUsePhoneForAuth": "您要使用此电话号码登录吗？",
    "ok": "行",
    "regAgree": "通过注册账户，我接受",
    "wantBind": "想要登录并链接服务",
    "bind": "绑定",
    "yoursMailBinded": "您的电子邮件在现有帐户中",
    "wantUsePhoneAndBind": "已经在系统中注册",
    "notGetDataFromApple": "无法从 Apple 接收数据"
}

export default function() {
    return lang;
}