const lang = {
    "EnterWidth": "İle giriş:",
    "Registration": "Kayıt olmak",
    "enter": "İçeri gel",
    "EntrWidthEmailOrPhone": "E-posta veya telefon ile giriş yapın",
    "login": "yetki",
    "NoAkkGoReg": "Hesabınız yok mu? Kayıt olmak",
    "Password": "Parola",
    "Name": "İsim",
    "LastName": "Soyadı",
    "Phone": "Telefon",
    "Email": "Posta",
    "RepeatPassword": "Şifreyi tekrar girin",
    "Сancel": "İptal etmek",
    "Save": "Kaydetmek",
    "Language": "Dilim",
    "ConfirmThisMail": "E-posta teyit edilmiştir",
    "EMailOrPhone": "Telefon veya posta",
    "Saved": "kaydedildi",
    "Error": "Hata",
    "FillInAllTheFields": "Tüm alanları doldurun",
    "Next": "Daha ileri",
    "MinPassLength": "Minimum şifre uzunluğu 4 karakter",
    "ForgotYourPassword": "Parolanızı mı unuttunuz",
    "RestorePassword": "Parola Yenile",
    "UserMustBeRegistered": "Kullanıcı kayıtlı olmalıdır",
    "Confirm": "Onaylamak",
    "WrongFormat": "Yanlış format",
    "WrongPassword": "yanlış parola",
    "WrongLoginOrPassword": "yanlış giriş veya şifre",
    "PhoneAlreadyRegistered": "Telefon zaten kayıtlı",
    "EmailAlreadyRegistered": "Posta zaten kayıtlı",
    "FillInTheFieldsCorrectly": "Alanları doğru doldurun",
    "ThisAccountDoesNotExist": "Bu hesap mevcut değil",
    "LinkIsNotValid": "Bağlantı geçerli değil",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Belirtilen kullanıcı adına bir mesaj gönderildi",
    "IncorrectCaptcha": "yanlış CAPTCHA",
    "CaptchaIsNotValid": "Captcha artık geçerli değil",
    "EnterCaptcha": "Captcha'yı girin",
    "EnterSMS": "SMS'den kodu girin",
    "SMSCodeIsNoLongerValid": "SMS kodu artık geçerli değil",
    "SMSCodeIsIncorrect": "SMS kodu yanlış",
    "MailNotVerifiedLinkTimedOut": "Posta onaylanmadı, bağlantının süresi doldu",
    "DemoLogin": "Demo girişi",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Denemek için <b> demoya </b> girmek istediğinizden emin misiniz?",
    "FillInAdditionalInformation": "Ek bilgileri doldurun",
    "ToCompleteRegistration": "Kaydı tamamlamak için",
    "LinkThirdPartyServices": "Üçüncü taraf hizmetlerini bağla",
    "Complete": "Tamamlamak",
    "AccountAlreadyLinked": "Hesap zaten bağlı",
    "AccountNotRegistered": "Hesap kayıtlı değil",
    "NotMyNumber": "benim numaram değil",
    "Back": "Geri",
    "numberOfAttemptsToCheckAuth": "Oturum açma denemesi sayısı:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Yanlış şifre, giriş denemesi sayısı:",
    "TryAgainAfterMinute": "Lütfen bir dakika içinde tekrar deneyin",
    "Help": "Destek olmak",
    "y": "Evet",
    "n": "Değil",
    "doYouConfirmEmail": "E-postanızı değiştirmek istediğinizden emin misiniz?",
    "success": "başarıyla",
    "goToAutorize": "Yetkilendirmeye git",
    "UserEmailAlreadyExists": "Bu emaile sahip kullanıcı zaten var",
    "UserPhoneAlreadyExists": "Bu telefon numarasına sahip kullanıcı zaten var",
    "TermOfUse": "Kullanım Şartları",
    "PrivacyPolicy": "Gizlilik Politikası",
    "accountNotRegistered": "hesap kayıtlı değil",
    "wantToReg": "Kayıt olmak ister misiniz?",
    "weSuccessChangeYouEmail": "E-posta adresinizi başarıyla değiştirdik",
    "youCanCloseThisWindow": "Bu pencereyi kapatabilirsiniz",
    "countryConfirm": "Ülke Onayı",
    "EmailAlreadyTied": "Posta zaten başka bir hesaba bağlı",
    "areYourCountry": "bu senin ülken mi?",
    "EmergencyEntrance": "Eski Yetkilendirme",
    "enterCorrectNumber": "Numarayı uluslararası formatta girin",
    "thisPhoneRegister": "Bu telefon numarası zaten sistemde kayıtlı",
    "wantUsePhoneForAuth": "Bu telefon numarasını kullanarak giriş yapmak ister misiniz?",
    "ok": "tamam",
    "regAgree": "Bir hesap kaydederek kabul ediyorum",
    "wantBind": "Oturum açmak ve hizmeti bağlamak istiyorum",
    "bind": "bağlamak",
    "yoursMailBinded": "E-postanız mevcut bir hesapta",
    "wantUsePhoneAndBind": "zaten sistemde kayıtlı",
    "notGetDataFromApple": "Apple'dan veri alınamadı"
}

export default function() {
    return lang;
}