<template>
    <div class="select-block">
        <select class="form-control custom-select" name="lang" v-model="currentLang" @change="editLang()">
            <option v-for="(item, index) in language" :key="index" v-bind:value="item.name">
                {{ item.text }}
            </option>
        </select>
    </div>
</template>

<script>
import { store } from '@/store.js'
export default {
    props: ['limited', 'utilityLang'],
    components: {
       
    },
    data() {
        return {
           currentLang: store.lang,
           language: [
                {
                    name: "uk",
                    text: "UK",
                    description:"Українська"
                },
                {
                    name: "en",
                    text: "EN",
                    description:"English"
                }, 
                {
                    name: "tg",
                    text: "TG",
                    description:"Забони тоҷикӣ"
                }, 
                {
                    name: "tr",
                    text: "TR",
                    description:"Türkçe"
                },
                { 
                    name: "ar", 
                    text: "AR",
                    description:"العربية"
                },
                { 
                    name: "cn", 
                    text: "CN",
                    description:"简体中文"
                },
                { 
                    name: "de", 
                    text: "DE",
                    description:"Deutsch"
                },
                {
                    name: "el",
                    text: "EL",
                    description:"Greek"
                },
                {
                    name: "es",
                    text: "ES",
                    description:"Español"
                }, 
                {
                    name: "et",
                    text: "ET",
                    description:"Eesti keel"
                }, 
                {
                    name: "fi",
                    text: "FI",
                    description:"Suomi"
                }, 
                {
                    name: "fr",
                    text: "FR",
                    description:"Français"
                }, 
                {
                    name: "he",
                    text: "HE",
                    description:"עִבְרִית"
                }, 
                {
                    name: "it",
                    text: "IT",
                    description:"Italiano"
                }, 
                {
                    name: "ka",
                    text: "KA",
                    description:"Georgian"
                }, 
                {
                    name: "km",
                    text: "KM",
                    description:"Khmer"
                }, 
                {
                    name: "lt",
                    text: "LT",
                    description:"Lietuvių kalba"
                }, 
                {
                    name: "lv",
                    text: "LV",
                    description:"Latvian"
                }, {
                    name: "pl",
                    text: "PL",
                    description:"Polski"
                }, {
                    name: "pt",
                    text: "PT",
                    description:"Português"
                }, {
                    name: "ro",
                    text: "RO",
                    description:"Romanian"
                }, 
                {
                    name: "ru",
                    text: "RU",
                    description:"Русский"
                },
                { 
                    name: "be", 
                    text: "BE",
                    description:"Беларуская мова"
                }
            ],
        }
    },
    created(){
        if(this.limited){
            this.language = this.limited
            this.currentLang = this.utilityLang
        }
    },
    methods: {
        editLang() {
            this.$emit('changeLang', this.currentLang)
        }
    },
}
</script>
