<template>
    <portal to="modal" >
        <div class="modal-lair">
            <div class="modal-container">
                <slot></slot>
            </div>
        </div>
    </portal>
</template>

<script>

import { keyevent } from '@/main.js';

export default {
    mounted() {
        keyevent.$off('esc');
        keyevent.$on('esc', () => 
            this.$emit('escape')
        );
    },
}
</script>

<style>
    .modal-lair {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .modal-container {
        min-width: 300px;
        /* width: 50%; */
        background: #fff;
        padding: 15px;
        border-radius: 0.25rem;
    }
    @media screen and (max-width: 700px) {
        .modal-container {
            width: 90%;
        }
    }
</style>