<template>
   <div class="enterReg">
       <headloginreg />
        <div class="container reg-scroll">
            <div class="form-reg" >
                <div class="special">
                    <div @click="goLogin">
                        <b-icon class="chevron" icon="chevron-left" font-scale="1.2"></b-icon>
                        <span class="login-text">{{ lang['login'] }}</span>
                    </div>
                </div>
                <hr>
                <!--
                    при регистрации, нам изначально необходимо узнать номер пользователя
                -->
                <div class="form-block">
                    <label for="person_login_input_regiter">{{ lang['Phone'] }}</label>
                    <div class="inputPlus">
                        <b-form-input
                            class="form-reg_input"
                            type="text"
                            v-model="form.phone"
                            name="login"
                            id="person_login_input_regiter"
                            :state="phoneState"
                            @input="inputEdit"
                        />
                        <span class="plus">+</span>
                    </div>
                    <span v-if="!isValidPhoneFormat" class="red-span">
                        {{ lang['enterCorrectNumber'] }}
                    </span>
                    <button class="btn btn-success" @click="validPhoneFormat">{{ lang['Registration'] }}</button>
                </div>
                <div class="description">
                    <span class="span-agree">{{ lang['regAgree'] }}:</span>
                    <ul>
                        <li class="privacy-policy-term">
                            <a class="privacy-policy-term" href="/privacy-policy">
                            <span >{{ lang['PrivacyPolicy'] }}</span>
                            </a>
                        </li>
                        <li class="privacy-policy-term">
                            <a class="privacy-policy-term" href="/terms-of-use">
                            <span>{{ lang['TermOfUse'] }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <confirmCountry v-if="showConfirmCountry" :country="country" @close="closeCountry()" @n="no" @y="sendPhone"/>
            </div>
        </div>
   </div>
</template>

<script>
import confirmCountry from "@/components/registration/confirmCountry.vue";
import headloginreg from '../heads/HeaderLoginAndReg.vue';
import { store } from '@/store.js'
import { regAPi } from '@/API.js';
import Cookies from 'js-cookie';
import { keyevent } from '@/main.js';

// создаем апи куков с настройкой дэфолтного домен для продакшена
let apiCookies = Cookies;

if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

const apiList = new regAPi();
export default {
    components: {
        headloginreg,
        confirmCountry
    },
    data() {
        return {
            form: {
                phone: '',
                email: ''
            },
            showConfirmCountry: false,
            isValidPhoneFormat: true,
            country: null
        }
    },
    created() {
        const inParams = new URLSearchParams(location.search);

        //Берем email який передали з іншого місця в URL
        try {
            const accountEmail = inParams.get('email');
            if(accountEmail){
                this.form.email = accountEmail;
            }
        }catch(err) {
            console.error(err);
        }

        //Берем номер телефону який передали з іншого місця в URL
        try {
            const accountPhone = inParams.get('phone');
            if(accountPhone){
                this.form.phone = accountPhone.replace('+', '');
            }
        }catch(err) {
            console.error(err);
        }

        if (Cookies.get('token')) {
            document.location.href = "https://cabinet.skyservice.online";
        }

        keyevent.$off('enter');
        keyevent.$on('enter', () => {
            this.validPhoneFormat();
        });

    },
    methods: {
        closeCountry(){
            keyevent.$off('enter')
            keyevent.$on('enter', () => this.validPhoneFormat())
            this.showConfirmCountry = false
        },
        inputEdit(){
            const str = this.form.phone
            this.form.phone = str.replace('+', '');
            this.isValidPhoneFormat = true
        },
        no(){
            this.isValidPhoneFormat = false;
            this.showConfirmCountry = false;
        },
        validPhoneFormat() {
            if (this.phoneState) {

                try{
                    //Костыльчик для Украины что бы не вводить +38
                    if(this.form.phone["0"] == "0"){
                        this.form.phone = "38"+this.form.phone
                    }
                }catch(err){
                    console.error(err)
                }
                const obj = { 
                    phone: this.form.phone,
                    ref_id:  localStorage.getItem('ref_id') != null ? localStorage.getItem('ref_id') : ''
                }
                apiList.phoneFormatValidation(obj).then((resp) => {
                    console.log(resp);
                    if(resp.data.status === "done") {
                        if(resp.data.data.viewModal){
                            this.country = resp.data.data.country;
                            this.showConfirmCountry = true;
                        } else {
                            this.sendPhone();
                        }
                    } else {
                        this.isValidPhoneFormat = false;
                    }
                });
            } else {
                window.say.messageError(this.lang['WrongFormat'])
            }
        },
        // отправки телефона
        sendPhone() {
            this.showConfirmCountry = false 
            try {
                const obj = { 
                    phone: this.form.phone,
                    lang: store.lang,
                    email: this.form.email,
                    ref_id:  localStorage.getItem('ref_id') != null ? localStorage.getItem('ref_id') : ''
                }
                apiList.quickRegistration(obj).then(resp => {
                    if (resp.data.status == 'done') {
                        localStorage.setItem('user_login', this.form.phone);
                        window.say.message(this.lang['Saved']);
                        apiCookies.set("token", resp.data.data.token, {expires: 180});//, {domain: ".skyservice.online"}
                        document.location.href = "https://cabinet.skyservice.online/";
                    } else {
                        this.form.phone = '';
                        window.say.messageError(this.lang['PhoneAlreadyRegistered']);
                    }
                })
                // } else {
                //     window.say.messageError(this.lang['WrongFormat'])
                // }
            } catch(err) {
                // console.log(err)
            }
        },
        // просто переход на страницу логина
        goLogin() {
            this.$router.push('/login')
        },
    },
    computed: {
        lang() {
            return window.lang;
        },
        phoneState(){
            return (this.form.phone && this.form.phone.length >= 4 && !isNaN(this.form.phone)) ? true : false;
        }
    }
}
</script>

<style scoped>
    .inputPlus{
        position: relative;
    }
    .span-agree{
        color: #585858;
    }
    .privacy-policy-term{
        color: #106090;
        cursor: pointer;
    }
    .btn-success{
        width: 100%; 
        margin-bottom: 2rem; 
        margin-top: 1rem
    }
    .red-span{
        font-size: 14px; 
        color: red;
    }
    .form-block{
        padding: 9 15px;
    }
    .login-text{
        color:#106090;
    }
    .chevron{
        color:#106090; 
        margin-right: 3px
    }
    .description{
        font-size: small;
        max-width: 300px;
    }   
    .enterReg{
        position: relative;
    }
    .special {
        position: absolute; 
        left: -10px;
        top: -2px;
        cursor: pointer;
    }
    .plus {
        position: absolute;
        font-size: 20px;
        bottom: 10%;
        left: 10px;
        color: #495057;
    }
    input.form-reg_input {
        margin-bottom: 0 !important;
        padding-left: 1.5rem;
    }
    .reg-block-container {
        padding: 15px;
    }
    .form-reg, .network-reg {
        position: relative;
        padding: 15px;
        /* margin-bottom: 1rem; */
    }
    .reg-scroll {
        height: calc(100vh - 70px);
        overflow: auto;
    }
    @media screen and (max-width: 700px) {
        .reg-block-container {
            padding: 0;
        }
        .form-reg {
            padding: 15px 5px;
            margin-bottom: 3rem;
        }
    }
    #footerLinks{
        margin-left: 2rem;
        position: absolute; 
        bottom:20px; 
        font-size:9pt;
    }
    @media screen and (max-height: 510px) {
        #footerLinks{
            position: relative;
            margin-top: 2rem;
        }
    }
</style>