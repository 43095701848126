<template>
    <smallModal @escape="$emit('close')">
        <div v-if="startModal" ref="bindStart" tabindex="0">
            <span>{{ lang['yoursMailBinded'] }} - <b>{{ this.bindData.currentUserPhone }}</b></span>
            <br>
            <span>{{ lang['wantBind'] }} <b>{{ bindData.socialType }}</b>?</span>
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success" @click="bind">{{ lang['bind'] }}</button>
            </div>
        </div>
        <div v-if="continueModal">
            <div class="usr-icon-login-container">
                <div class="user-icon-div">
                    <img src="@/assets/userSVG.svg" class="account-icon" alt="">
                </div>
                <span class="person-login">{{ bindData.currentUserPhone }}</span>
            </div>
            <hr>
            <div>
                <div>
                    <span>{{ lang['Password'] }}</span>
                    <b-form-input type="password" v-model="password" :state="statePass" id="pass" autofocus />
                </div>
            </div>
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success" @click="save">{{ lang['enter'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';
import Cookies from 'js-cookie';
import { regAPi } from '@/API.js';
const apiList = new regAPi();
let apiCookies = Cookies;
if (process.env.NODE_ENV == 'production') {
  apiCookies = Cookies.withAttributes({ domain: ".skyservice.online" })
}
export default {
    props: ['bindData'],
    components: {
        smallModal
    },
    data() {
        return {
            startModal: true,
            continueModal: false,
            password: ''
        }
    },
    created() {
        this.$nextTick(() => {
            this.$refs.bindStart.focus();
        });
        keyevent.$off('enter');
        keyevent.$on('enter', () => this.startModal ? this.bind() : this.save())
    },
    methods: {
        bind(){
            this.startModal = false
            this.continueModal = true
        },
        save() {
            console.log("this.bindData", this.bindData)
            try {
                if (this.statePass) {
                    if(this.bindData.socialType == "Google"){
                    apiList.bindSocialGoogle({ google_token: this.bindData.currentAccessToken, user_password: this.password }).then(resp => {
                        if (resp.data.status == 'done') {
                            apiCookies.set("token", resp.data.data.token, {expires: 180})
                            document.location.href = "https://cabinet.skyservice.online";
                        } else {
                            if (resp.data.error == "Wrong password") {
                                window.say.messageError(this.lang["WrongPassword"]);
                            } else if (resp.data.error == "Google token is incorrect") {
                                window.say.messageError(this.lang["Error"]);
                            } else {
                                window.say.messageError(this.lang["Error"]);
                            }
                        }
                    })
                    }else if(this.bindData.socialType == "Facebook"){
                    apiList.bindSocialFacebook({ 
                        facebook_token: this.bindData.facebookToken, 
                        facebook_id: this.bindData.facebookId, 
                        user_password: this.password 
                    }).then(resp => {
                        if (resp.data.status == 'done') {
                            apiCookies.set("token", resp.data.data.token, {expires: 180})
                            document.location.href = "https://cabinet.skyservice.online";
                        } else {
                            if (resp.data.error == "Wrong password") {
                                window.say.messageError(this.lang["WrongPassword"]);
                            } else if (resp.data.error == "Facebook token is incorrect") {
                                window.say.messageError(this.lang["Error"]);
                            } else {
                                window.say.messageError(this.lang["Error"]);
                            }
                        }
                    })
                    }else if(this.bindData.socialType == "Apple"){
                        apiList.bindSocialApple({apple_token: this.bindData.currentAccessToken, user_password: this.password })
                        .then(resp => {
                            console.log("resp.data", resp.data)
                            if (resp.data.status == 'done') {
                                apiCookies.set("token", resp.data.data.token, {expires: 180})
                                document.location.href = "https://cabinet.skyservice.online";
                            } else {
                                if (resp.data.error == "Wrong password") {
                                    window.say.messageError(this.lang["WrongPassword"]);
                                } else if (resp.data.error == "Apple token is incorrect") {
                                    window.say.messageError(this.lang["Error"]);
                                } else {
                                    window.say.messageError(this.lang["Error"]);
                                }
                            }
                        })
                    } else {
                        window.say.messageError("Social type error");
                    }

                } else {
                    window.say.messageError(this.lang['MinPassLength']);
                }
            } catch(err) {
                // console.log(err)
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        statePass() {
            return this.password.length >= 4 ? true : false;
        },
    }
}
</script>

<style scoped>
.buttons{
    display: flex;
    justify-content: space-between;
}
.btn-success, .btn-secondary{
    width: 49%;
}
#pass{
    margin-top: 10px;
}
.usr-icon-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.user-icon-div {
    /* border: 1px solid gray; */
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 999px;
    margin-bottom: 1rem;
}
.person-login {
    font-size: 1.3rem;
    font-weight: 500;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.account-icon{
  height: 3rem;
}
</style>