const lang = {
    "EnterWidth": "כניסה דרך:",
    "Registration": "הירשם",
    "enter": "להיכנס",
    "EntrWidthEmailOrPhone": "כניסה באמצעות דואר אלקטרוני או טלפון",
    "login": "הרשאה",
    "NoAkkGoReg": "אין לך חשבון? הירשם",
    "Password": "סיסמה",
    "Name": "שֵׁם",
    "LastName": "שֵׁם מִשׁפָּחָה",
    "Phone": "טֵלֵפוֹן",
    "Email": "דוֹאַר",
    "RepeatPassword": "חזור על הסיסמה",
    "Сancel": "לְבַטֵל",
    "Save": "להציל",
    "Language": "שפה",
    "ConfirmThisMail": "האימייל שלך אושר",
    "EMailOrPhone": "טלפון או דואר",
    "Saved": "שמור",
    "Error": "שְׁגִיאָה",
    "FillInAllTheFields": "מלא את כל השדות",
    "Next": "נוסף",
    "MinPassLength": "אורך סיסמה מינימלי 4 תווים",
    "ForgotYourPassword": "שכחת ססמה",
    "RestorePassword": "שחזר סיסמה",
    "UserMustBeRegistered": "המשתמש חייב להיות רשום",
    "Confirm": "לְאַשֵׁר",
    "WrongFormat": "פורמט שגוי",
    "WrongPassword": "סיסמא לא נכונה",
    "WrongLoginOrPassword": "שם משתמש או סיסמא שגויים",
    "PhoneAlreadyRegistered": "הטלפון כבר רשום",
    "EmailAlreadyRegistered": "הדואר כבר רשום",
    "FillInTheFieldsCorrectly": "מלא את השדות בצורה נכונה",
    "ThisAccountDoesNotExist": "החשבון הזה אינו קיים",
    "LinkIsNotValid": "הקישור אינו תקף",
    "AMessageHasBeenSentToTheSpecifiedLogin": "הודעה נשלחה לכניסה שצוינה",
    "IncorrectCaptcha": "טעות בקאפצ'ה",
    "CaptchaIsNotValid": "Captcha כבר לא תקף",
    "EnterCaptcha": "הזן captcha",
    "EnterSMS": "הזן את הקוד מ-SMS",
    "SMSCodeIsNoLongerValid": "קוד ה-SMS אינו תקף יותר",
    "SMSCodeIsIncorrect": "קוד SMS שגוי",
    "MailNotVerifiedLinkTimedOut": "האימייל לא אושר, זמן הקישור פג",
    "DemoLogin": "כניסה להדגמה",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "האם אתה בטוח שאתה רוצה להיכנס לגרסת הניסיון?",
    "FillInAdditionalInformation": "מלא מידע נוסף",
    "ToCompleteRegistration": "להשלמת הרישום",
    "LinkThirdPartyServices": "קישור שירותי צד שלישי",
    "Complete": "לְהַשְׁלִים",
    "AccountAlreadyLinked": "החשבון כבר מקושר",
    "AccountNotRegistered": "חשבון לא רשום",
    "NotMyNumber": "לא המספר שלי",
    "Back": "חזור",
    "numberOfAttemptsToCheckAuth": "מספר ניסיונות התחברות:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "סיסמה שגויה, מספר ניסיונות התחברות:",
    "TryAgainAfterMinute": "אנא נסה שוב בעוד דקה",
    "Help": "תמיכה טכנית",
    "y": "כן",
    "n": "לא",
    "doYouConfirmEmail": "האם אתה בטוח שברצונך לשנות את האימייל שלך?",
    "success": "בְּהַצלָחָה",
    "goToAutorize": "עבור אל הרשאה",
    "UserEmailAlreadyExists": "משתמש עם אימייל זה כבר קיים",
    "UserPhoneAlreadyExists": "כבר קיים משתמש עם אותו מספר טלפון",
    "TermOfUse": "תנאי שימוש",
    "PrivacyPolicy": "מדיניות הפרטיות",
    "accountNotRegistered": "חשבון לא רשום",
    "wantToReg": "האם תרצה להירשם?",
    "weSuccessChangeYouEmail": "שינינו בהצלחה את כתובת הדואל שלך",
    "youCanCloseThisWindow": "אתה יכול לסגור את החלון הזה",
    "countryConfirm": "אישור מדינה",
    "EmailAlreadyTied": "הדואר כבר מקושר לחשבון אחר",
    "areYourCountry": "זו המדינה שלך?",
    "EmergencyEntrance": "אישור ישן",
    "enterCorrectNumber": "הזן את המספר בפורמט בינלאומי",
    "thisPhoneRegister": "מספר הטלפון הזה כבר רשום במערכת",
    "wantUsePhoneForAuth": "האם תרצה להתחבר באמצעות מספר הטלפון הזה?",
    "ok": "בסדר",
    "regAgree": "על ידי רישום חשבון, אני מסכים",
    "wantBind": "האם אתה רוצה להיכנס לחשבון שלך ולקשר את השירות?",
    "bind": "לְצַלֵם",
    "yoursMailBinded": "האימייל שלך רשום בחשבון קיים",
    "wantUsePhoneAndBind": "כבר רשום במערכת",
    "notGetDataFromApple": "קבלת הנתונים מאפל נכשלה"
}

export default function() {
    return lang;
}