// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVueIcons)

import "icomoon/icomoon.css";

import crisp from '@/crisp.js';
import VueRouter from 'vue-router'
import Registration from './components/registration/Registration.vue';
import TermsOfUse from './components/PrivacyAndTerms/TermsOfUse.vue';
import Login from './components/login/Login';
import ConfirmMail from './components/ConfirmMail.vue';
import WrongPage from './components/WrongPage.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import EmailNotConfirm from './components/emailNotConfirm';
import PrivacyPolicy from './components/PrivacyAndTerms/PrivacyPolicy.vue'
import DeleteAccount from './components/PrivacyAndTerms/DeleteAccount.vue'
import passwordRecovery from './components/PrivacyAndTerms/passwordRecovery.vue'
//import startPage from './components/start/startPage';

//Это не мусор, удалять не надо. используется как глобальная шина
import "@/components/js/keyevent.js"
import { store } from './store.js';

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import Notifications from 'vue-notification';
Vue.use(Notifications);

export const keyevent = new Vue();
export const handleResize = new Vue();
export const scroll = new Vue();

// if (process.env.NODE_ENV == 'production') {
//   // убираем  логи консоли на проде
//   console.log = function(){};
//   console.group = function(){};
//   console.warn = function(){};
// }

window.app.message = function(text) {
    Vue.notify({
        group: 'foo',
        text: text,
        type: 'success',
    });
}

window.app.messageError = function(text) {
    Vue.notify({
        group: 'foo',
        text: text,
        type: 'error'
    });
}

window.app.messageWarn = function(text) {
    Vue.notify({
        group: 'foo',
        text: text,
        type: 'warn'
    });
}

// Инициилизируем крисп чат с настройками аккаунта
window.$crisp = crisp();

//Чат CRISP
window.CRISP_WEBSITE_ID = "69e2a69a-8103-446b-8e09-e9dfb294422c";

//Фиксим баг сдвигания экрана на ios после закрытия клавиатуры
window.app = {}
window.app.main = {}
window.app.main.closekeyboard = function() {
  window.scrollTo(0, 0);
}

window.say = {};
window.say.message = function(text) {
    Vue.notify({
        group: 'foo',
        text: `<b style="font-size: 1.2rem;"> ${ text } </b>`,
        type: 'success',
    });
}

window.say.messageError = function(text) {
    Vue.notify({
        group: 'foo',
        text: `<b style="font-size: 1.2rem;"> ${ text } </b>`,
        type: 'error'
    });
}

const routes = [
  //{ path: '/', component: startPage },
  { path: `/`, component: Login },//login
  { path: '/registration', component: Registration },
  { path: '/confirmmail', component: ConfirmMail },
  { path: '/emailnotconfirm', component: EmailNotConfirm },
  { path: '/forgotpassword', component: ForgotPassword },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/privacy-policy/delete-account', component: DeleteAccount },
  { path: '/privacy-policy/passwordRecovery', component: passwordRecovery },
  { path: '/terms-of-use', component: TermsOfUse },
  { path: '/**', component: WrongPage }
]
const router = new VueRouter({
  routes,
  mode: 'history',
})

Vue.use(VueRouter)
Vue.use(BootstrapVue)


Vue.config.productionTip = false;

// Получаем стартовые данные, язык, странгу и тогда отрисовываем приложение
try {
  let language = "en";
  let changedLang = localStorage.getItem('lang_user');
  
  if(changedLang){
      language = changedLang;
  }else{
      // берем язык из устройства
      language = window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : "en";     
  }

  var browserLang = language.length > 2 ? language.substr(0, 2) : language;
  //Обломчик для російськомовних посіпак
  if(browserLang == 'ru'){
    browserLang = 'uk';
  }
  store.lang = browserLang;
  window.lang = store.langs[store.lang]();
} catch(err) {
  window.lang = store.langs['en']();
  store.lang = "en"
}

window.CRISP_RUNTIME_CONFIG = {
  locale: store.lang
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

