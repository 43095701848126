<template>
  <div>
    <headloginreg />
    <div class="container">
      <div v-if="!successEmail">
        <div class="alert alert-warning">
          <h5 class="warningH5">
            {{ lang['doYouConfirmEmail'] }}
          </h5>
        </div>
        <div class="buttons">
          <button
            class="btn btn-secondary"
            @click="goLogin()"
          >
            {{ lang['n'] }}
          </button>
          <button
            class="btn btn-success"
            @click="confirmEmail()"
          >
            {{ lang['Confirm'] }}
          </button>
        </div>
      </div>
      <div class="imgSuccess" v-if="successEmail">
        <div class="successResultImg">
          <img class="img" src="@/assets/success.png" alt="" />
        </div>
        <div class="successResultSpan">
          <div class="alert alert-success">
            <span class="footText">{{ lang['weSuccessChangeYouEmail'] }} </span>
          </div>
        </div>
        <div class="successResultBut">
          <button class="btn btn-info" @click="goLogin">
            {{ lang['goToAutorize'] }}
          </button>
        </div>
        <div>
          <span class="span-close">{{ lang['youCanCloseThisWindow'] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
  Страница подтверждения почты
 */
import { regAPi } from "@/API.js";
import headloginreg from "./heads/HeaderLoginAndReg.vue";
import { store } from "@/store.js";

const apiList = new regAPi();

export default {
  components: {
    headloginreg,
  },
  data() {
    return {
      successEmail: false,
      uuidToken: "",
      newEmail: "",
      token: "",
    };
  },
  computed: {
    lang() {
      return window.lang
    }
  },
  created() {
    const url = window.location.href;
    const linkParams = new URL(url).searchParams;
    store.lang = linkParams.get("lang");
    window.lang = store.langs[store.lang]();
    this.newEmail = linkParams.get("new_email");
    this.uuidToken = linkParams.get("uuid_token");
    this.token = linkParams.get("token");
  },
  methods: {
    confirmEmail() {
      apiList.checkEmail(this.uuidToken, this.token, this.newEmail, store.lang).then((resp) => {
        if (resp.data.status == "done") {
          this.successEmail = true;
          window.say.message(this.lang['success']);
        } else if (
          resp.data.error == "Account dont exist | ERROR CODE: 889424"
        ) {
          window.say.messageError(this.lang['AccountNotRegistered']);
        } else if (resp.data.error == "Link no longer available") {
          window.say.messageError(this.lang['MailNotVerifiedLinkTimedOut']);
        }
      });
    },
    goLogin() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.successResultImg{
  margin-bottom: 20px;
}
.imgSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.footText {
  font-weight: bold;
  font-size: larger;
}
.container{
    margin-top: 20px;
}
.span-close{
    text-decoration:underline;
    color:grey;
}
.warningH5{
  text-align: center; 
  margin: 5px;
}
.buttons{
  display: flex;
  text-align: center;
}
.btn-secondary{
  width: 100%;
  margin-right: 5px;
}
.btn-success{
  width: 100%;
}
.successResult{
  margin-bottom: 50px;
}
.successResultSpan{
  margin-bottom: 20px;
}
.successResultBut{
  margin-bottom: 20px;
}
</style>