import parser from 'ua-parser-js';
export function webviewCheck() {
    if (window.webkit != null) {
        if (window.webkit.messageHandlers == "undefined") {
        //console.log("not ios webview")
        } else {
            //ще раз перевірим чи точно це не браузер бо тут були приколи
            try{
                var ua = parser(navigator.userAgent);
                if(ua.browser.name == "WebKit"){
                    //console.log("ios webview")
                    return "ios_webview";
                }
            }catch(err){
                console.error(err)
            }
        }
    }
    if (typeof Android === "undefined") {
        //console.log("not android webview")
    } else {
        //console.log("android webview")
        return "android_webview";
    }

    if (typeof window.cefQuery === "undefined") {
        //console.log("not cef")
    } else {
        //console.log("cef")
        return "cef_webview";
    }

    return "browser";
}