<template>
    <div @click="$emit('clickLogo')" id="headerContiner-green">
            <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px"  viewBox="0 0 100 20" enable-background="new 0 0 100 20" xml:space="preserve">
            <path fill="#5cb85c" d="M0.377,13.484h8.466c1.374,0,1.439-1.092,1.439-1.596c0-0.586-0.152-1.257-1.483-1.655L2.776,8.41
                c-0.239-0.063-1.199-0.377-1.81-1.091C0.268,6.521,0.181,5.369,0.181,4.55c0-0.881,0.109-1.95,0.981-2.727
                c0.785-0.711,1.768-0.818,2.51-0.818h8.792v2.203H4.653c-0.392,0-0.851,0.021-1.199,0.336C3.083,3.879,3.062,4.403,3.062,4.676
                c0,0.651,0.196,1.279,1.417,1.657l5.585,1.699c1.024,0.315,1.724,0.63,2.181,1.048c0.917,0.839,1.004,2.141,1.004,2.831
                c0,0.923-0.131,1.952-0.763,2.729c-0.764,0.943-1.724,1.047-2.727,1.047H0.377V13.484z"/>
            <path fill="#5cb85c" d="M14.777,1.005h2.552v14.683h-2.552V1.005z M22.872,15.688l-3.994-4.067
                c-0.328-0.337-0.785-0.902-0.785-1.679c0-0.838,0.479-1.321,0.807-1.677l3.49-3.692h2.967l-4.34,4.488
                c-0.196,0.209-0.371,0.441-0.371,0.797s0.262,0.734,0.546,1.028l4.734,4.803H22.872z"/>
            <polygon fill="#5cb85c" points="31.51,19.273 28.914,19.273 30.682,15.645 25.533,4.571 28.172,4.571 31.881,13.105 35.809,4.613 
                38.405,4.613 "/>
            <path fill="#5cb85c" d="M38.732,14.702h6.633c0.676,0,1.135-0.231,1.354-0.524C46.915,13.904,47,13.275,47,12.898
                c0-0.631-0.259-0.987-0.435-1.155c-0.371-0.333-1.048-0.502-2.727-0.88l-2.007-0.44c-1.768-0.397-2.401-0.942-2.663-1.237
                c-0.502-0.546-0.633-1.238-0.633-1.846c0-0.734,0.174-1.3,0.589-1.803c0.72-0.902,1.703-0.965,2.792-0.965h5.543v0.986h-6
                c-0.261,0-0.917,0.021-1.287,0.378c-0.393,0.378-0.415,1.027-0.415,1.321c0,0.378,0.044,1.028,0.48,1.447
                c0.393,0.378,1.594,0.67,1.985,0.755l2.095,0.461c1.395,0.315,2.4,0.524,3.075,1.049c0.721,0.545,0.83,1.196,0.83,1.932
                c0,0.772-0.153,1.803-1.004,2.367c-0.567,0.377-1.353,0.42-1.81,0.42h-6.677L38.732,14.702L38.732,14.702z"/>
            <path fill="#5cb85c" d="M53.523,15.688c-0.938,0-2.158-0.23-3.053-1.362c-0.896-1.111-0.938-2.517-0.938-4.153
                c0-1.552,0.086-3.439,1.548-4.615c1.046-0.838,2.465-0.986,3.619-0.986c0.81,0,2.009,0.106,2.947,0.63
                c0.459,0.272,1.854,1.196,1.854,3.524c0,0.314-0.022,1.069-0.479,1.447c-0.35,0.293-0.896,0.335-1.224,0.335h-7.046
                c-0.021,0.651,0.021,1.679,0.197,2.265c0.261,0.901,1.047,1.932,2.574,1.932h5.672v0.984H53.523z M57.8,9.521
                c0.505,0,0.479-0.587,0.479-0.734c0-1.089-0.391-1.824-0.784-2.265c-0.829-0.944-1.963-0.965-2.988-0.965
                c-1.155,0-2.401,0.042-3.209,1.343c-0.457,0.734-0.524,1.531-0.544,2.622L57.8,9.521L57.8,9.521z"/>
            <path fill="#5cb85c" d="M61.247,8.389c0-1.132,0.153-2.119,1.024-2.915c0.48-0.44,1.289-0.903,2.838-0.903h0.785v0.987h-0.699
                c-0.633,0-1.592,0.063-2.223,0.775C62.491,6.899,62.38,7.738,62.38,8.64v7.047h-1.134L61.247,8.389L61.247,8.389z"/>
            <path fill="#5cb85c" d="M70.346,14.262c0.064,0.146,0.219,0.461,0.327,0.461c0.106,0,0.263-0.313,0.326-0.461l3.729-9.691h1.158
                L71.871,14.85c-0.305,0.795-0.762,0.965-1.199,0.965s-0.895-0.17-1.201-0.965L65.458,4.571h1.155L70.346,14.262z"/>
            <path fill="#5cb85c" d="M76.762,1.006h1.221v1.552h-1.221V1.006z M76.805,4.571h1.133v11.117h-1.133V4.571z"/>
            <path fill="#5cb85c" d="M84.178,15.688c-1.635,0-2.727-0.631-3.404-1.404c-0.916-1.051-1.047-2.371-1.047-4.007
                c0-1.509,0.088-3.271,1.312-4.466c0.675-0.65,1.788-1.238,3.601-1.238h3.902v0.987h-4.034c-1.854,0-2.989,0.944-3.338,2.265
                c-0.153,0.608-0.219,2.055-0.219,2.6c0,0.735,0.108,1.993,0.393,2.622c0.675,1.468,2.357,1.658,3.402,1.658h4.06v0.985h-4.627
                V15.688z"/>
            <path fill="#5cb85c" d="M94.104,15.688c-0.938,0-2.158-0.23-3.054-1.362c-0.896-1.111-0.938-2.517-0.938-4.153
                c0-1.552,0.088-3.439,1.551-4.615c1.047-0.838,2.466-0.986,3.62-0.986c0.807,0,2.008,0.106,2.946,0.63
                c0.459,0.272,1.854,1.196,1.854,3.524c0,0.314-0.023,1.069-0.48,1.447c-0.349,0.293-0.894,0.335-1.222,0.335h-7.048
                c-0.02,0.651,0.021,1.679,0.197,2.265c0.263,0.901,1.047,1.932,2.574,1.932h5.672v0.984H94.104z M98.381,9.521
                c0.503,0,0.479-0.587,0.479-0.734c0-1.089-0.392-1.824-0.784-2.265c-0.83-0.944-1.964-0.965-2.989-0.965
                c-1.156,0-2.398,0.042-3.206,1.343c-0.459,0.734-0.524,1.531-0.547,2.622L98.381,9.521L98.381,9.521z"/>
            </svg>
    </div>
</template>

<script>
export default {
    updated() {
     
    }
}
</script>

<style>
    #headerContiner-green {
        display: flex; 
        justify-content: center; 
        width: 100%;
        padding-top: 10px;
    }
</style>
