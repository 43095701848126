const lang = {
    "EnterWidth": "ចូល​ជា​មួយ​នឹង:",
    "Registration": "ចុះឈ្មោះ",
    "enter": "ចូលមក",
    "EntrWidthEmailOrPhone": "ចូលតាមរយៈអ៊ីមែលឬទូរស័ព្ទ",
    "login": "ការអនុញ្ញាត",
    "NoAkkGoReg": "មិនមានគណនីមែនទេ? ចុះឈ្មោះ",
    "Password": "ពាក្យសម្ងាត់",
    "Name": "ឈ្មោះ",
    "LastName": "នាមត្រកូល",
    "Phone": "ទូរស័ព្ទ",
    "Email": "សំបុត្រ",
    "RepeatPassword": "ពាក្យសម្ងាត់ម្តងទៀត",
    "Сancel": "បោះបង់",
    "Save": "រក្សាទុក",
    "Language": "ភាសា",
    "ConfirmThisMail": "អ៊ីមែលរបស់អ្នកត្រូវបានបញ្ជាក់",
    "EMailOrPhone": "ទូរស័ព្ទឬសំបុត្រ",
    "Saved": "បានរក្សាទុក",
    "Error": "កំហុស",
    "FillInAllTheFields": "បំពេញវាលទាំងអស់។",
    "Next": "បន្ថែមទៀត",
    "MinPassLength": "ប្រវែងពាក្យសម្ងាត់អប្បបរមា 4 តួអក្សរ",
    "ForgotYourPassword": "ភ្លេចពាក្យសម្ងាត់របស់អ្នក។",
    "RestorePassword": "ស្ដារពាក្យសម្ងាត់",
    "UserMustBeRegistered": "អ្នកប្រើប្រាស់ត្រូវតែចុះឈ្មោះ",
    "Confirm": "បញ្ជាក់",
    "WrongFormat": "ខុស​ទ្រង់ទ្រាយ",
    "WrongPassword": "ពាក្យសម្ងាត់មិនត្រឹមត្រូវ",
    "WrongLoginOrPassword": "ការចូល ឬពាក្យសម្ងាត់ខុស",
    "PhoneAlreadyRegistered": "ទូរស័ព្ទបានចុះឈ្មោះរួចហើយ",
    "EmailAlreadyRegistered": "សំបុត្របានចុះឈ្មោះរួចហើយ",
    "FillInTheFieldsCorrectly": "បំពេញវាលឱ្យបានត្រឹមត្រូវ",
    "ThisAccountDoesNotExist": "គណនីនេះមិនមានទេ។",
    "LinkIsNotValid": "តំណមិនត្រឹមត្រូវទេ។",
    "AMessageHasBeenSentToTheSpecifiedLogin": "សារមួយត្រូវបានផ្ញើទៅកាន់ឈ្មោះអ្នកប្រើប្រាស់ដែលបានបញ្ជាក់",
    "IncorrectCaptcha": "captcha ខុស",
    "CaptchaIsNotValid": "Captcha លែងមានសុពលភាពទៀតហើយ",
    "EnterCaptcha": "បញ្ចូល captcha",
    "EnterSMS": "បញ្ចូលលេខកូដពី SMS",
    "SMSCodeIsNoLongerValid": "លេខកូដ SMS លែងមានសុពលភាពទៀតហើយ",
    "SMSCodeIsIncorrect": "លេខកូដ SMS មិនត្រឹមត្រូវទេ។",
    "MailNotVerifiedLinkTimedOut": "សំបុត្រមិនត្រូវបានបញ្ជាក់ទេ តំណផុតកំណត់",
    "DemoLogin": "ការចូលសាកល្បង",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "តើ​អ្នក​ប្រាកដ​ជា​ចង់​ចូល <b> demo </b> ដើម្បី​សាកល្បង​វា?",
    "FillInAdditionalInformation": "បំពេញពត៌មានបន្ថែម",
    "ToCompleteRegistration": "ដើម្បីបញ្ចប់ការចុះឈ្មោះ",
    "LinkThirdPartyServices": "ភ្ជាប់សេវាកម្មភាគីទីបី",
    "Complete": "ដើម្បី​បញ្ចប់",
    "AccountAlreadyLinked": "គណនីត្រូវបានភ្ជាប់រួចហើយ",
    "AccountNotRegistered": "គណនីមិនបានចុះឈ្មោះ",
    "NotMyNumber": "មិនមែនជាលេខរបស់ខ្ញុំទេ។",
    "Back": "ត្រឡប់មកវិញ",
    "numberOfAttemptsToCheckAuth": "ចំនួននៃការព្យាយាមចូល៖",
    "InvalidPassNumberOfAttemptsToCheckAuth": "ពាក្យសម្ងាត់ខុស ចំនួននៃការព្យាយាមចូល៖",
    "TryAgainAfterMinute": "សូមព្យាយាមម្តងទៀតក្នុងពេលបន្តិចទៀត។",
    "Help": "គាំទ្រ",
    "y": "បាទ",
    "n": "ទេ។",
    "doYouConfirmEmail": "តើអ្នកប្រាកដថាចង់ផ្លាស់ប្តូរអ៊ីមែលរបស់អ្នកទេ?",
    "success": "ដោយជោគជ័យ",
    "goToAutorize": "ចូលទៅកាន់ការអនុញ្ញាត",
    "UserEmailAlreadyExists": "អ្នកប្រើប្រាស់ដែលមានអ៊ីមែលនេះមានរួចហើយ",
    "UserPhoneAlreadyExists": "អ្នកប្រើប្រាស់ដែលមានលេខទូរស័ព្ទនេះមានរួចហើយ",
    "TermOfUse": "ល័ក្ខខ័ណ្ឌនៃការប្រើប្រាស់",
    "PrivacyPolicy": "គោលការណ៍​ភាព​ឯកជន",
    "accountNotRegistered": "គណនីមិនបានចុះឈ្មោះ",
    "wantToReg": "តើអ្នកចង់ចុះឈ្មោះទេ?",
    "weSuccessChangeYouEmail": "យើងបានផ្លាស់ប្តូរអាសយដ្ឋានអ៊ីមែលរបស់អ្នកដោយជោគជ័យ",
    "youCanCloseThisWindow": "អ្នកអាចបិទបង្អួចនេះ។",
    "countryConfirm": "ការបញ្ជាក់ប្រទេស",
    "EmailAlreadyTied": "សំបុត្រត្រូវបានភ្ជាប់ទៅគណនីមួយផ្សេងទៀតរួចហើយ",
    "areYourCountry": "តើនេះជាប្រទេសរបស់អ្នកទេ?",
    "EmergencyEntrance": "ការអនុញ្ញាតចាស់",
    "enterCorrectNumber": "បញ្ចូលលេខជាទម្រង់អន្តរជាតិ",
    "thisPhoneRegister": "លេខទូរស័ព្ទនេះត្រូវបានចុះឈ្មោះក្នុងប្រព័ន្ធរួចហើយ",
    "wantUsePhoneForAuth": "តើអ្នកចង់ចូលដោយប្រើលេខទូរស័ព្ទនេះទេ?",
    "ok": "ok",
    "regAgree": "ដោយចុះឈ្មោះគណនីមួយ ខ្ញុំទទួលយក",
    "wantBind": "ចង់ចូល និងភ្ជាប់សេវាកម្ម",
    "bind": "ចង",
    "yoursMailBinded": "អ៊ីមែលរបស់អ្នកស្ថិតនៅក្នុងគណនីដែលមានស្រាប់",
    "wantUsePhoneAndBind": "បានចុះឈ្មោះរួចហើយនៅក្នុងប្រព័ន្ធ",
    "notGetDataFromApple": "បរាជ័យក្នុងការទទួលទិន្នន័យពី Apple"
}

export default function() {
    return lang;
}