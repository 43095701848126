function rotate(item) {
    const b = document.querySelector(item);
    if (b != null)  {
        b.style.transition = '0.5s';
        b.style.transform = 'rotate(180deg)';
        setTimeout(() => {
            b.style.transition = '';
            b.style.transform = ''
        }, 500)
    }
}

export { rotate }