<template>
    <div>
        <!-- чисто страница редиректа, если кто то вдруг что то левое в адресной строке забъет -->
    </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
    created() {
        if (Cookies.get('token')) {
            document.location.href = "https://cabinet.skyservice.online";
        } else {
            this.$router.push('/');
        }
    }
}
</script>
