const lang = {
    "EnterWidth": "Kirjaudu sisään:",
    "Registration": "Rekisteröidy",
    "enter": "Tulla sisään",
    "EntrWidthEmailOrPhone": "Kirjaudu sähköpostilla tai puhelimitse",
    "login": "Valtuutus",
    "NoAkkGoReg": "Eikö sinulla ole tiliä? Rekisteröidy",
    "Password": "Salasana",
    "Name": "Nimi",
    "LastName": "Sukunimi",
    "Phone": "Puhelin",
    "Email": "Mail",
    "RepeatPassword": "Toista salasana",
    "Сancel": "Peruuttaa",
    "Save": "Tallentaa",
    "Language": "Kieli",
    "ConfirmThisMail": "Sähköpostisi on vahvistettu",
    "EMailOrPhone": "Puhelin tai posti",
    "Saved": "Tallennettu",
    "Error": "Virhe",
    "FillInAllTheFields": "Täytä kaikki kentät",
    "Next": "Edelleen",
    "MinPassLength": "Salasanan minimipituus 4 merkkiä",
    "ForgotYourPassword": "Unohditko salasanasi",
    "RestorePassword": "Palauta salasana",
    "UserMustBeRegistered": "Käyttäjän tulee olla rekisteröitynyt",
    "Confirm": "Vahvistaa",
    "WrongFormat": "Väärä formaatti",
    "WrongPassword": "Väärä salasana",
    "WrongLoginOrPassword": "väärä kirjautumistunnus tai salasana",
    "PhoneAlreadyRegistered": "Puhelin on jo rekisteröity",
    "EmailAlreadyRegistered": "Posti on jo rekisteröity",
    "FillInTheFieldsCorrectly": "Täytä kentät oikein",
    "ThisAccountDoesNotExist": "tätä käyttäjää ei ole olemassa",
    "LinkIsNotValid": "Linkki ei kelpaa",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Viesti on lähetetty määritettyyn käyttäjätunnukseen",
    "IncorrectCaptcha": "Väärä captcha",
    "CaptchaIsNotValid": "Captcha ei ole enää voimassa",
    "EnterCaptcha": "Kirjoita captcha",
    "EnterSMS": "Syötä koodi tekstiviestistä",
    "SMSCodeIsNoLongerValid": "SMS-koodi ei ole enää voimassa",
    "SMSCodeIsIncorrect": "SMS-koodi on väärä",
    "MailNotVerifiedLinkTimedOut": "Postia ei vahvistettu, linkki vanhentunut",
    "DemoLogin": "Demo kirjautuminen",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Haluatko varmasti osallistua <b> demoon </b> kokeillaksesi sitä?",
    "FillInAdditionalInformation": "Täytä lisätiedot",
    "ToCompleteRegistration": "Viimeistele rekisteröinti",
    "LinkThirdPartyServices": "Linkitä kolmannen osapuolen palvelut",
    "Complete": "Saada valmiiksi",
    "AccountAlreadyLinked": "Tili on jo linkitetty",
    "AccountNotRegistered": "Tiliä ei ole rekisteröity",
    "NotMyNumber": "Ei minun numeroni",
    "Back": "Takaisin",
    "numberOfAttemptsToCheckAuth": "Kirjautumisyritysten määrä:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Väärä salasana, kirjautumisyritysten määrä:",
    "TryAgainAfterMinute": "Yritä uudelleen hetken kuluttua",
    "Help": "Tuki",
    "y": "Joo",
    "n": "Ei",
    "doYouConfirmEmail": "Haluatko varmasti vaihtaa sähköpostiosoitteesi?",
    "success": "Onnistuneesti",
    "goToAutorize": "Siirry valtuutukseen",
    "UserEmailAlreadyExists": "Käyttäjä, jolla on tämä sähköpostiosoite, on jo olemassa",
    "UserPhoneAlreadyExists": "Käyttäjä tällä puhelinnumerolla on jo olemassa",
    "TermOfUse": "Käyttöehdot",
    "PrivacyPolicy": "Tietosuojakäytäntö",
    "accountNotRegistered": "tiliä ei ole rekisteröity",
    "wantToReg": "Haluatko rekisteröityä?",
    "weSuccessChangeYouEmail": "Olemme vaihtaneet sähköpostiosoitteesi onnistuneesti",
    "youCanCloseThisWindow": "Voit sulkea tämän ikkunan",
    "countryConfirm": "Maan vahvistus",
    "EmailAlreadyTied": "Mail on jo linkitetty toiseen tiliin",
    "areYourCountry": "onko tämä sinun maasi?",
    "EmergencyEntrance": "Vanha valtuutus",
    "enterCorrectNumber": "Syötä numero kansainvälisessä muodossa",
    "thisPhoneRegister": "Tämä puhelinnumero on jo rekisteröity järjestelmään",
    "wantUsePhoneForAuth": "Haluatko kirjautua sisään tällä puhelinnumerolla?",
    "ok": "ok",
    "regAgree": "Rekisteröimällä tilin hyväksyn",
    "wantBind": "Haluatko kirjautua sisään ja linkittää palveluun",
    "bind": "sitoa",
    "yoursMailBinded": "Sähköpostisi on olemassa olevalla tilillä",
    "wantUsePhoneAndBind": "jo rekisteröity järjestelmään",
    "notGetDataFromApple": "Tietojen vastaanottaminen Applelta epäonnistui"
}

export default function() {
    return lang;
}