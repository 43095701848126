<!-- Компонент для открытия чата на старых АНДРОИДАХ!!! -->
<template>
  <div class="chat-button" @click="toggleChat">
    <b-icon class="icon" icon="messenger"></b-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chatOpen: false
    };
  },
  methods: {
    toggleChat() {
        try {
            // eslint-disable-next-line
            Android.chat_crisp();
        } catch (err) {
            console.log(err)
        }
    }
  }
};
</script>

<style scoped>
.chat-button {
    width: 60px;
    height: 60px;
    bottom: 15px;
    right: 15px;
    position: fixed;
    background-color: #2A2A2A;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.15s ease-in-out;
}

@media (min-width: 768px) {
    /* Применить эти стили только для устройств с шириной экрана 768px и более */
    .chat-button {
        width: 60px;
        height: 60px;
        bottom: 75px;
        right: 75px;
    }
}

.icon {
    width: 33px!important;
    height: 28px!important;
}
</style>