const lang = {
    "EnterWidth": "Sisene:",
    "Registration": "Registreeri",
    "enter": "Tulla sisse",
    "EntrWidthEmailOrPhone": "Logi sisse e-posti või telefoni teel",
    "login": "Autoriseerimine",
    "NoAkkGoReg": "Kas teil pole kontot? Registreeri",
    "Password": "Parool",
    "Name": "Nimi",
    "LastName": "Perekonnanimi",
    "Phone": "Telefon",
    "Email": "Mail",
    "RepeatPassword": "Korda salasõna",
    "Сancel": "Tühista",
    "Save": "Salvesta",
    "Language": "Keel",
    "ConfirmThisMail": "Teie e-post on kinnitatud",
    "EMailOrPhone": "Telefon või post",
    "Saved": "Salvestatud",
    "Error": "Viga",
    "FillInAllTheFields": "Täitke kõik väljad",
    "Next": "Edasi",
    "MinPassLength": "Parooli minimaalne pikkus 4 tähemärki",
    "ForgotYourPassword": "Unustasid salasõna",
    "RestorePassword": "Taasta parool",
    "UserMustBeRegistered": "Kasutaja peab olema registreeritud",
    "Confirm": "Kinnita",
    "WrongFormat": "Vale formaat",
    "WrongPassword": "vale salasõna",
    "WrongLoginOrPassword": "vale sisselogimine või parool",
    "PhoneAlreadyRegistered": "Telefon juba registreeritud",
    "EmailAlreadyRegistered": "Post juba registreeritud",
    "FillInTheFieldsCorrectly": "Täitke väljad õigesti",
    "ThisAccountDoesNotExist": "Seda kontot pole olemas",
    "LinkIsNotValid": "Link ei kehti",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Määratud kasutajanimele on saadetud sõnum",
    "IncorrectCaptcha": "vale captcha",
    "CaptchaIsNotValid": "Captcha ei kehti enam",
    "EnterCaptcha": "Sisestage captcha",
    "EnterSMS": "Sisestage kood SMS-ist",
    "SMSCodeIsNoLongerValid": "SMS kood ei kehti enam",
    "SMSCodeIsIncorrect": "SMS-kood on vale",
    "MailNotVerifiedLinkTimedOut": "Post pole kinnitatud, link aegunud",
    "DemoLogin": "Demo sisselogimine",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Kas olete kindel, et soovite selle proovimiseks siseneda <b> demosse </b> ?",
    "FillInAdditionalInformation": "Täitke lisateave",
    "ToCompleteRegistration": "Registreerimise lõpetamiseks",
    "LinkThirdPartyServices": "Linkige kolmandate osapoolte teenused",
    "Complete": "Lõpetama",
    "AccountAlreadyLinked": "Konto on juba lingitud",
    "AccountNotRegistered": "Konto registreerimata",
    "NotMyNumber": "Mitte minu number",
    "Back": "tagasi",
    "numberOfAttemptsToCheckAuth": "Sisselogimiskatsete arv:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Vale parool, sisselogimiskatsete arv:",
    "TryAgainAfterMinute": "Proovige minuti pärast uuesti",
    "Help": "Toetus",
    "y": "Jah",
    "n": "Mitte",
    "doYouConfirmEmail": "Kas olete kindel, et soovite oma e-posti aadressi muuta?",
    "success": "Edukalt",
    "goToAutorize": "Minge autoriseerimisse",
    "UserEmailAlreadyExists": "Selle e-posti aadressiga kasutaja on juba olemas",
    "UserPhoneAlreadyExists": "Selle telefoninumbriga kasutaja on juba olemas",
    "TermOfUse": "Kasutustingimused",
    "PrivacyPolicy": "Privaatsuspoliitika",
    "accountNotRegistered": "konto registreerimata",
    "wantToReg": "Kas soovite registreeruda?",
    "weSuccessChangeYouEmail": "Oleme edukalt muutnud teie e-posti aadressi",
    "youCanCloseThisWindow": "Saate selle akna sulgeda",
    "countryConfirm": "Riigi kinnitus",
    "EmailAlreadyTied": "Mail on juba lingitud teise kontoga",
    "areYourCountry": "kas see on sinu riik?",
    "EmergencyEntrance": "Vana volitus",
    "enterCorrectNumber": "Sisestage number rahvusvahelises vormingus",
    "thisPhoneRegister": "See telefoninumber on juba süsteemis registreeritud",
    "wantUsePhoneForAuth": "Kas soovite selle telefoninumbriga sisse logida?",
    "ok": "olgu",
    "regAgree": "Konto registreerimisega nõustun",
    "wantBind": "Soovite teenusesse sisse logida ja linkida",
    "bind": "siduda",
    "yoursMailBinded": "Teie e-post on olemasoleval kontol",
    "wantUsePhoneAndBind": "juba süsteemis registreeritud",
    "notGetDataFromApple": "Apple'ilt andmete vastuvõtmine ebaõnnestus"
}

export default function() {
    return lang;
}