// перевіряємо яка версія OS встановлена в користувача
export default function getiOSVersion() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    // Визначаємо версію iOS, якщо це пристрій Apple
    const version = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const currentIOsVersion = parseInt(version[1], 10);
    console.warn("currentIOsVersion >= 13", currentIOsVersion >= 13)
    return !!(currentIOsVersion >= 13)
  } 
  if(navigator.platform.includes("Mac")){
    const macOsVersionMatch = navigator.userAgent.match(/Mac OS X (\d+)[_.](\d+)(?:[_.](\d+))?/);
    if (macOsVersionMatch) {
      const majorVersion = parseInt(macOsVersionMatch[1], 10);
      const minorVersion = parseInt(macOsVersionMatch[2], 10);
      console.warn("macOsVersionMatch >= 10.15", !!((majorVersion == 10 && minorVersion >= 15) || majorVersion > 10))
      return !!((majorVersion == 10 && minorVersion >= 15) || majorVersion > 10);
    }
  }
  // Якщо це не пристрій Apple або не вдалося визначити версію, повертаємо null
  return null;
}