<template>
    <div>
        <headloginreg />
        <div class="container">
            <div class="alert alert-danger">
                <h5>{{ lang['MailNotVerifiedLinkTimedOut'] }}</h5>
            </div>
            <div class="buttonLogin">
                <button class="btn btn-outline-success" @click="goLogin()">{{ lang['Next'] }}</button>
            </div>
        </div>
    </div>
</template>

<script>
/**
    Cтраница подтверждения почты
 */
import headloginreg from './heads/HeaderLoginAndReg.vue';
export default {
    components: {
        headloginreg
    },
    created() {
        const url = window.location.href;
        const linkParams = (new URL(url)).searchParams; 
        // ретерним объект в котором помещаем выдранные из ссылки значения
        const l = linkParams.get('lang');
        console.log(l)
    },
    computed: {
        lang() {
            return window.lang
        }
    },
    methods: {
        goLogin() {
            this.$router.push('/');
        }
    }
}
</script>
<style scoped>
.btn-outline-success{
    width: 100%;
}
.buttonLogin{
    text-align: center;
}
.container{
    margin-top: 10px;
}
</style>