const lang = {
    "EnterWidth": "Entrar com:",
    "Registration": "Registro",
    "enter": "Entrar",
    "EntrWidthEmailOrPhone": "Acesse por e-mail ou telefone",
    "login": "Autorização",
    "NoAkkGoReg": "Não tem uma conta? Registro",
    "Password": "Senha",
    "Name": "Nome",
    "LastName": "Sobrenome",
    "Phone": "Telefone",
    "Email": "Correspondência",
    "RepeatPassword": "Repita a senha",
    "Сancel": "Cancelar",
    "Save": "Salve ",
    "Language": "Linguagem",
    "ConfirmThisMail": "Seu email foi confirmado",
    "EMailOrPhone": "Telefone ou correio",
    "Saved": "Salvou",
    "Error": "Erro",
    "FillInAllTheFields": "Preencha todos os campos",
    "Next": "Mais distante",
    "MinPassLength": "Comprimento mínimo da senha 4 caracteres",
    "ForgotYourPassword": "Esqueceu sua senha",
    "RestorePassword": "Restaurar senha",
    "UserMustBeRegistered": "O usuário deve estar cadastrado",
    "Confirm": "confirme",
    "WrongFormat": "Formato incorreto",
    "WrongPassword": "Senha incorreta",
    "WrongLoginOrPassword": "login ou senha errada",
    "PhoneAlreadyRegistered": "Telefone já cadastrado",
    "EmailAlreadyRegistered": "Correio já cadastrado",
    "FillInTheFieldsCorrectly": "Preencha corretamente os campos",
    "ThisAccountDoesNotExist": "Essa conta não existe",
    "LinkIsNotValid": "O link não é válido",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Uma mensagem foi enviada para o nome de usuário especificado",
    "IncorrectCaptcha": "CAPTCHA errado",
    "CaptchaIsNotValid": "Captcha não é mais válido",
    "EnterCaptcha": "Digite captcha",
    "EnterSMS": "Digite o código do SMS",
    "SMSCodeIsNoLongerValid": "O código SMS não é mais válido",
    "SMSCodeIsIncorrect": "O código SMS está incorreto",
    "MailNotVerifiedLinkTimedOut": "E-mail não confirmado, link expirado",
    "DemoLogin": "Login de demonstração",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Tem certeza de que deseja entrar na <b> demonstração </b> para experimentá-la?",
    "FillInAdditionalInformation": "Preencha as informações adicionais",
    "ToCompleteRegistration": "Para concluir o cadastro",
    "LinkThirdPartyServices": "Vincular serviços de terceiros",
    "Complete": "Completar",
    "AccountAlreadyLinked": "Conta já vinculada",
    "AccountNotRegistered": "Conta não cadastrada",
    "NotMyNumber": "Não é meu número",
    "Back": "Costas",
    "numberOfAttemptsToCheckAuth": "Número de tentativas de login:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Senha incorreta, número de tentativas de login:",
    "TryAgainAfterMinute": "Por favor, tente novamente em um minuto",
    "Help": "Apoio, suporte",
    "y": "Sim",
    "n": "Não",
    "doYouConfirmEmail": "Tem certeza de que deseja alterar seu e-mail?",
    "success": "com sucesso",
    "goToAutorize": "Ir para autorização",
    "UserEmailAlreadyExists": "Já existe usuário com este endereço de email",
    "UserPhoneAlreadyExists": "Já existe um usuário com este número de telefone",
    "TermOfUse": "Termos de uso",
    "PrivacyPolicy": "Política de Privacidade",
    "accountNotRegistered": "conta não cadastrada",
    "wantToReg": "Voce gostaria de se registrar?",
    "weSuccessChangeYouEmail": "Alteramos seu endereço de e-mail com sucesso",
    "youCanCloseThisWindow": "Você pode fechar esta janela",
    "countryConfirm": "Confirmação do país",
    "EmailAlreadyTied": "O e-mail já está vinculado a outra conta",
    "areYourCountry": "este é o seu país?",
    "EmergencyEntrance": "Autorização antiga",
    "enterCorrectNumber": "Digite o número no formato internacional",
    "thisPhoneRegister": "Este número de telefone já está cadastrado no sistema",
    "wantUsePhoneForAuth": "Deseja fazer login usando este número de telefone?",
    "ok": "ok",
    "regAgree": "Ao registrar uma conta, eu aceito",
    "wantBind": "Deseja entrar e vincular o serviço",
    "bind": "ligar",
    "yoursMailBinded": "Seu e-mail está em uma conta existente",
    "wantUsePhoneAndBind": "já cadastrado no sistema",
    "notGetDataFromApple": "Falha ao receber dados da Apple"
}

export default function() {
    return lang;
}