const lang = {
    "EnterWidth": "Zaloguj się z:",
    "Registration": "Zarejestrować",
    "enter": "Wejść",
    "EntrWidthEmailOrPhone": "Zaloguj się przez e-mail lub telefon",
    "login": "Upoważnienie",
    "NoAkkGoReg": "Nie masz konta? Zarejestrować",
    "Password": "Hasło",
    "Name": "Nazwać",
    "LastName": "Nazwisko",
    "Phone": "Telefon",
    "Email": "Poczta",
    "RepeatPassword": "Powtórz hasło",
    "Сancel": "Anulować",
    "Save": "Ratować",
    "Language": "Język",
    "ConfirmThisMail": "Twój email został potwierdzony",
    "EMailOrPhone": "Telefon lub poczta",
    "Saved": "Zapisane",
    "Error": "Błąd",
    "FillInAllTheFields": "Wypełnij wszystkie pola",
    "Next": "Dalej",
    "MinPassLength": "Minimalna długość hasła 4 znaków",
    "ForgotYourPassword": "Zapomniałeś hasła",
    "RestorePassword": "Przywróć hasło",
    "UserMustBeRegistered": "Użytkownik musi być zarejestrowany",
    "Confirm": "Potwierdzać",
    "WrongFormat": "Zły format",
    "WrongPassword": "niepoprawne hasło",
    "WrongLoginOrPassword": "zły login lub hasło",
    "PhoneAlreadyRegistered": "Telefon już zarejestrowany",
    "EmailAlreadyRegistered": "Poczta już zarejestrowana",
    "FillInTheFieldsCorrectly": "Poprawnie wypełnij pola",
    "ThisAccountDoesNotExist": "to konto nie istnieje",
    "LinkIsNotValid": "Link jest nieprawidłowy",
    "AMessageHasBeenSentToTheSpecifiedLogin": "Wiadomość została wysłana do podanej nazwy użytkownika",
    "IncorrectCaptcha": "błędny kod weryfikacyjny",
    "CaptchaIsNotValid": "Captcha nie jest już ważny",
    "EnterCaptcha": "Wpisz captcha",
    "EnterSMS": "Wpisz kod z SMS-a",
    "SMSCodeIsNoLongerValid": "Kod SMS jest już nieważny",
    "SMSCodeIsIncorrect": "Kod SMS jest nieprawidłowy",
    "MailNotVerifiedLinkTimedOut": "Wiadomość nie potwierdzona, link wygasł",
    "DemoLogin": "Logowanie do wersji demonstracyjnej",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Czy na pewno chcesz wziąć udział w <b> demo </b> , aby to wypróbować?",
    "FillInAdditionalInformation": "Uzupełnij dodatkowe informacje",
    "ToCompleteRegistration": "Aby zakończyć rejestrację",
    "LinkThirdPartyServices": "Połącz usługi stron trzecich",
    "Complete": "Ukończyć",
    "AccountAlreadyLinked": "Konto już połączone",
    "AccountNotRegistered": "Konto niezarejestrowane",
    "NotMyNumber": "Nie mój numer",
    "Back": "Z powrotem",
    "numberOfAttemptsToCheckAuth": "Liczba prób logowania:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Błędne hasło, liczba prób logowania:",
    "TryAgainAfterMinute": "Spróbuj ponownie za minutę",
    "Help": "Wsparcie",
    "y": "tak",
    "n": "Nie",
    "doYouConfirmEmail": "Czy na pewno chcesz zmienić swój adres e-mail?",
    "success": "Z powodzeniem",
    "goToAutorize": "Przejdź do autoryzacji",
    "UserEmailAlreadyExists": "Użytkownik z tym adresem email już istnieje",
    "UserPhoneAlreadyExists": "Użytkownik o tym numerze telefonu już istnieje",
    "TermOfUse": "Warunki korzystania",
    "PrivacyPolicy": "Polityka prywatności",
    "accountNotRegistered": "konto niezarejestrowane",
    "wantToReg": "Chcesz się zarejestrować?",
    "weSuccessChangeYouEmail": "Pomyślnie zmieniliśmy Twój adres e-mail",
    "youCanCloseThisWindow": "Możesz zamknąć to okno",
    "countryConfirm": "Potwierdzenie kraju",
    "EmailAlreadyTied": "Poczta jest już połączona z innym kontem",
    "areYourCountry": "czy to twój kraj?",
    "EmergencyEntrance": "Stara autoryzacja",
    "enterCorrectNumber": "Wprowadź numer w formacie międzynarodowym",
    "thisPhoneRegister": "Ten numer telefonu jest już zarejestrowany w systemie",
    "wantUsePhoneForAuth": "Czy chcesz się zalogować przy użyciu tego numeru telefonu?",
    "ok": "Ok",
    "regAgree": "Rejestrując konto, akceptuję",
    "wantBind": "Chcesz się zalogować i połączyć usługę",
    "bind": "wiązać",
    "yoursMailBinded": "Twój adres e-mail znajduje się na istniejącym koncie",
    "wantUsePhoneAndBind": "już zarejestrowany w systemie",
    "notGetDataFromApple": "Nie udało się odebrać danych od Apple"
}

export default function() {
    return lang;
}