const lang = {
    "EnterWidth": "Войти через:",
    "Registration": "Зарегистрироваться",
    "enter": "Войти",
    "EntrWidthEmailOrPhone": "Войти через почту или телефон",
    "login": "Авторизация",
    "NoAkkGoReg": "Нет аккаунта? Зарегистрируйтесь",
    "Password": "Пароль",
    "Name": "Имя",
    "LastName": "Фамилия",
    "Phone": "Телефон",
    "Email": "Почта",
    "RepeatPassword": "Повторить пароль",
    "Сancel": "Отмена",
    "Save": "Сохранить",
    "Language": "Язык",
    "ConfirmThisMail": "Ваш e-mail подтвержден",
    "EMailOrPhone": "Телефон или почта",
    "Saved": "Сохранено",
    "Error": "Ошибка",
    "FillInAllTheFields": "Заполните все поля",
    "Next": "Далее",
    "MinPassLength": "Минимальная длина пароля 4 символов",
    "ForgotYourPassword": "Забыли пароль",
    "RestorePassword": "Восстановить пароль",
    "UserMustBeRegistered": "Пользователь должен быть зарегистрирован",
    "Confirm": "Подтвердить",
    "WrongFormat": "Неверный формат",
    "WrongPassword": "Неверный пароль",
    "WrongLoginOrPassword": "Неверный логин или пароль",
    "PhoneAlreadyRegistered": "Телефон уже зарегистрирован",
    "EmailAlreadyRegistered": "Почта уже зарегистрирована",
    "FillInTheFieldsCorrectly": "Корректно заполните поля",
    "ThisAccountDoesNotExist": "Данный аккаунт не существует",
    "LinkIsNotValid": "Ссылка не действительна",
    "AMessageHasBeenSentToTheSpecifiedLogin": "На указанный логин отправлено сообщение",
    "IncorrectCaptcha": "Неправильная капча",
    "CaptchaIsNotValid": "Капча больше недействительна",
    "EnterCaptcha": "Введите капчу",
    "EnterSMS": "Введите код из СМС",
    "SMSCodeIsNoLongerValid": "СМС код уже не действителен",
    "SMSCodeIsIncorrect": "СМС код неверный",
    "MailNotVerifiedLinkTimedOut": "Почта не подтверждена, истекло время ссылки",
    "DemoLogin": "Демо вход",
    "AreYouSureYouWantToEnterTheDemoForEvaluation": "Вы действительно хотите войти в демонстрационную версию для ознакомления?",
    "FillInAdditionalInformation": "Заполнить дополнительную информацию",
    "ToCompleteRegistration": "Для завершения регистрации",
    "LinkThirdPartyServices": "Привязать сторонние сервисы",
    "Complete": "Завершить",
    "AccountAlreadyLinked": "Аккаунт уже привязан",
    "AccountNotRegistered": "Аккаунт не зарегистрирован",
    "NotMyNumber": "Не мой номер",
    "Back": "Назад",
    "numberOfAttemptsToCheckAuth": "Количество попыток для входа:",
    "InvalidPassNumberOfAttemptsToCheckAuth": "Неверный пароль, количество попыток для входа:",
    "TryAgainAfterMinute": "Повторите попытку через минуту",
    "Help": "Техподдержка",
    "y": "Да",
    "n": "Нет",
    "doYouConfirmEmail": "Вы действительно хотите изменить вашу электронную почту?",
    "success": "Успешно",
    "goToAutorize": "Перейти к авторизации",
    "UserEmailAlreadyExists": "Пользователь с такой почтой уже существует",
    "UserPhoneAlreadyExists": "Пользователь с таким телефоном уже существует",
    "TermOfUse": "Условия использования",
    "PrivacyPolicy": "Политика конфиденциальности",
    "accountNotRegistered": "аккаунт не зарегистрирован",
    "wantToReg": "Желаете зарегистрироваться?",
    "weSuccessChangeYouEmail": "Мы успешно изменили ваш адрес электронной почты",
    "youCanCloseThisWindow": "Вы можете закрыть это окно",
    "countryConfirm": "Подтверждение страны",
    "EmailAlreadyTied": "Почта уже привязана к другому аккаунту",
    "areYourCountry": "это ваша страна?",
    "EmergencyEntrance": "Старая авторизация",
    "enterCorrectNumber": "Введите номер в международном формате",
    "thisPhoneRegister": "Такой номер телефона уже зарегистрирован в системе",
    "wantUsePhoneForAuth": "Желаете авторизироваться используя этот номер телефона?",
    "ok": "Ок",
    "regAgree": "Регистрируя аккаунт, я принимаю",
    "wantBind": "Хотите войти в аккаунт и привязать сервис",
    "bind": "Привязать",
    "yoursMailBinded": "Ваша почта указана в существующем аккаунте",
    "wantUsePhoneAndBind": "уже зарегистрирован в системе",
    "notGetDataFromApple": "Не удалось получить данные от Apple"
}

export default function() {
    return lang;
}