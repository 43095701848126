let s
export default function Initialize() {
  // Удаляем существующий экземлпяр
  if (s instanceof HTMLElement)
    s.remove()
  let arr = [];
  (function(){
    let d = document;
    s = d.createElement("script");
    s.src="https://client.crisp.chat/l.js";
    s.async=1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  arr.push(["do", "chat:hide"])
  arr.push(["on", "message:received", function(){
    window.$crisp.push(["do", "chat:show"])
  }])
  arr.push(["config", "color:theme", ["black"]])
  arr.push(["config", "show:operator:count", [false]])
  return arr;
}