<template>
  <div id="login" ref="loginRef">
    <headloginreg @clickLogo="devMode()" v-show="showLoginForm"/>
    <div id="loginContainerBox" class="container">
      <div v-if="showLoginForm" class="reg-block-container">
        <hr />
        <!-- обычная форма логина -->
        <div class="login-container">
          
          <!-- язык -->
          <div class="langSelector">
            <SelectLangs @changeLang="changeLang($event)" />
          </div>

          <div class="login-form-container">
            <div>
              <label for="login">{{ lang['EntrWidthEmailOrPhone'] }}</label>
              <b-form-input :state="loginState" type="email" v-model="form.login" name="login" id="person_login_input" ref="loginInput"/>
            </div>

            <button class="btn btn-outline-success loginButton" @click="btnLogin">
              {{ lang['login'] }}
            </button>
          </div>
        </div>
        
        <!-- ↓↓↓ СОЦ СЕТИ СНИЗУ ↓↓↓ -->

        <div class="network-reg">
          <hr />
          <div id="enterWidth">
            <span>{{ lang['EnterWidth'] }}</span>
          </div>

          <div class="network-div-container">
            <div class="network-div" v-if="false">
              <button class="btn" @click="facebookLogin">
                <svg class="network-img" width="50" height="50" viewBox="0 0 80 80" fill="#1876d2" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M80 40C80 17.9084 62.0916 0 40 0C17.9084 0 0 17.9084 0 40C0 59.9747 14.6418 76.5284 33.7778 79.5173V51.5556H23.5556V40H33.7778L33.7844 31.1876C33.7844 21.1627 39.7218 15.6022 48.8587 15.6022C53.2347 15.6022 57.7778 16.4444 57.7778 16.4444V26.2222H52.7684C47.7996 26.2222 46.2129 29.3333 46.2129 32.4969L46.2222 40H57.3333L55.5556 51.5556H46.2222V79.5173C65.3587 76.5284 80 59.9747 80 40" style="fill: var(--base-blue)"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M55.5555 51.5554L57.3333 39.9998H46.2222L46.2129 32.4967C46.2129 29.3332 47.7995 26.2221 52.7684 26.2221H57.7778V16.4443C57.7778 16.4443 53.2347 15.6021 48.8587 15.6021C39.7218 15.6021 33.7844 21.1625 33.7844 31.1874L33.7778 39.9998H23.5555V51.5554H33.7778V79.5172C35.8053 79.8341 37.8831 79.9998 40 79.9998C42.1169 79.9998 44.1947 79.8341 46.2222 79.5172V51.5554H55.5555Z" style="fill: white"></path></svg>
              </button>
            </div>
            <div class="network-div">
              <button class="btn" @click="googleLogin">
                <svg class="network-img" xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 24 24" width="50"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
              </button>
            </div>
            <div v-if="!telegramOff" class="network-div">
              <button class="btn" @click="telegramLogin">
                <svg class="network-img" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#2aabee" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/> </svg>
              </button>
            </div>
            <div v-if="appleSign" class="network-div">
              <button class="btn" @click="signInWithApple">
                <svg class="apple-btn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="50" height="50" x="0" y="0" viewBox="0 0 22.773 22.773" style="enable-background:new 0 0 512 512" xml:space="preserve"><g><path d="M15.769 0h.162c.13 1.606-.483 2.806-1.228 3.675-.731.863-1.732 1.7-3.351 1.573-.108-1.583.506-2.694 1.25-3.561C13.292.879 14.557.16 15.769 0zM20.67 16.716v.045c-.455 1.378-1.104 2.559-1.896 3.655-.723.995-1.609 2.334-3.191 2.334-1.367 0-2.275-.879-3.676-.903-1.482-.024-2.297.735-3.652.926h-.462c-.995-.144-1.798-.932-2.383-1.642-1.725-2.098-3.058-4.808-3.306-8.276v-1.019c.105-2.482 1.311-4.5 2.914-5.478.846-.52 2.009-.963 3.304-.765.555.086 1.122.276 1.619.464.471.181 1.06.502 1.618.485.378-.011.754-.208 1.135-.347 1.116-.403 2.21-.865 3.652-.648 1.733.262 2.963 1.032 3.723 2.22-1.466.933-2.625 2.339-2.427 4.74.176 2.181 1.444 3.457 3.028 4.209z" fill="#000000" opacity="1" data-original="#000000"/></g></svg>
              </button>
            </div>
          </div>
        </div>

        <hr />
        <!-- перейти к регистрации или в демо версию-->
        <div>
          <span @click="goReg" class="go-registration">
            {{ lang['NoAkkGoReg'] }}
          </span>

          <span @click="goDemo" class="demo-span">
            {{ lang['DemoLogin'] }}
          </span>

          <span v-if="showEmergencyEntrance > 4" @click="goEmergencyEntrance" class="emergency-entrance-span">
            {{ lang['EmergencyEntrance'] }}
          </span>
        </div>

        <!-- <vue-telegram-login 
          mode="redirect" 
          telegram-login="SkyserviceAuthbot" 
          redirect-url="https://auth.skyservice.online/" /> -->

        <div id="footerLinks">
          <a class="privacy-policy-term" href="/privacy-policy">
            <span >{{ lang['PrivacyPolicy'] }}</span>
          </a>
          |
          <a href="/terms-of-use" class="privacy-policy-term">
            <span>{{ lang['TermOfUse'] }}</span>
          </a>
        </div>

      </div>

      <!--если ты уже авторизирован показываем спинер и ждем переброса на кабинет-->
      <div v-if="!showLoginForm">
        <loader></loader>
      </div>
    </div>

    <!-- Компонент ввода и проверки пароля для авторизации -->
    <loginPassword v-if="showLoginPass" @close="closeLoginPass()" @sendPass="sendPass" @goToCaptch="goToCaptch"
      :registered="this.registered" :personLogin="form.login"></loginPassword>

    <!-- Авторизация с помощью Google, Facebook, Telegram, Apple -->
    <loginServicesGoogle v-if="showLoginServiceGoogle || showGoogleAndroid" @close="closeGoogleModal()" :serviceGoogleToken="serviceGoogleToken" ></loginServicesGoogle>

    <loginServicesFB v-if="showLoginServiceFacebook || showFacebookAndroid" @close="closeFacebookModal()" :serviceFbId="serviceFacebookUserID" :serviceFbToken="serviceFacebookToken" ></loginServicesFB>
    
    <loginServicesTg v-if="showLoginServiceTelegram || showTelegramAndroid" @goToAuth="openAuthModal($event)" @close="closeTelegramModal()" :serviceTgData="serviceTgData" ></loginServicesTg>
    
    <loginServicesApple v-if="showLoginServiceApple" @goToAuth="openAuthModal($event)" @close="closeAppleModal()" :serviceAppleToken="serviceAppleToken"></loginServicesApple>
    
    <!-- привязка соц сетей если есть почта юзера -->
    
    <continueBindSocials v-if="showBindSocials" @close="closeBindModal()" :bindData="bindData"></continueBindSocials>

    <!-- если юзер логинелся через телефон и при этом забыл пароль, то после нажатия - "Забыл пароль"
      вызываем модалку с капчей и после этого смс  -->   
    <forgotcaptcha :personLogin="form.login" v-if="showForgotCaptcha" @getSms="getSms"
      @close="closeCaptcha()" />
    <!-- Компонент получения смс -->
    <sms :personLogin="form.login" v-if="showSms" @close="closeSmsModal()" />
    <!-- Компонент получения смс -->
    <sms :personLogin="form.login" v-if="showSms" @close="closeSmsModal()" />
    <!-- Компонент кнопки для вызова чата на старых андроидах -->
    <CrispChatButton v-if="showCrispButton" @close="showCrispButton = false" />
  </div>
</template>

<script>
import { store } from "@/store.js";
import { keyevent } from '@/main.js';
import { webviewCheck } from "../js/webviewCheck";
import getiOSVersion from "../js/getiOSVersion"
import SelectLangs from '../LangSelectComponent.vue';
import forgotcaptcha from "./forgotCaptcha";
import sms from "./getSMS";
import loginPassword from "./loginPassword.vue";
import loginServicesApple from "./loginServicesApple.vue";
import loginServicesFB from "./loginServicesFB.vue";
import loginServicesGoogle from "./loginServicesGoogle.vue";
import loginServicesTg from "./loginServicesTg.vue";
import continueBindSocials from "./continueBindSocials.vue";
import headloginreg from "../heads/HeaderLoginAndReg";
import Cookies from 'js-cookie';
import loader from '@/components/Loader';
import CrispChatButton from "@/components/CrispChatButton.vue" // компонент нативной кнопки чата для старых андроидов

import { MoreURL } from "@/API.js";
const moreURLs = new MoreURL();

import { regAPi } from "@/API.js";
const apiList = new regAPi();

// создаем апи куков с настройкой дэфолтного домен для продакшена
let apiCookies = Cookies;
if (process.env.NODE_ENV == 'production') {
  apiCookies = Cookies.withAttributes({ domain: ".skyservice.online" })
}

export default {
  components: {
    SelectLangs,
    loginPassword,
    headloginreg,
    loginServicesFB,
    loginServicesGoogle,
    loginServicesTg,
    continueBindSocials,
    sms,
    forgotcaptcha,
    loader,
    CrispChatButton,
    loginServicesApple
  },
  data() {
    return {
      closeTest: false,
      registered: null,
      showEmergencyEntrance:0,
      form: {
        login: "",
        password: "",
      },
      google : 'google',
      facebook : 'facebook',
      telegram: 'telegram',
      serviceTgData: {},
      showLoginServiceGoogle: false,
      showLoginServiceFacebook: false,
      showLoginServiceTelegram: false,
      showLoginServiceApple: false,
      showLoginPass: false,
      showForgotCaptcha: false,
      showSms: false,
      googleClient: null,
      telegramOff: false,
      facebookOff: false,
      bindData: {
        socialType:'',
        currentAccessToken: '',
        currentUserPhone: '',
        facebookId: '',
        facebookToken: ''
      },
      showBindSocials: false,
      showCrispButton: false,
      // test: 'test commit'
    };
  },
  created() {
    // выключаем соц сети (временно)

    //              google 	telegram 	facebook	
    // Браузер	      1	       1	       0	
    // Android   	    1	       0	       0	
    // Windows   	    1	       1	       0	
    // ios           	1	       0	       0	

    // слухачі для авторизації через apple для ios додатків
    window.ioshttpsender = {
      AppleIDSignInOnSuccess: (response) => {
        try{
          const responseData = JSON.parse(response)
          const appleToken = responseData.identityToken;
          this.addApple(appleToken)
        } catch (err) {
          window.say.messageError(this.lang['Error']);
        }
      },
      AppleIDSignInOnFailure: (error) => {
        console.error(error);
        window.say.messageError(this.lang['notGetDataFromApple']);
      },
      // Отримання і встановлення версії додатку IOS
      setAppVersion(appData){
        let data = JSON.parse(appData)
        const {osVersion, deviceModel, appVersion} = data
        store.appInfoData = {
          os: osVersion,
          appVersion: appVersion,
          device: deviceModel
        }
      }
    }

    if(webviewCheck() == "android_webview"){
      this.telegramOff = true;
      //this.facebookOff = true;
    } else if(webviewCheck() == "cef_webview"){
      this.facebookOff = true;
    } else if (webviewCheck() == "ios_webview"){
      this.telegramOff = true;
      this.facebookOff = true;
    } else {
      this.facebookOff = true;
    }
    // androidFixMono('login');
    // если юзер типа самый умный и будучи залогиненым сотрет в адресной строке /person, то его редиректит обратно на страницу персоны
    if (Cookies.get("token")) {
      /* eslint-disable */
      if (process.env.NODE_ENV != 'production') {
          Cookies.set(`token`, '', {expires: -1});
      } else {
        document.location.href = "https://cabinet.skyservice.online";
      }
    }

    //Заменяем старую переменную на новую
    var registerPhoneLocal = localStorage["registration_phone"];
    if (registerPhoneLocal != null) {
      localStorage["user_login"] = registerPhoneLocal;
      localStorage.removeItem('registration_phone');
    }

    var newRegisterPhoneLocal = localStorage["user_login"];
    if (newRegisterPhoneLocal != null) {
      this.form.login = newRegisterPhoneLocal;
    }


      /******/

          //метод авторизации винда
      window.windowsAuthResult = {}
      window.windowsAuthResult.success = (json) => {
        console.log('windowsAuthResult go')
        let data = JSON.parse(json)
        if(data.authType == "google"){
          if(data.googleUser){
            console.log('googleUser go') 
            let obj = {
                lang: store.lang,
                token: data.googleUser.tokenId
              }
            store.serviceGoogleToken = data.googleUser.tokenId;
            apiList.authGoogle(obj).then((resp) => {
              if (resp.data.status == "done") {
                if(resp.data.data.token){
                  apiCookies.set("token", resp.data.data.token, {expires: 180})//, {domain: ".skyservice.online"}
                  document.location.href = "https://cabinet.skyservice.online";
                } else if(resp.data.data.description === "Email is already registered"){
                  this.showBindSocials = true;
                  this.bindData = {
                      socialType: "Google",
                      currentUserPhone: resp.data.data.user_phone,
                      currentAccessToken: obj.token
                    }
                }
              } else {
                if (resp.data.error == "Google Acc not exist") {
                  window.say.messageError(this.lang['AccountNotRegistered']);
                  this.showLoginServiceGoogle = true;
                }  else if(resp.data.error == "Phone is already registered"){
                  this.showLoginServiceGoogle = true;
                  window.say.messageError(this.lang['PhoneAlreadyRegistered']);
                } else {
                  window.say.messageError(this.lang['Error']);
                }
              }
            });
          }
        } else if (data.authType == "facebook") {
          let obj = {
            id: data.facebookUser.id,
            token: data.facebookUser.token,
            lang: store.lang,
          };
          store.serviceFacebookToken = data.facebookUser.token;
          store.serviceUserID = data.facebookUser.id;
          apiList.authFB(obj).then((resp) => {
            console.log('FacebookUser request') 
            if (resp.data.status == "done") {
    
              if (resp.data.data.description == "Email is already registered") {
                this.showBindSocials = true;
                this.bindData = {
                  socialType: "Facebook",
                  currentUserPhone: resp.data.data.user_phone,
                  facebookToken: obj.token,
                  facebookId: obj.id
                }
              }else{
                console.log('FacebookUser request done') 
                apiCookies.set("token", resp.data.data.token, {expires: 180})
                document.location.href = "https://cabinet.skyservice.online";
              }
            } else {
              console.log('FacebookUser request err')
              console.log('obj', obj)
              console.log('resp.data.error - ', resp.data.error)
              if (resp.data.error == "FB Acc not exist") {
                console.log('FacebookUser request err FB Acc not exist') 
                window.say.messageError(this.lang['AccountNotRegistered'])
                this.showLoginServiceFacebook = true
              } else {
                console.log('err err') 
                window.say.messageError(this.lang['Error']);
              }
            }
          });
        }
        console.log('end')
      }
      window.windowsAuthResult.error = () => {
      // alert('error')
      }


      window.iosGoogleAuthResult = {}
          window.iosGoogleAuthResult.success = (token) => {
            let authObj = {
              authType:"google",
              googleUser:{
                tokenId:token
              }
            }
          window.androidAuthResult.success(JSON.stringify(authObj));
      }

      window.iosFacebookAuthResult = {}
          window.iosFacebookAuthResult.success = (jsonString) => {
            let jsonObj = JSON.parse(jsonString)
            try{
              let authObj = {
                authType:"facebook",
                facebookUser:{
                  userID:jsonObj.userID,
                  accessToken:jsonObj.accessToken
                }
              }
              window.androidAuthResult.success(JSON.stringify(authObj));
            }catch(err){
              // console.error(err)
            }

      }

      // функция для авторизации андроидов
      window.androidAuthResult = {}
      window.androidAuthResult.success = (json) => {
      let data = JSON.parse(json)
      let obj = {}
        // Гугл
        if(data.authType == 'google'){
          obj = {
            lang: store.lang,
            token: data.googleUser.tokenId
          }
          store.serviceGoogleToken = data.googleUser.tokenId;
          apiList.authGoogle(obj).then((resp) => {
            if (resp.data.status == "done") {
              if(resp.data.data.token){
                apiCookies.set("token", resp.data.data.token, {expires: 180})//, {domain: ".skyservice.online"}
                document.location.href = "https://cabinet.skyservice.online";
              } else if(resp.data.data.description === "Email is already registered"){
                this.showBindSocials = true;
                this.bindData = {
                  socialType: "Google",
                  currentUserPhone: resp.data.data.user_phone,
                  currentAccessToken: obj.token
                }
              }
            } else {
              if (resp.data.error == "Google Acc not exist") {
                window.say.messageError(this.lang['AccountNotRegistered']);
                this.showLoginServiceGoogle = true;
              }  else if(resp.data.error == "Phone is already registered"){
                this.showLoginServiceGoogle = true;
                window.say.messageError(this.lang['PhoneAlreadyRegistered']);
              } else {
                window.say.messageError(this.lang['Error']);
              }
            }
          });
        // Телеграм
        } else if(data.authType == 'telegram'){
          let user
          data.telegramUser = user
          obj = {
            lang: store.lang,
            ...user
          }
          apiList.authTelegram(obj).then((resp) => {
            if(resp.data.status == "done") {
              apiCookies.set("token", resp.data.data.token, {expires: 180})
              document.location.href = "https://cabinet.skyservice.online";
            } else {
              if (resp.data.error == "Telegram acc is not registered") {
                window.say.messageError(this.lang['AccountNotRegistered']);
                store.showTelegramAndroid = true
              } else {
                window.say.messageError(this.lang['Error']);
              }
            }
          });
        // Facebook
        } else if(data.authType == 'facebook'){
          obj = {
            id: data.facebookUser.userID,
            token: data.facebookUser.accessToken,
            lang: store.lang,
          }
          store.serviceFacebookToken = data.facebookUser.accessToken
          store.serviceUserID = data.facebookUser.userID
          apiList.authFB(obj).then((resp) => {
            if (resp.data.status == "done") {
              if(resp.data.data.description == "Email is already registered"){
                this.showBindSocials = true;
                this.bindData = {
                  socialType: "Facebook",
                  currentUserPhone: resp.data.data.user_phone,
                  facebookToken: obj.token,
                  facebookId: obj.id
                }
              }else if(resp.data.data.token){
                apiCookies.set("token", resp.data.data.token, {expires: 180})
                document.location.href = "https://cabinet.skyservice.online";
              }else{
                window.say.messageError(this.lang['Error']+": 1101");
              }
        
            } else {
              if (resp.data.error == "FB Acc not exist") {
                store.showFacebookAndroid = true
              } else if (resp.data.error){
                // console.log('err')
              } else {
                // console.log('err')
              }
            }
          }
        )}
      }
      window.androidAuthResult.error = () => {
      // alert('error')
      }

      /*******/
  },
  mounted() {
    keyevent.$off('enter');
    keyevent.$on('enter', () => {
      this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
    })
    //  androidFixMono('login');
    // инициализация пользователя гугл
    try{
          this.googleClient = google.accounts.oauth2.initTokenClient({
          client_id: '910811730153-q8m9pb2rkt372dlf3u3te7kggbhj3aiu.apps.googleusercontent.com',
          scope: 'email profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile',
          callback: (response) => {
            this.addGoogle(response.access_token);
          },
        });
    }catch(err){
      // console.log("не удалось инициализировать пользователя гугл")
    }
    // пишем в службу поддержки
    setTimeout(() => {
      if(webviewCheck() == "android_webview"){
        this.showCrispButton = true
      } else {
        // стартуем чат поддержки
      window.$crisp.push(['do', 'chat:show']);
      $crisp.push(["on", "chat:opened", () => {
          //Адаптация чата для мобильно приложения ios iphone 10+
          //Определяем что за девайс, есть ли брова
          try{
              //Notch - вырез в экране айфона
              let isNotch = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat"));
              if(isNotch > 0 && webviewCheck() == "ios_webview"){
                  let chatHeight = window.innerHeight-220;
                  document.querySelector('.crisp-client .cc-52lo .cc-kegp .cc-1apq .cc-1jpm').style = `height: `+chatHeight+`px !important;`
                  document.querySelector('.crisp-client .cc-52lo .cc-kegp .cc-1apq .cc-1q8z').style = `padding-top: 40px !important; background: #2a2a2a !important;`
              }
          }catch(err){
              console.log("нету моноброви");
          }
      }])
      }
    }, 200);
    if(webviewCheck() == 'ios_webview'){
      window.webkit.messageHandlers.getAppVersion.postMessage('');
    }
  },
  methods: {
    // дії при натиску на кнопку через apple
    signInWithApple(){
      if(webviewCheck() == 'ios_webview'){
        this.signInWithAppleForIOSApp()
      } else if(webviewCheck() == 'browser'){
        this.signInWithAppleForBrowser()
      }
    },
    // Авторизація через apple для додатку на ios
    signInWithAppleForIOSApp(){
      window.webkit.messageHandlers.handleAppleSignIn.postMessage('');
    },
    // Авторизація через apple для браузера
    signInWithAppleForBrowser(){
      // Ініціалізація Apple Sign In
      window.AppleID.auth.init({
        clientId: "pro.skeservice.app",
        scope: 'name email', // Вказати необхідні області
        redirectURI: "https://auth.skyservice.online/",
        usePopup: true,
      });
      // Виклик методу для відображення вікна авторизації
      window.AppleID.auth.signIn()
      .then(response => {
        const appleToken = response.authorization.id_token;
        this.addApple(appleToken);
      })
      .catch(error => {
        console.error(error)
        window.say.messageError(this.lang['Error']);
      });
    },
    // Відправка данних на бек після авторизації через apple
    addApple(appleToken){
      try {
        store.serviceAppleToken = appleToken;
        // от Apple нам нужен только токен который передаем на бэк
        const obj = {
          apple_token: appleToken,
          lang: store.lang,
        };
        apiList.authApple(obj).then((resp) => {
          console.warn("authApple_resp", resp);
          if (resp.data.status == "done") {
            if(resp.data.data.token){
              apiCookies.set("token", resp.data.data.token, {expires: 180})//, {domain: ".skyservice.online"}
              document.location.href = "https://cabinet.skyservice.online";
            } else if(resp.data.data.description === "Email is already registered"){
              this.showBindSocials = true;
              this.bindData = {
                socialType: "Apple",
                currentUserPhone: resp.data.data.user_phone,
                currentAccessToken: appleToken
              }
            }
          } else {
            if (resp.data.error == "Apple Acc not exist") {
              window.say.messageError(this.lang['AccountNotRegistered']);
              this.showLoginServiceApple = true;
            }  else if(resp.data.error == "Phone is already registered"){
              this.showLoginServiceApple = true;
              window.say.messageError(this.lang['PhoneAlreadyRegistered']);
            } else {
              window.say.messageError(this.lang['Error']);
            }
          }
        });
      } catch (err) {
        // console.log(err);
        window.say.messageError(this.lang['Error']);
      }
    },
    // 2 метода для фокуса на странице, они нужны для кейивэнтов
    loginInputFocus(){
      try {
        this.$refs.loginInput.focus()
      } catch (err) {
        // console.log(err)
      }
    },
    loginRefFocus(){
      try {
        this.$refs.loginRef.focus()
      } catch (err) {
        // console.log(err)
      }
    },
    closeCaptcha(){
      this.showForgotCaptcha = false
      keyevent.$off('enter');
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    closeSmsModal(){
      this.showSms = false
      keyevent.$off('enter');
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    devMode(){
      this.showEmergencyEntrance = this.showEmergencyEntrance + 1;
      if(this.showEmergencyEntrance > 5){
        this.showEmergencyEntrance = 0;
      }
    },
    closeLoginPass(){
      this.showLoginPass = false;
      this.loginRefFocus();
      keyevent.$off('enter');
      keyevent.$on('enter', () => {
      this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    openAuthModal(e){
      this.closeTelegramModal();
      this.form.login = e;
      this.registered = true;
      this.showLoginPass = true;
      this.loginRefFocus();
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    closeGoogleModal(){
      store.showGoogleAndroid = false;
      this.showLoginServiceGoogle = false;
      this.loginRefFocus();
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    closeFacebookModal(){
      store.showFacebookAndroid = false;
      this.showLoginServiceFacebook = false;
      this.loginRefFocus();
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    closeTelegramModal(){
      store.showTelegramAndroid = false;
      this.showLoginServiceTelegram = false;
      this.loginRefFocus();
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    closeAppleModal(){
      this.showLoginServiceApple = false;
      this.loginRefFocus();
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    closeBindModal(){
      this.showBindSocials = false;
      this.bindData = {
        socialType: '',
        currentUserPhone: '',
        currentAccessToken: '',
        facebookId: '',
        facebookToken: ''
      }
      this.loginInputFocus();
      keyevent.$off('enter')
      keyevent.$on('enter', () => {
        this.form.login.length > 3 ? this.btnLogin() : window.say.messageError(this.lang['EMailOrPhone'])
      })
    },
    googleWindowsAuth() {
        let data = {
        command: "auth",
        authType: "google"
      }
 
      var request = {
        method: "auth",
        data: JSON.stringify(data)
      }

      window.cefQuery({request: JSON.stringify(request),
        persistent: true,
        onSuccess: function(responseJson) {
            console.log(responseJson)
        },
        onFailure: function(error_code, error_message) {
            console.log(error_code, error_message)
        }
      });
    },
    facebookWindowsAuth() {
        let data = {
        command: "auth",
        authType: "facebook"
      }
      var request = {
        method: "auth",
        data: JSON.stringify(data)
      }

      window.cefQuery({request: JSON.stringify(request),
        persistent: true,
        onSuccess: function(responseJson) {
            console.log(responseJson)
        },
        onFailure: function(error_code, error_message) {
            console.log(error_code, error_message)
        }
      });
    },
    // метод для авторизации андроидов
    androidAuth(t) {
      let authData = {
        "command": 'auth', //logout
        "authType": t,
      }
      try {
          // eslint-disable-next-line
          Android.auth(JSON.stringify(authData))
      } catch(err) {
        console.log('err')
      }
    },
    telegramLogin(){
      if(webviewCheck() == "android_webview"){
        this.androidAuth(this.telegram)
      } else {
        window.Telegram.Login.auth({ bot_id: '1401730837', request_access: 'read' },
          (data) => {
            if (!data) {
              console.error("Error")
            } else {
              console.log(data);
              this.telegramCallbackFunction(data);
            }
          }
        );
      }
    },
    // вариант получения токена гугл
    googleLogin(){
      if( webviewCheck() == "android_webview"){
        this.androidAuth(this.google)
      } else if(webviewCheck() == "cef_webview"){
        this.googleWindowsAuth()
      } else if (webviewCheck() == "ios_webview"){
          window.webkit.messageHandlers.GoogleAuth.postMessage("");
      } else {
        this.googleClient.requestAccessToken();
      }
    },
    // забыл пароль- телефон
    // открываем окно для ввода капчи, есл  юзер ввел номенр телефона как логин
    goToCaptch() {
      this.showForgotCaptcha = true;
    },
    goDemo() {
      location.href = moreURLs.goDemo(store.lang);
    },
    goEmergencyEntrance(){
      location.href = moreURLs.goEmergencyEntrance(store.lang); 
    },
    getSms() {
      this.showSms = true;
    },
    // Телеграм логин
    telegramCallbackFunction(user) {
      return new Promise((resolve, reject) => {
        if (user != null) {
          resolve(user);
        } else {
          reject();
        }
      })
        .then((user) => {
          try {
            this.serviceTgData = user
            apiList
              .authTelegram({
                ...user,
                lang: store.lang,
              })
              .then((resp) => {
                if(resp.data.status == "done") {
                  apiCookies.set("token", resp.data.data.token, {expires: 180})//, {domain: ".skyservice.online"}
                  document.location.href = "https://cabinet.skyservice.online";
                } else {
                  if (resp.data.error == "Telegram acc is not registered") {
                    window.say.messageError(this.lang['AccountNotRegistered']);
                    this.showLoginServiceTelegram = true
                  } else {
                    window.say.messageError(this.lang['Error']);
                  }
                }
              });
          } catch (err) {
            console.log(err)
            window.say.messageError(this.lang['Error']);
          }
        })
        .catch((err) => {
          window.say.messageError(this.lang['Error']);
        });
    },
    // login ФБ
    facebookLogin() {
      if( webviewCheck() == "android_webview"){
        this.androidAuth(this.facebook)
      } else if (webviewCheck() == "ios_webview"){
          window.webkit.messageHandlers.FacebookAuth.postMessage("");
      } else if(webviewCheck() == "cef_webview"){
          this.facebookWindowsAuth()
      } else {
      FB.login((login) => {
        console.log(login)
        try {
        if (login.status == "connected") {
          try {
            // нам ФБ отдает токен и ид юзера, который проверяется на бэке и если все ок то мы входим
            store.serviceFacebookToken = login.authResponse.accessToken
            store.serviceUserID = login.authResponse.userID
            const obj = {
              id: login.authResponse.userID,
              token: login.authResponse.accessToken,
              lang: store.lang,
            };
            apiList.authFB(obj).then((resp) => {
              if (resp.data.status == "done") {
                if (resp.data.data.description == "Email is already registered") {
                  this.showBindSocials = true;
                  this.bindData = {
                    socialType: "Facebook",
                    currentUserPhone: resp.data.data.user_phone,
                    facebookToken: obj.token,
                    facebookId: obj.id
                  }
                }else{
                  // если все ок:
                  //записываем токен
                  // редиректим пользователя на страницу персоны
                  apiCookies.set("token", resp.data.data.token, {expires: 180})//, {domain: ".skyservice.online"}
                  document.location.href = "https://cabinet.skyservice.online";
                }

              } else {
                if (resp.data.error == "FB Acc not exist") {
                  window.say.messageError(this.lang['AccountNotRegistered'])
                  this.showLoginServiceFacebook = true
                } else {
                  window.say.messageError(this.lang['Error']);
                }
              }
            });
          } catch (err) {
            window.say.messageError(this.lang['Error']);
          }
        }
      } catch (err) {
        // console.log(err);
      }
      })
      }
    },
    /**
     * обычная форма логина
     */
    btnLogin() {
      if (this.form.login.length > 3) {
        this.checkAcc()
        //!this.showLoginPass
      } else {
        window.say.messageError(this.lang['EMailOrPhone']);
      }
    },
    // принимаем пароль из модалки впихиваем его в форму после вызываем метод логина
    sendPass(i) {
      this.form.password = i;
      this.goLogin();
    },
    // метод проверки аккаунта на наличие онного
    checkAcc() {
      try {
        try{
          //Костыльчик для Украины что бы не вводить +38
          if(this.form.login["0"] == "0"){
            this.form.login = "38"+this.form.login
          }
        }catch(err){
          console.error(err)
        }

        apiList.checkAccount({ login: this.form.login }).then(resp => {
          if (resp.data.status == "done") {
            if (resp.data.data == 'login not found' || resp.data.data == 'login found, not full reg') {
              this.registered = false;
              this.showLoginPass = true;
            } else {
              this.registered = true;
              this.showLoginPass = true;
              //если прошла авторизация не успешно, тогда будем
              //передавать данные о количестве осташихся попыток на попытки входа
              if (!resp.data.data.auth.canAuth) {
                //проверяем на количество попыток
                if (resp.data.data.auth.countsLeft > 0) {
                  window.say.messageError(this.lang['numberOfAttemptsToCheckAuth'] + ' ' + resp.data.data.auth.countsLeft);
                } else {
                  //иначе повторите попытку позже
                  // this.$emit('tryLater'); 
                  window.say.messageError(this.lang['TryAgainAfterMinute']);
                }
              }
            }
          } else if (resp.data.status == "error") {
            this.$emit('close');
            if (Array.isArray(resp.data.error)) {
              for (let key in resp.data.error) {
                window.say.messageError(resp.data.error[key]);
              }
            } else if (typeof resp.data.error == 'string') {
              window.say.messageError(resp.data.error);
            }
          } else {
            this.$emit('close');
            this.registered = false;
            window.say.messageError("unknown error:0175");
          }

        })
      } catch (err) {
        // console.log(err)
      }
    },
    // метод логина принимает в себя форму, логин заполняется в этом компоненте, а пароль прилетает из модалки
    goLogin() {
      // const expirationDate = new Date()
      // expirationDate.setMonth(expirationDate.getMonth() + 6);
      try {
        (this.form.lang = store.lang),
          apiList.personLogin(this.form).then((resp) => {
            if(resp.data.status == "done" && resp.data.data.authTerminal == true){
              apiCookies.set("token", resp.data.data.token, {expires: 180});//, {domain: ".skyservice.online"}
              let url = ''
              url = resp.data.data.url
              document.location.href = url;
              this.showLoginPass = false
            } else if (resp.data.status == "done") {
              //если передан токен, тогда авторизация прошла успешно и пользователь идет дальше
              if (resp.data.data.token) {
                localStorage.setItem("user_login", this.form.login);
                //записываем токен
                // редиректим пользователя на страницу персоны
                apiCookies.set("token", resp.data.data.token, {expires: 180});//, {domain: ".skyservice.online"}
                // eslint-disable-next-line
                // debugger
                document.location.href = "https://cabinet.skyservice.online";
                this.showLoginPass = false
              } else {
                //если прошла авторизация не успешно, тогда будем
                //передавать данные о количестве осташихся попыток на попытки входа
                if (resp.data.data.countsLeft > 0) {
                  window.say.messageError(this.lang['InvalidPassNumberOfAttemptsToCheckAuth'] + ' ' + resp.data.data.countsLeft);
                }
                //если прошла авторизация не успешно, тогда будем
                //передавать данные о количестве осташихся попыток на попытки входа
                if (!resp.data.data.canAuth) {
                  window.say.messageError(this.lang['TryAgainAfterMinute']);
                }
              }
            } else {
              if (resp.data.error[0] == "login is incorrect") {
                window.say.messageError(this.lang['WrongLoginOrPassword']);
              } else if (resp.data.error[0] == "pass is incorrect") {
                window.say.messageError(this.lang['WrongLoginOrPassword']);
              } else if (resp.data.error[0] == "wrong terminal password") {
              window.say.messageError(this.lang['WrongLoginOrPassword']);
              } else {
                window.say.messageError(this.lang['Error']);
              }
            }
          });
      } catch (err) {
        window.say.messageError(this.lang['Error']);
      }
    },
    // логин вход через Гугл
    addGoogle(token) {
      try {
        // от гугла нам нужен только токен который передаем на бэк
        store.serviceGoogleToken = token
        const obj = {
          token,
          lang: store.lang,
        };
        apiList.authGoogle(obj).then((resp) => {
          console.log(resp);
          if (resp.data.status == "done") {
            if(resp.data.data.token){
              apiCookies.set("token", resp.data.data.token, {expires: 180})//, {domain: ".skyservice.online"}
              document.location.href = "https://cabinet.skyservice.online";
            } else if(resp.data.data.description === "Email is already registered"){
              this.showBindSocials = true;
              this.bindData = {
                  socialType: "Google",
                  currentUserPhone: resp.data.data.user_phone,
                  currentAccessToken: obj.token
                }
            }
          } else {
            if (resp.data.error == "Google Acc not exist") {
              window.say.messageError(this.lang['AccountNotRegistered']);
              this.showLoginServiceGoogle = true;
            }  else if(resp.data.error == "Phone is already registered"){
              this.showLoginServiceGoogle = true;
              window.say.messageError(this.lang['PhoneAlreadyRegistered']);
            } else {
              window.say.messageError(this.lang['Error']);
            }
          }
        });
      } catch (err) {
        // console.log(err);
        window.say.messageError(this.lang['Error']);
      }
    },
    // просто пушим юзера на странице регистрации
    goReg() {
      keyevent.$off('enter')
      this.$router.push("/registration");
    },
    // изменение языка
    changeLang(l) {
      localStorage.setItem("lang_user", l);
      store.lang = l;
      window.lang = store.langs[store.lang]();
    },
  },
  computed: {
    appleSign(){
      return (webviewCheck() == 'ios_webview' && getiOSVersion() && store.appInfoData.appVersion >= 6) || webviewCheck() == 'browser';
    },
    loginState(){
      return this.form.login != '' && this.form.login.length >= 4 ? true : false;
    },
    serviceGoogleToken(){
      return store.serviceGoogleToken;
    },
    serviceFacebookToken(){
      return store.serviceFacebookToken;
    },
    serviceAppleToken(){
      return store.serviceAppleToken;
    },
    serviceFacebookUserID(){
      return store.serviceUserID;
    },
    showLoginForm() {
      try{
        if(!Cookies.get("token")){
          return true;
        }else{
          return false;
        }
      }catch(err){
        return true;
      }
    },
    showGoogleAndroid() {
      return store.showGoogleAndroid
    },
    showFacebookAndroid() {
      return store.showFacebookAndroid
    },
    showTelegramAndroid() {
      return store.showTelegramAndroid
    },
    lang() {
      return store.langs[store.lang]();
    },
  },
};
</script>

<style>
#login{
  position: relative;
}
.container{
  overflow-y: auto;
}
.privacy-policy-term {
  color: #106090;
}
.langSelector{
    margin-bottom: 1rem; 
    margin-right: 1rem; 
    float: right;
}
.loginButton{
  width: 100%;
}
.network-img{
  height: 50px;
}
#enterWidth{
  padding-left: 30px;
  padding-bottom: 5px;
}
#tg-container {
  opacity: 0;
  position: absolute;
  width: 100%;
  bottom: 5px;
  z-index: 1;
  overflow: hidden;
}

.login-text-btn {
  display: flex;
  justify-content: space-between;
}

.network-div-container {
  display: flex;
  padding: 0 5px;
  flex-wrap: wrap;
}

.network-div {
  padding: 0 15px;
}

.network-div .btn{
  border-radius: 10px;
  transition: transform 0.25s;
}

.network-div .btn:hover {
  background: rgb(245, 245, 245);
  transform: scale(1.1);
}
.apple-btn{
  position: relative;
  top: -3px;
}
.v-facebook-login {
  padding: 10px 15px;
}

.help {
  color: #228311;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-left: 2rem;
}

.fb_text {
  display: none;
}

.fb_logo {
  font-size: 0rem;
}

.login-container {
  padding: 0 15px;
}

.login-form-container {
  padding: 15px;
}

.go-registration {
  color: #106090;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 1rem;
}

.emergency-entrance-span {
  color: black;
  margin-left: 2rem;
  text-decoration: underline;
  cursor: pointer;
}

.demo-span {
  color: red;
  margin-left: 2rem;
  text-decoration: underline;
  cursor: pointer;
}
#footerLinks{
  position:absolute; 
  bottom:20px; 
  font-size:9pt;
}
@media screen and (max-height: 510px) {
  #footerLinks{
    position: relative;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 700px) {
  .sky-img {
    width: 100%;
  }

  .login-container {
    padding: 0;
  }

  .network-div {
    padding: 5px;
    width: 33%;
    text-align: center;
  }

  .demo-span {
    margin-left: unset;
    margin-top: 2rem;
    display: block;
  }

  .emergency-entrance-span {
    margin-left: unset;
    margin-top: 2rem;
    display: block;
  }
}
</style>
