<template>
    <div>
        <headloginreg />
        <div class="container">
            <h5>{{ lang['RestorePassword'] }}</h5>
            <hr>
            <div class=" forgot-password">
                <label for="password">{{ lang['Password'] }}</label>
                <b-form-input autofocus type="password" name="password" :state="statePassword" v-model="form.password" />
                <label for="repeatpassword">{{ lang['RepeatPassword'] }}</label>
                <b-form-input type="password" name="repeat_password" :state="stateReeatPassword" v-model="form.repeatPassword" />
                <button class="btn btn-outline-success" @click="saveNewPassword">{{ lang['Save'] }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { keyevent } from '@/main.js';
import headloginreg from './heads/HeaderLoginAndReg.vue';
// import { store } from '@/store'
import { regAPi } from '../API.js'
const apiList = new regAPi();
export default {
    components: {
        headloginreg
    },
    data() {
        return {
            form: {
                password: '',
                repeatPassword: ''
            },
            mailLang: ''
        }
    },
    mounted() {
        this.getUserLogin()
        keyevent.$off('enter');
        keyevent.$on('enter', () => this.saveNewPassword())
    },
    methods: {
        // Сохранить новый пароль
        saveNewPassword() {
            //первая проверка необходима если вдруг юзер сам добавил в строке /forgotpassword
            if (this.getUserLogin().login != null && this.getUserLogin().pass_token != null) {
                if (this.statePassword && this.stateReeatPassword) {
                    // собираем объект из полученных значени в  ссылке и введеного юзером пароля
                    const obj = {
                        ...this.getUserLogin(),
                        new_password: this.form.password
                    }
                    apiList.sendNewPassword(obj).then(resp => {
                        if (resp.data.status == 'done') {
                            window.say.message(this.lang['Saved'])
                            this.$router.push('/');
                        } else {
                            window.say.messageError(this.lang['Error']);
                        }
                    })
                    console.warn(obj)
                } else {
                    window.say.messageError(this.lang['MinPassLength']);
                }
            } else {
                window.say.messageError(this.lang['LinkIsNotValid']); 
            }
        },
        // тут мы будем выдирать логин юзера из ссылки
        getUserLogin() {
            const url = window.location.href;
            const personLogin = (new URL(url)).searchParams; 

            this.mailLang = personLogin.get("lang")
            // ретерним объект в котором помещаем выдранные из ссылки значения
            const obj = {
                login: personLogin.get("login"),
                pass_token: personLogin.get("pass_token")
            }
            return obj;
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        statePassword() {
            return this.form.password.length >= 4 ? true : false;
        },
        stateReeatPassword() {
            return this.form.repeatPassword != '' && this.form.repeatPassword == this.form.password ? true : false;
        }
    }
}
</script>

<style scoped>
    .forgot-password {
        padding: 15px;
    }
    .btn-outline-success{
        width: 100%;
    }
</style>