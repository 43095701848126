function lang() {
    return window.lang;
}

function errList(text) {
    switch (text) {
        case 'pass is incorrect':
            return window.say.messageError(lang().WrongPassword);
        case 'user not registered':
            return window.say.messageError(lang().UserMustBeRegistered);
        case 'Captcha dont exist':
            return window.say.messageError(lang().CaptchaIsNotValid);
        case 'Captcha is incorrect':
            return window.say.messageError(lang().IncorrectCaptcha);
        default:
            return window.say.messageError(lang().Error);
    }
}

export { errList }