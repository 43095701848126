<template>
    <div class="container">
        <a href="/"><headloginreg /></a>
        <br>
        <div class="special">
            <div class="currentSpan" @click="goLogin"><b-icon icon="chevron-left" style="margin-right:3px;" font-scale="1.25"></b-icon><span>{{ currentLang === 'ru' ? currentSpan.descRU : currentLang === 'uk' ? currentSpan.descUK : currentSpan.descEN  }}</span></div>
            <div class="specialSelect">
                <SelectLangs @changeLang="changeLang($event)" :utilityLang="currentLang" :limited="language" />
            </div>
        </div>
        <hr>
        <div v-if="currentLang==='ru'">
            <h2>Восстановление паролей</h2>
            <div class="info-text">
                <h5 style="text-align:center">Инструкция по восстановлению пароля от аккаунта Skyservice с помощью мобильного телефона:</h5>
                    <p class="text">
                        Чтобы восстановить свой пароль от аккаунта Skyservice переходим по ссылке: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                    </p>
                    <p class="text">
                        Далее авторизуемся в свой аккаунт Skyservice c помощью вашего номера телефона под которым вы регистрировали аккаунт 
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/phone-entry.png" alt="">
                        </div>
                    <p class="text">
                        Во всплывающем окне кликаем "Забыли пароль"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/forget-password.png" alt="">
                        </div>
                    <p class="text">
                        Далее вводим капчу с картинки, вводим пароль и кликаем кнопку "Сохранить"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/captchaModal.png" alt="">
                        </div>
                    <p class="text">
                        Во всплывающем окне вводим код который пришел смс-кой на ваш номер телефона и кликаем "Сохранить"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/smsModal.png" alt="">
                        </div>
                    <p class="text">
                        Далее переходим на окно восстановления пароля и задаем новый пароль, дублируем его и кликаем "Сохранить"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/recovery-password.png" alt="">
                        </div>
                <h5 style="text-align:center">Инструкция по восстановлению пароля от аккаунта Skyservice с помощью электронной почты:</h5>
                    <p class="text">
                        Чтобы восстановить свой пароль от аккаунта Skyservice переходим по ссылке: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                    </p>
                    <p class="text">
                        Далее авторизуемся в свой аккаунт Skyservice c помощью вашей электронной почты с помощью которой Вы регистрировали аккаунт 
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/login-entry.png" alt="">
                        </div>
                    <p class="text">
                        Во всплывающем окне кликаем "Забыли пароль"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/forget-passMail.png" alt="">
                        </div>
                    <p class="text">
                        Далее вводим капчу с картинки, вводим пароль и кликаем кнопку "Сохранить"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/captchaModal.png" alt="">
                        </div>
                    <p class="text">
                        На указанную электронную почту прийдет письмо, кликаем на ссылку
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/mailMessage.png" alt="">
                        </div>
                    <p class="text">
                        Далее переходим на окно восстановления пароля и задаем новый пароль,дублируем его и кликаем "Сохранить"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/recovery-password.png" alt="">
                        </div>
                    <p class="text">
                        После этих действий Вы успешно восстановите ваш пароль от аккаунта Skyservice.
                    </p>
            </div>
        </div>
        <div v-if="currentLang==='uk'">
            <h2>Відновлення паролів</h2>
            <div class="info-text">Інструкція з відновлення пароля від акаунту Skyservice за допомогою мобільного телефону:
                <h5 style="text-align:center"></h5>
                    <p class="text">
                        Щоб відновити свій пароль від облікового запису Skyservice переходимо за посиланням: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                    </p>
                    <p class="text">
                        Далі авторизуємося у свій акаунт Skyservice за допомогою вашого номера телефону під яким ви реєстрували акаунт 
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/phone-entryUA.png" alt="">
                        </div>
                    <p class="text">
                        У спливаючому вікні клацаємо "Забули пароль"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/forget-passwordUA.png" alt="">
                        </div>
                    <p class="text">
                        Далі вводимо капчу з картинки, вводимо пароль і натискаємо кнопку "Зберегти"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/captchaModalUA.png" alt="">
                        </div>
                    <p class="text">
                        У спливаючому вікні вводимо код, який прийшов смс-кою на ваш номер телефону і клацаємо "Зберегти"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/smsModalUA.png" alt="">
                        </div>
                    <p class="text">
                        Далі переходимо на вікно відновлення пароля та задаємо новий пароль, дублюємо його та натискаємо "Зберегти"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/recovery-passwordUA.png" alt="">
                        </div>
                    <h5 style="text-align:center">Інструкція з відновлення пароля від акаунту Skyservice за допомогою електронної пошти:</h5>
                    <p class="text">
                        Щоб відновити свій пароль від облікового запису Skyservice переходимо за посиланням: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                    </p>
                    <p class="text">
                        Далі авторизуємося у свій акаунт Skyservice за допомогою вашої електронної пошти, за допомогою якої Ви реєстрували акаунт
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/login-entryUA.png" alt="">
                        </div>
                    <p class="text">
                        У спливаючому вікні клацаємо "Забули пароль"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/forget-passMailUA.png" alt="">
                        </div>
                    <p class="text">
                        Далі вводимо капчу з картинки, вводимо пароль і натискаємо кнопку "Зберегти"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/captchaModalUA.png" alt="">
                        </div>
                    <p class="text">
                        На вказану електронну пошту прийде лист, клацаємо на посилання
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/mailMessageUA.png" alt="">
                        </div>
                    <p class="text">
                        Далі переходимо на вікно відновлення пароля і задаємо новий пароль, дублюємо його і натискаємо "Зберегти"
                    </p>
                        <div class="recImg">
                            <img  class="img" src="@/assets/recovery-passwordUA.png" alt="">
                        </div>
                    <p class="text">
                        Після цих дій Ви успішно відновите пароль від облікового запису Skyservice.
                    </p>
            </div>
        </div>
        <div v-if="currentLang==='en'">
            <h2>Password recovery</h2>
            <div class="info-text">
            <h5 style="text-align:center">Instructions for recovering a password from a Skyservice account using a mobile phone:</h5>
                <p class="text">
                    To recover your password from your Skyservice account, follow the link: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                </p>
                <p class="text">
                    Next, log in to your Skyservice account using your phone number under which you registered your account
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/phone-entryEN.png" alt="">
                    </div>
                <p class="text">
                    In the pop-up window, click "Forgot password"
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/forget-passwordEN.png" alt="">
                    </div>
                <p class="text">
                    Next, enter the captcha from the image, enter the password and click the "Save" button.
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/captchaModalEN.png" alt="">
                    </div>
                <p class="text">
                    In the pop-up window, enter the code that was sent by SMS to your phone number and click "Save"
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/smsModalEN.png" alt="">
                    </div>
                <p class="text">
                    Next, go to the password recovery window and set a new password, duplicate it and click "Save"
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/recovery-passwordEN.png" alt="">
                    </div>Instructions for recovering a password from a Skyservice account using email:<h5 style="text-align:center"></h5>
                <p class="text">
                    To recover your password from your Skyservice account, follow the link: <a href="https://auth.skyservice.online">auth.skyservice.online</a>
                </p>
                <p class="text">
                    Next, log in to your Skyservice account using your email with which you registered your account
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/login-entryEN.png" alt="">
                    </div>
                <p class="text">
                    In the pop-up window, click "Forgot password"
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/forget-passMailEN.png" alt="">
                    </div>
                <p class="text">
                    Next, enter the captcha from the image, enter the password and click the "Save" button.
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/captchaModalEN.png" alt="">
                    </div>
                <p class="text">
                    An email will be sent to your email address, click on the link
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/mailMessageEN.png" alt="">
                    </div>
                <p class="text">
                    Next, go to the password recovery window and set a new password, duplicate it and click "Save"
                </p>
                    <div class="recImg">
                        <img  class="img" src="@/assets/recovery-passwordEN.png" alt="">
                    </div>
                <p class="text">
                    After these steps, you will successfully recover your Skyservice account password.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store.js";
import SelectLangs from '../LangSelectComponent.vue';
import headloginreg from "../heads/HeaderLoginAndReg.vue"
export default {
    components: {
        headloginreg,
        SelectLangs
    },
    data () {
        return {
            currentLang: "",
            language: [
                { name: 'uk', text: 'UK' },
                { name: 'ru', text: 'RU' },
                { name: 'en', text: 'EN' }
            ],
            currentSpan: {
                descUK: 'Авторизація',
                descRU: 'Авторизация',
                descEN: 'Authorization'
            }
        }
        
    },
    created() {
        // костыль для политик
        switch(store.lang){
            case "ru":  
            case "uk":
            case "en":
                this.currentLang = store.lang;
                break;
            default:
                this.currentLang = 'en';
                break;

        }
    },
    methods: {
        changeLang(l) {
            localStorage.setItem("lang_user", l);
            store.lang = l;
            window.lang = store.langs[store.lang]();
            this.currentLang = l
        },
        goLogin() {
            this.$router.push("/");
        },
    },
};
</script>
<style scoped>
.img{
    max-width: 100%;
}
.text{
    color: black;
    font-weight: bold;
    margin-bottom: 2rem;
}
.recImg{
   margin-bottom: 2rem;
}
h2{ 
    margin-top: 2rem;
    margin-top: 2rem;
    text-align:center;
    color: black;
}
h5{
    color: black;
    text-align: center;
    margin-bottom: 2rem;
}
.currentSpan{
    cursor: pointer;
    color:#106090;
    margin-top: 12px;
}
.custom-select{
    cursor: pointer;
}
.special {
    display: flex;
    justify-content: space-between;
    height: 35px;
}
body{
    overflow: scroll;
}
</style>