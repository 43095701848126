<template>
    <div class="container">
        <a href="/"><headloginreg /></a>
        <br>
        <div class="special">
            <div class="currentSpan" @click="goLogin"><b-icon icon="chevron-left" style="margin-right:3px;" font-scale="1.25"></b-icon><span>{{ currentLang === 'ru' ? currentSpan.descRU : currentLang === 'uk' ? currentSpan.descUK : currentSpan.descEN  }}</span></div>
            <div class="specialSelect">
                <SelectLangs @changeLang="changeLang($event)" :utilityLang="currentLang" :limited="language" />
            </div>
        </div>
        <hr>
        <div v-if="currentLang === 'ru'">
            <h2 style="text-align:center">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
            <div class="info-text">
                <p style="color: black">
                    Внимательно прочитайте настоящую Политику конфиденциальности,
                    перед использованием SkyService POS. Если вы хотите создать
                    Аккаунт, Вам необходимо принять Условия и Политику
                    конфиденциальности, а также другие документы и рекомендации
                    SkyService POS, которые ссылаются на эти документы.<br /><br />
                    Эта Политика конфиденциальности была обновлена
                    &ZeroWidthSpace;&ZeroWidthSpace;16 мая 2017 года.
                </p>
                <h3>Конфиденциальность</h3>
                <p style="color: black">
                    SkyService POS стремится к сохранению конфиденциальности
                    информации всех своих Пользователей.<br /><br />
                    Определения терминов, используемых в данном документе, приведены
                    в Условиях использования (“Условия”).
                </p>
                <h3>Распространение</h3>
                <p style="color: black">
                    Эта Политика конфиденциальности распространяется на всю
                    информацию, предоставленную Пользователями при использовании
                    SkyService POS, в том числе по электронной почте, а также любую
                    информацию, которая может быть автоматически доступна при
                    использовании SkyService POS.
                </p>
                <h3>Согласие</h3>
                <p style="color: black">
                    Регистрируясь на Сайте и / или используя Приложение,
                    Пользователи дают свое согласие на сбор, использование,
                    разглашение, хранение и обработку их информации в соответствии с
                    данной Политикой конфиденциальности.<br /><br />
                    Не используйте SkyService POS, если Вы не готовы принять Условия
                    использования и эту Политику конфиденциальности. Принимая
                    условия настоящей Политики конфиденциальности, Вы подтверждаете
                    свое согласие на сбор и обработку определенной информации о Вас
                    в целях описанных ниже.<br /><br />
                    При использовании SkyService POS Вы соглашаетесь и гарантируете,
                    что являетесь совершеннолетним и дееспособным лицом, способным
                    быть в полной мере не только носителем прав, но и нести
                    ответственность за свои действия.
                </p>
                <h3>Изменения в политике конфиденциальности</h3>
                <p style="color: black">
                    SkyService POS может изменять или обновлять настоящую Политику
                    конфиденциальности время от времени, с или без уведомления
                    Пользователей. Используя SkyService POS, Вы соглашаетесь
                    соблюдать требования Политики конфиденциальности, которая
                    является действующей на момент Вашего доступа к SkyService POS.
                </p>
                <h3>Предоставляемая информация</h3>
                <p style="color: black">
                    Для того, чтобы пользоваться Услугами, все Пользователи должны
                    будут представить определенную информацию о себе, которая не
                    является персональными данными и не может персонифицировать
                    конкретное физическое лицо. Возможно, в некоторых случаях,
                    потребуется предоставить дополнительную информацию, в том числе
                    если этого требует действующее законодательство Украины в
                    отношении юридических лиц. Если Вы используете SkyService POS,
                    мы можем собирать и хранить такую
                    &ZeroWidthSpace;&ZeroWidthSpace;информацию: 1. Ваш адрес
                    электронной почты и пароль для входа на сайт в зашифрованном
                    виде; 2. Ваши телефоны (служебные, рабочие); 3. Ваше
                    местоположение, тип браузера и операционной системы, способы
                    просмотра веб-сайта (например, через cookies) и историю продаж;
                    4. другую информацию, которую Вы сами нам предоставляете или
                    информацию о Вас, которую мы можем получать автоматически на
                    основании Вашего взаимодействия с SkyService POS. Вы можете
                    взаимодействовать с SkyService POS, например, если вы: 1.
                    зарегистрировались, подписались, позволили передачу данных или
                    создали аккаунт на Сайте или используя Приложение; 2. открыли
                    и/или ответили на электронные письма от SkyService POS; 3.
                    используете Сайт и/или Приложение SkyService POS; 4. а также в
                    любых других случаях при использовании Вами SkyService POS.
                </p>
                <h3>Информация, которая не собирается</h3>
                <p style="color: black">
                    Мы не запрашиваем от Вас финансовые документы, банковские
                    реквизиты, а также персональные данные в отношении Вас или
                    каких-либо третьих лиц, в том числе Ф. И. О., копии документов,
                    подтверждающих личность и идентификационный номер физических
                    лиц, и Вы обязуетесь не предоставлять нам такие данные. Мы не
                    производим хранение, обработку и передачу персональных
                    данных.<br /><br />
                    Если Вас просят предоставить такую информацию при использовании
                    SkyService POS, незамедлительно свяжитесь со службой поддержки
                    SkyService POS.
                </p>
                <h3>Опции</h3>
                <p style="color: black">
                    SkyService POS позволяет управлять предоставленными Вами данными
                    и ограничивать способы взаимодействия SkyService POS с Вами.<br /><br />
                    Но, даже если Вы решите отменить оповещения, у SkyService POS
                    все равно может возникнуть необходимость связаться с Вами, чтобы
                    донести важную информацию по операциям, связанных с Вашим
                    Аккаутном. В этом случае мы направим Вам сообщение на
                    электронную почту.
                </p>
                <h3>Использование информации и данных</h3>
                <p style="color: black">
                    Информация, предоставленная Вами, будет использоваться только в
                    целях предоставления Услуг, общения с Вами в отношении Услуг или
                    сотрудничества с третьими лицами, если это не противоречит
                    действующему законодательству Украины. SkyService POS оставляет
                    за собой право использовать данные (на анонимной основе) в
                    маркетинговых целях.
                </p>
                <h3>Раскрытие информации</h3>
                <p style="color: black">
                    SkyService POS не раскрывает информацию Пользователей третьим
                    лицам, за исключением следующих случаев: 1. корпоративной
                    продажи, слияния, реорганизации, ликвидации или аналогичного
                    события, когда информация (данные) могут быть частью переданных
                    неимущественных активов; 2. данные могут быть доступны партнерам
                    и аффилированным лицам SkyService POS в целях продвижения и
                    улучшения SkyService POS, на условиях, совместимых с данной
                    Политикой конфиденциальности; 3. в случаях привлечения третьих
                    лиц для выполнения определенных функций, связанных с
                    использованием SkyService POS и улучшением качества Услуг.
                    Информация в таких случаях передается в объеме, необходимом для
                    оказания услуг такими лицами, а также в соответствии с
                    требованиями действующей Политики конфиденциальности; 4. в
                    случаях, предусмотренных действующим законодательством, а также
                    с целью предупреждения мошенничества, устранения технических
                    неполадок и/или проблем с безопасностью, защиты прав и
                    интересов, собственности или безопасности SkyService POS и/или
                    его правообладателей, Пользователей или иных лиц, как это
                    требуется и допускается законодательством.
                </p>
                <h3>Доступ и обновление информации</h3>
                <p style="color: black">
                    Пользователи могут в любое время изменять, дополнять или удалять
                    предоставленную ими информацию по их желанию.
                </p>
                <h3>Хранение информации</h3>
                <p style="color: black">
                    SkyService POS будет надежно хранить информацию своих
                    Пользователей.Таким образом, информация и данные, полученные от
                    пользователей, могут храниться за пределами территории Украины.
                </p>
                <h3>Удаление информации</h3>
                <p style="color: black">
                    Для удаления учетной записи и собранных данных вашей учётной записи Вы можете незамедлительно воспользоваться функцией удаления учетной записи которая расположена в личном кабинете, о том как это сделать можно прочитать <a href="/privacy-policy/delete-account">тут.</a>
                </p>
                <h3>Восстановление паролей</h3>
                <p style="color: black">
                    Если Вы забыли пароль и не можете войти в свою учетную запись, воспользуйтесь инструкцией <a href="/privacy-policy/passwordRecovery">тут</a>. Это поможет Вам восстановить доступ к аккаунту Skyservice.
                </p>
                <h3>Cookies</h3>
                <p style="color: black">
                    SkyService POS использует файлы cookies, которые автоматически
                    генерируются во время вашего взаимодействия с SkyService POS. Вы
                    можете настроить свой браузер и/или устройство не принимать
                    cookies, но если сделать это, то использование SkyService POS
                    может быть невозможным или некорректна работа.
                </p>
                <h3>Поддержка</h3>
                <p style="color: black">
                    В случае возникновения вопросов по использованию SkyService POS,
                    свяжитесь с нами по адресу m@skyservice.pro
                </p>
            </div>
        </div>
        <div v-if="currentLang === 'uk'">
            <h2 style="text-align: center">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h2>
            <div class="info-text">
                <p style="color: black">
                    Прочитайте актуальну політику, що є конфіденційною, використовуючи
                    SkyService POS. Якщо ви хочете створити Аккаунт, вам потрібно прийняти
                    Учасники та політику конфіденційності, а також інші документи та
                    рекомендації SkyService POS, які посилаються на ці документи.<br /><br />
                    Ця Політика конфіденційності була оновлена 16 травня 2017 року.
                </p>
                <h3>Конфіденційність</h3>
                <p style="color: black">
                    SkyService POS прагне до збереження конфіденційності інформації всіх
                    своїх користувачів.<br /><br />
                    Визначення термінів, які використовуються в даному документі, наведені в
                    Умовах використання ("Умови").
                </p>
                <h3>Поширення</h3>
                <p style="color: black">
                    Ця Політика конфіденційності поширюється на всю інформацію, надану
                    Користувачами при використанні SkyService POS, в тому числі по
                    електронній пошті, а також будь-яку інформацію, яка може бути
                    автоматично доступна при використанні SkyService POS.
                </p>
                <h3>Згода</h3>
                <p style="color: black">
                    Реєструючись на Сайті і / або використовуючи Додаток, користувачі дати
                    йому свою згоду на збір, використання, розголошення, зберігання і
                    обробку їх інформації в відповідності з цією політикою
                    конфіденційності.<br /><br />
                    Не використовуйте SkyService POS, якщо Ви не готові прийняти Умови
                    використання та цю Політику конфіденційності. Приймаючи умови цієї
                    Політики конфіденційності, Ви підтверджуєте свою згоду на збір і обробку
                    певної інформації про Вас з метою описаних нижче.<br /><br />
                    При використанні SkyService POS Ви погоджуєтеся та гарантуєте, що є
                    повнолітнім і дієздатним особою, яка спроможна бути в повній мірі не
                    тільки носієм прав, а й нести відповідальність за свої дії.
                </p>
                <h3>Зміни в політиці конфіденційності</h3>
                <p style="color: black">
                    SkyService POS може змінювати або оновлювати справжню Політику
                    конфіденційності час від часу, з або без повідомлення користувачів.
                    Використовуючи SkyService POS, Ви погоджуєтеся дотримуватися вимог
                    Політики конфіденційності, яка є чинною на момент вашого доступу до
                    SkyService POS.
                </p>
                <h3>Надана інформація</h3>
                <p style="color: black">
                    Для того, щоб користуватися Послугами, все Користувачі повинні будуть
                    представити певну інформацію про себе, яка не є персональними даними і
                    не може персоніфікувати конкретну фізичну особу. Можливо, в деяких
                    випадках, потрібно надати додаткову інформацію, в тому числі якщо цього
                    вимагає чинне законодавство України щодо юридичних осіб. Якщо Ви
                    використовуєте SkyService POS, ми можемо збирати і зберігати таку
                    інформацію: 1. Вашу адресу електронної пошти і пароль для входу на сайт
                    в зашифрованому вигляді; 2. Ваші телефони (службові, робочі); 3. Ваше
                    місце розташування, тип браузера і операційної системи, способи
                    перегляду веб-сайту (наприклад, через cookies) і історію продажів; 4.
                    іншу інформацію, яку Ви самі нам надаєте або інформацію про Вас, яку ми
                    можемо отримувати автоматично на підставі Вашої взаємодії з SkyService
                    POS. Ви можете взаємодіяти з SkyService POS, наприклад, якщо ви: 1.
                    зареєструвалися, підписалися, дозволили передачу даних або створили
                    аккаунт на Сайті або використовуючи Додаток; 2. відкрили і / або
                    відповіли на електронні листи від SkyService POS; 3. використовуєте Сайт
                    та / або Додаток SkyService POS; 4. а також в будь-яких інших випадках
                    при використанні Вами SkyService POS.
                </p>
                <h3>Інформація, яка не збирається</h3>
                <p style="color: black">
                    Ми не запитуємо від Вас фінансові документи, банківські реквізити, а
                    також персональні дані щодо Вас або будь-яких третіх осіб, в тому числі
                    П. І. Б., копії документів, що підтверджують особу та ідентифікаційний
                    номер фізичних осіб, і Ви зобов'язуєтеся не надавати нам такі дані. Ми
                    не виробляємо зберігання, обробку і передачу персональних даних.<br /><br />
                    Якщо Вас просять надати таку інформацію при використанні SkyService POS,
                    негайно зв'яжіться зі службою підтримки SkyService POS.
                </p>
                <h3>Опції</h3>
                <p style="color: black">
                    SkyService POS дозволяє управляти наданими Вами даними і обмежувати
                    способи взаємодії SkyService POS з Вами.<br /><br />
                    Але, навіть якщо Ви вирішите скасувати оповіщення, у SkyService POS все
                    одно може виникнути необхідність зв'язатися з Вами, щоб донести важливу
                    інформацію за операціями, пов'язаних з Вашим Аккаутном. В цьому випадку
                    ми направимо Вам повідомлення на електронну пошту.
                </p>
                <h3>Використання інформації та даних</h3>
                <p style="color: black">
                    Інформація, надана Вами, буде використовуватися тільки в цілях надання
                    Послуг, спілкування з Вами щодо Послуг або співпраці з третіми особами,
                    якщо це не суперечить чинному законодавству України. SkyService POS
                    залишає за собою право використовувати дані (на анонімній основі) в
                    маркетингових цілях.
                </p>
                <h3>Розкриття інформації</h3>
                <p style="color: black">
                    SkyService POS не розкриває інформацію користувачів третім особам, за
                    винятком таких випадків: 1. корпоративного продажу, злиття,
                    реорганізації, ліквідації або аналогічної події, коли інформація (дані)
                    можуть бути частиною переданих немайнових активів; 2. дані можуть бути
                    доступні партнерам і афілійованим особам SkyService POS з метою
                    просування і поліпшення SkyService POS, на умовах, сумісних з цією
                    політикою збереження конфіденційності; 3. у випадках залучення третіх
                    осіб для виконання певних функцій, пов'язаних з використанням SkyService
                    POS і поліпшенням якості Послуг. Інформація в таких випадках передається
                    в обсязі, необхідному для надання послуг такими особами, а також
                    відповідно до вимог чинної Політики конфіденційності; 4. у випадках,
                    передбачених чинним законодавством, а також з метою попередження
                    шахрайства, усунення технічних неполадок і / або проблем з безпекою,
                    захисту прав та інтересів, власності або безпеки SkyService POS і / або
                    його правовласників, користувачів або інших осіб, як це вимагається і
                    допускається законодавством.
                </p>
                <h3>Доступ і оновлення інформації</h3>
                <p style="color: black">
                    Користувачі можуть в будь-який час змінювати, доповнювати або видаляти
                    надану ними інформацію за їхнім бажанням.
                </p>
                <h3>Зберігання інформації</h3>
                <p style="color: black">
                    SkyService POS буде надійно зберігати інформацію своїх
                    Пользователей.Такім чином, інформація і дані, отримані від користувачів,
                    можуть зберігатися за межами території України.
                </p>
                <h3>Видалення інформації</h3>
                <p style="color: black">
                    Для видалення облікового запису та зібраних даних вашого облікового запису Ви можете негайно скористатися функцією видалення облікового запису, яка розташована в особистому кабінеті, про те, як це зробити, можна прочитати <a href="/privacy-policy/delete-account">тут.</a>
                </p>
                <h3>Відновлення паролів</h3>
                <p style="color: black">
                    Якщо Ви забули пароль і не можете увійти до свого облікового запису, скористайтесь інструкцією <a href="/privacy-policy/passwordRecovery">тут</a>. Це допоможе Вам відновити доступ до облікового запису Skyservice.
                </p>
                <h3>Cookies</h3>
                <p style="color: black">
                    SkyService POS використовує файли cookies, які автоматично генеруються
                    під час вашого взаємодії з SkyService POS. Ви можете налаштувати свій
                    браузер і / або пристрій не брати cookies, але якщо зробити це, то
                    використання SkyService POS може бути неможливим або некоректна робота.
                </p>
                <h3>Підтримка</h3>
                <p style="color: black">
                    У разі виникнення питань щодо використання SkyService POS, зв'яжіться з
                    нами за адресою m@skyservice.pro
                </p>
            </div>
        </div>
        <div v-if="currentLang === 'en'">
            <h2 style="text-align: center">PRIVACY POLICY</h2>
            <div class="info-text">
                <p style="color: black">
                    Using SkyService POS envisages respect for the confidentiality of
                    personal information, the principles of openness in matters of privacy,
                    as well as giving you control over your information and data relating to
                    the use of SkyService POS.<br /><br />

                    Definitions of terms, used in this document are set out in the Terms of
                    Use. This Privacy Policy sets out the conditions for handling of
                    information that is provided in the course of Your use of SkyService POS
                    and other collectible data. Please read carefully this Privacy Policy
                    before using SkyService POS. If you want to create an Account, you need
                    to accept the Terms of Use and Privacy Policy, as well as other
                    documents and recommendations from SkyService POS, which refer to these
                    documents. Do not use SkyService POS if you are not ready to accept the
                    Terms of Use and this Privacy Policy. By accepting the terms of this
                    Privacy Policy, you give your consent for the collection and processing
                    of certain information about you for the purposes described below. The
                    authenticity of obtained (collected) data about the persons using
                    SkyService POS, shall not be verified. This Privacy Policy was updated
                    on 17 May 2017.
                </p>
                <h3>Information Collection</h3>
                <p style="color: black">
                    If you are a User, data collection occurs: when registering through the
                    Site; when You download the application; when you make a purchase at one
                    of SkyService POS Merchants and provide him/her Your e-mail address to
                    register You as a SkyService POS User, thereby agreeing to the Terms of
                    Use and this Privacy Policy. If you are a Merchant, information is
                    collected during the Merchant Account registration.
                </p>
                <h3>What information is collected by SkyService POS?</h3>
                <p style="color: black">
                    Personal information provided by you: personal information - this is
                    information voluntarily provided by you. It can be information such as
                    your name, nickname, email address, phone number, birthday, location,
                    information about your use of the Services and other information
                    specified by you in your SkyService POS Account. In particular,
                    SkyService POS may ask the Merchant to provide information about its
                    business, for example, such as the name of the shop, mailing address,
                    business phone number, tax identification number (or other similar
                    identification number), and other relevant information, which may also
                    be considered public in accordance with law of the relevant
                    jurisdiction. The information you provide allows you, in particular, to
                    set up a Merchant or User Account, which can be used for further
                    Merchant and User interaction via SkyService POS. SkyService POS Account
                    in associated with your email. Information provided by You may be used
                    by SkyService POS to assess and improve the quality and efficiency of
                    services and for the development of new applications. Information
                    collected automatically: during your use of SkyService POS, some
                    information is collected automatically through a protocol from your
                    browser or mobile application, including details such as your location,
                    unique device identifiers, IP-address, browser type, operating system,
                    mobile operator, devices and applications ID, data from cookie-files and
                    information on webpages you have requested from SkyService POS. Except
                    as otherwise required by law, such data are treated as Impersonal
                    information, that is one that does not identify you personally. Unless
                    otherwise stated in this Privacy Policy, SkyService POS uses this
                    information aggregate with personal information. This allows to analyze
                    how one uses SkyService POS. Information obtained from third parties:
                    SkyService POS can receive and store information about you from third
                    parties that interact with SkyService POS one way or another: а) when
                    you use third-party applications, and other services third parties to
                    interact with SkyService POS. Thus, some applications and services you
                    use at your discretion can transmit information about you. To find out
                    what exact information is passed by such applications and services, read
                    their privacy policies. We may also capture the subject and content of
                    incoming e-mails, which helps generally to better understand your needs
                    and improve the quality of services; b) when attracting third parties to
                    perform certain tasks related to the provision of Services (such as
                    sending notifications).
                </p>
                <h3>What information is collected?</h3>
                <p style="color: black">
                    SkyService POS does not request nor collect any personal data relating
                    to You or any third party, including name, a copy of identity documents
                    and identification number. Any personal data provided by you
                    unilaterally will be removed from SkyService POS. SkyService POS does
                    not deal with storage, processing and transmission of your personal
                    data.
                </p>
                <h3>How is being handled personal data?</h3>
                <p style="color: black">
                    You agree and accept that Merchants and possibly other SkyService POS
                    Users may have access to Your data. One of the key SkyService POS tasks
                    is to acquaint Users with potentially interesting for them Merchants
                    (companies, shops, events), as well as allow Merchants to convey to
                    Users information about their companies, brands, shops or events, and
                    encourage Users to visit these places or activities using commercial
                    incentives – Rewards. For example, when you visit a Merchant,
                    information about your visits is automatically available to the
                    Merchant, which allows him/her to offer you a higher level of service
                    and Rewards. SkyService POS does not sell personal information and does
                    not transmit it to third parties. You bear in mind that Merchants, when
                    browsing information about Users, in the case of misconduct, can take
                    advantage of such information or transfer it to third parties or
                    organizations without your knowledge or consent from SkyService POS.
                    Therefore, you agree to exercise due diligence in the provision of
                    information about yourself.
                </p>
                <h3>Use of SkyService POS by children and minors</h3>
                <p style="color: black">
                    SkyService POS can not be used by a person under 13 years of age (or
                    under another age prescribed by law in the relevant jurisdiction). If
                    you become aware of the use of SkyService POS by children under 13
                    years, immediately contact SkyService POS Customer support for action to
                    remove these accounts and data associated with those. Persons under the
                    age of majority may use SkyService POS only with the approval of a
                    parent or guardian unless otherwise provided by the law in the relevant
                    jurisdiction.
                </p>
                <h3>Information disclosure and other legal requirements</h3>
                <p style="color: black">
                    Your personal information may be available to third parties, in the
                    following cases: Business Transfers: In the event of a corporate sale,
                    merger, reorganization, liquidation or similar events, personal data may
                    be part of the intangible assets transferred. Partners: Your personal
                    data may be made available to SkyService POS partners and affiliates to
                    promote SkyService POS products, on conditions compatible with this
                    Privacy Policy. Agents, consultants, and third parties: SkyService POS
                    may engage third parties to perform certain functions related to the
                    provision of services (for example, sending e-mails, push-messages,
                    etc.). In such cases, provided information is transmitted in the volumes
                    necessary for the provision of services by such parties. At the request
                    of the law: Your personal data may be disclosed to third parties not
                    connected with SkyService POS, in case when can be assumed in good faith
                    that to obtain, use, preserve or disclose such information is reasonably
                    necessary for the purpose of a) carrying out or complying with the
                    current laws, normative legal act, judicial decision or forcibly
                    executable request of a public authority; b) enforcing the Terms of Use,
                    or investigating their possible violations; c) identifying, curbing, or
                    otherwise preventing fraud, as well as eliminating technical faults or
                    security problems; d) protecting the rights, property or safety of
                    SkyService POS, the SkyService POS Merchant / User or other persons as
                    required and permitted by law; d) other cases provided by law.
                </p>
                <h3>Determination and disclosure of locations</h3>
                <p style="color: black">
                    When you use SkyService POS on your computer or mobile device, then
                    geolocation data received from the browser of your computer and / or
                    device (for example, longitude and latitude) allows to provide
                    information related to Your location. Your geolocation data from
                    SkyService POS Application is not disclosed. However, for example, when
                    you leave a comment or rate a Merchant through SkyService POS
                    Application, then, presumably the Merchant can draw the conclusion that
                    this User either has been or is still in his shop (at the Merchant).
                </p>
                <h3>Transactions History. Receipts.</h3>
                <p style="color: black">
                    Transactions and Receipts History is stored in the SkyService POS
                    database to which you have access. If for whatever reason you decide to
                    delete your account, such information will be deleted from the
                    SkyService POS database automatically.
                </p>
                <h3>Emails</h3>
                <p style="color: black">
                    By submitting to SkyService POS your email address, you agree to receive
                    emails. Such emails can be linked to major events in SkyService POS,
                    information about special offers from the Merchant, information about
                    other products and services developed or provided by SkyService POS, and
                    other information. If you wish to opt out of the notifications, you need
                    to perform the actions specified in such a notification or use the
                    SkyService POS app Settings.
                </p>
                <h3>Cookie-files and similar technologies</h3>
                <p style="color: black">
                    SkyService POS uses technologies such as cookie-files, which are small
                    text files that are automatically generated during your interaction with
                    SkyService POS. You can set your browser not to accept cookies, but if
                    you do so, the use of all SkyService POS areas or all of its features
                    and functionality will be impossible. For more information, including
                    regarding the types of cookie-files and related technologies used by
                    SkyService POS, please see the Cookies Policy.
                </p>
                <h3>Is personal information really protected?</h3>
                <p style="color: black">
                    For safety and privacy purposes, your personal information, as part of
                    your SkyService POS Account, is password protected. To prevent
                    unauthorized access to your Account and Personal information, you should
                    also take reasonable measures to secure your password, possibly
                    excluding the circumstances in connection with which your password can
                    be known to third parties. SkyService POS makes all reasonable measures
                    to protect the confidentiality of information and Accounts. These
                    measures may include, among others, encryption, physical access security
                    and other appropriate technologies. But be advised, no method of
                    transmission over the Internet or method of electronic storage is 100%
                    secure. SkyService POS never asks for information about your credit
                    card, password, login, identification number and other personal
                    information. If someone approaches you with a request to provide such
                    information, immediately notify SkyService POS Customer Support.
                </p>
                <h3>How to delete the account?</h3>
                <p style="color: black">
                    To delete your account and the collected data of your account, you can immediately use the account deletion function located in your personal account, you can read about how to do this <a href="/privacy-policy/passwordRecovery">here</a>.
                </p>
                <h3>Password recovery</h3>
                <p style="color: black">
                    If you have forgotten your password and cannot log in to your account, please follow the instructions <a href="/privacy-policy/passwordRecovery">here</a>. This will help You regain access to your account Skyservice.
                </p>
                <h3>Will Privacy Policy undergo any changes?</h3>
                <p style="color: black">
                    SkyService POS reserves the right to update and change this Privacy
                    Policy at any time and without notice. Please follow the changes to this
                    Privacy Policy. Your continued use of SkyService POS after any changes
                    to the Privacy Policy indicates your acceptance of such changes.
                </p>
                <h3>What if questions or concerns will arise?</h3>
                <p style="color: black">
                    If you have questions or concerns regarding the use of SkyService POS,
                    you can send a detailed email to m@skyservice.pro The more specific the
                    question is set out, the faster and more accurate SkyService POS
                    Customers support will be able to answer.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store.js";
import SelectLangs from '../LangSelectComponent.vue';
import headloginreg from "../heads/HeaderLoginAndReg.vue"
export default {
    components: {
        headloginreg,
        SelectLangs
    },
    data () {
        return {
            currentLang: "",
            language: [
                { name: 'uk', text: 'UK' },
                { name: 'ru', text: 'RU' },
                { name: 'en', text: 'EN' }
            ],
            currentSpan: {
                descUK: 'Авторизація',
                descRU: 'Авторизация',
                descEN: 'Authorization'
            }
        }
    },
    created() {
        // костыль для политик
        switch(store.lang){
            case "ru":  
            case "uk":
            case "en":
                this.currentLang = store.lang;
                break;
            default:
                this.currentLang = 'en';
                break;

        }
    },
    methods: {
        changeLang(l) {
            localStorage.setItem("lang_user", l);
            store.lang = l;
            window.lang = store.langs[store.lang]();
            this.currentLang = l
        },
        goLogin() {
            this.$router.push("/");
        },
    },
};
</script>
<style scoped>
.currentSpan{
    cursor: pointer;
    color:#106090;
    margin-top: 12px;
}
.custom-select{
    cursor: pointer;
}
.special {
    display: flex;
    justify-content: space-between;
    height: 35px;
}
body{
    overflow: scroll;
}
</style>