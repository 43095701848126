import axios from 'axios';
import { store } from '@/store.js'
import Cookies from 'js-cookie';
const url = '//api.auth.skyservice.online';

axios.defaults.baseURL = url;

// if(process.env.NODE_ENV != 'production') {
//     Cookies.set("token", "k2k7T63EHka4HyFFQFEQYzfHkFH9FF5G");
// }

axios.interceptors.request.use(config => {
    try {
      store.loader = true;
      config.params = {
        token: Cookies.get("token"),
        timezone: (new Date().getTimezoneOffset())/60,
        ...config.params
      };
      // console.log(config)
    } catch (err) {
      console.log(err)
      store.loader = true;
    }
    return config;
  }, (error) => {
    store.loader = false;
    if (store.syncChecksInterval < 15000) {
      store.syncChecksInterval = store.syncChecksInterval + 1000;
    }
    return Promise.reject(error);
  })


axios.interceptors.request.use(function(config) {
    //Сделайте что-нибудь до отправки запроса
    try {
        if (config.params.concatenation != "true") {
            store.loader = true;
        }
        delete config.params.concatenation;
    } catch (err) {
        store.loader = true;
    }
    return config;
}, function(error) {
    store.loader = false;
    //Сделать что-то с ошибкой запроса
    return Promise.reject(error);
});
axios.interceptors.response.use(function(response) {
    //Сделайте что-нибудь с ответными данными
    store.loader = false;
    return response;
}, 
function(error) {
    // Сделать что-то с ошибкой ответа
    store.loader = false;
    try {
        if (JSON.parse(JSON.stringify(error)).response.status == 401 && process.env.NODE_ENV == 'production') {
            window.location = '/';
        }
    } catch {
        //
    }
    // app.messageError(lang["ErrorConnection"]);
    return Promise.reject(error);
});


export class Start {
    getStart() {
        return axios.get('?section=users&action=getStart');
    }
}

export class MoreURL {
    goDemo(lang) {
        return `https://online.skyservice.pro/?section=pos&login=demo&lang=${ lang }`;
    }

    goEmergencyEntrance(lang) {
        return `https://online.skyservice.pro/?emergency_entrance=3&lang=${ lang }`;
    }
}

export class regAPi {
    /**
     * изначально мы делаем регистрацию по номеру телефона
     * после чего предлагаем юзеру дополнить данные о себе
     */
    phoneRegistration(obj) {
        return axios.post('?section=users&action=registPhone', obj);
    }
    quickRegistration(obj) {
        return axios.post('?section=users&action=quickRegister', obj);
    }
    // для модалки подтверждения почты
    checkEmail(uuid_token, token, new_email, lang) {
        return axios.get(`?section=users&action=checkEmail&uuid_token=${uuid_token}&token=${token}&new_email=${new_email}&lang=${lang}`);
    }

    // для удаления номера с базы если клиент нажал "не мой номер"
    notMyPhone(phone) {
        return axios.post('?section=users&action=notMyNumber', {phone});
    }
    
    // Регистрация вручную 
    registration(obj) {
        return axios.post('?section=users&action=registration', obj);
    }
    // Регистрация или вход через FB
    registrationFB(obj) {
        return axios.post('?section=users&action=registerFB', obj);
    }
    // Регистрация через Гугл
    registrationGoogle(obj) {
        return axios.post('?section=users&action=registerGoogle', obj);
    }
    // Регистрация Телеграм 
    registrationTelegram(obj) {
        return axios.post('?section=users&action=registerTelegram', obj);
    }
    // реєстрація через Apple
    registrationApple(obj) {
        return axios.post('?section=users&action=registerApple', obj);
    }
    /**
     * Конец регистрации
     */
    // проверка аккаунта на регу
    // привязать ФБ
    authFB(obj) {
        return axios.post('?section=users&action=authFB', obj);
    }
    // Привяываем гугл
    authGoogle(obj) {
        return axios.post('?section=users&action=authGoogle', obj);
    }
    // привязываем Телеграмм
    authTelegram(obj) {
        return axios.post('?section=users&action=authTelegram', obj);
    }
    // прив'язуєм Apple
    authApple(obj) {
        return axios.post('?section=users&action=authApple', obj);
    }
    // прив'язуєм пошту з Apple до існуючого аккаунту
    bindSocialApple(obj) {
        return axios.post('?section=services&action=linkApple', obj);
    }
    // привязываем почту гугла на существующий аккаунт 
    bindSocialGoogle(obj) {
        return axios.post('?section=services&action=linkGoogle', obj);
    }
    // привязываем почту фейсбука на существующий аккаунт
    bindSocialFacebook(obj) {
        return axios.post('?section=services&action=linkFacebook', obj);
    }
    checkAccount(login) {
        return axios.post('?section=users&action=checkLogin', login)
    }
    // Изменить пароль
    rePassword(obj) {
        return axios.post('?section=users&action=editPassword', obj);
    }
    // Смена языка
    language(lang) {
        return axios.post('?section=users&action=editLanguage', lang);
    }
    // логин ручками
    personLogin(obj) {
        return axios.post('?section=users&action=auth', obj);
    }
    // Проверка капчи 
    forgotPasswordCaptcha(obj) {
        return axios.post('?section=users&action=forgotPassCaptcha', obj);
    }
    // Проверка СМС при Забыли пароль с телефона
    forgotPasswordPhone(obj) {
        return axios.post('?section=users&action=forgotPassByPhone', obj);
    }

    // после берем его новый парол  который он вбил и отправляем на сервак
    sendNewPassword(obj) {
        return axios.post('/?section=users&action=changePassword', obj);
    }
   // Captcha img
    captchaUID(uid) {
        return  `${ url }?section=users&action=captcha&captcha_guid=${ uid }`
    }
    // валидация формата номера телефона
    phoneFormatValidation(obj){
        return axios.get('?section=users&action=isPhoneValid', {params: obj})
    }
}

export class RedirectApi {
    redirectCompany(obj) {
        return axios.post(`//staging.skyservice.pro/api/?section=workers&action=checkToken`, obj);//&token=${token}
    }
}
