<template>
    <div>
        <smallModal v-if="!showSmallModal" @escape="$emit('close')">
            <div v-if="ccc != null">
                <div id="captcha-forgot-div">
                    <button @click="captchaImg" class="btn">
                        <b-icon-arrow-repeat id="captcha-forgot-pass"></b-icon-arrow-repeat>
                    </button>
                </div>
                <div>
                    <div class="img-ccc">
                        <img id="ccc" :src="ccc">
                    </div>
                </div>
            
                <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
                <b-form-input v-model="captcha" :state="capState" autofocus :name="captchaName" class="captcha-forgot" />
            </div>

            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success" @click="save">{{ lang['Save'] }}</button>
            </div>
        </smallModal>
        <smallModal v-if="showSmallModal" @escape="$emit('close')">
            <div class="center">
                <p>{{ lang['AMessageHasBeenSentToTheSpecifiedLogin'] }}</p>
            </div>
            <hr>
            <div>
                <button class="btn btn-success okbtn" @click="$emit('close')">{{ lang['ok'] }}</button>
            </div>
        </smallModal>
    </div>
</template>

<script>
import { keyevent } from '@/main.js';
import { errList } from '../js/CaptchaAndPassError';
import { rotate } from '../js/rotateBtn';
import { guid } from '../js/guid';
import smallModal from '../modals/smallModal';
import { store } from '@/store.js';
import { regAPi } from '@/API.js';
const apiList = new regAPi();
export default {
    props: ['personLogin'],
    components: {
        smallModal
    },
    data() {
        return {
            showSmallModal: false,
            ccc: null,
            guidNow: null,
            captcha: '',
            captchaName:'captcha_' + this.getRandNum(999,99999)
        }
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () => this.showSmallModal ? this.$emit('close') : this.save())
    },
    created() {
        this.captchaImg();
    },
    methods: {
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        captchaImg() {
            try {
                // создаем гуид (файл guid.js) который скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.guidNow = a;
                // крутилка для кнопки
                rotate('#captcha-forgot-pass');
            } catch(err) {
                // console.log(err)
            }
        },
        save() {
            try {
                if (this.capState) {
                    apiList.forgotPasswordCaptcha({ captcha_guid: this.guidNow, captcha: this.captcha, login: this.personLogin, lang: store.lang }).then(resp => {
                        if (resp.data.status == 'done') {
                            // проверяем ЛОГИН- это телефон или почта
                            const mailRegular  = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
                            if (mailRegular.test(this.personLogin) == false) {
                                // если это не почиа, тогда направляем юзера в окно с СМС
                                this.$emit('getSms');
                                this.$emit('close');
                            } else {
                                this.showSmallModal = true
                            }
                        } else {
                            switch(resp.data.error[0]){
                                case 'Captcha dont exist':
                                    //меняем капчу если есть ошибка о том что капча больше недействительна
                                    this.captchaImg();
                                    break;
                            }
                            // функция принимающая в себя ошибки и выводящая юзеру ответ об ошибке
                            errList(resp.data.error[0]);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['FillInTheFieldsCorrectly']);
                }
                
            } catch(err) {
                // console.log(err)
            }
        }
    },
    computed: {
        lang() {
            return window.lang
        },
        capState() {
            return this.captcha.length == 4 ? true : false;
        }
    }
}
</script>
<style scoped>
#captcha-forgot-pass{
    height: 2rem; 
    width: 2rem;
}
#captcha-forgot-div{
    display: flex; 
    justify-content: flex-end;
}
.btn-secondary, .btn-success{
    width: 49%;
}
.okbtn{
    width: 100%;
}
.buttons{
    display: flex;
    justify-content: space-between;
}
#ccc{
    height: 100px;
}
.img-ccc{
    text-align: center; 
    height: 100px;
}
.center{
    text-align: center;
}
.btn-success-save{
    width: 99%;
}
</style>