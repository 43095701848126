<template>
  <div class="container">
    <a href="/"><headloginreg /></a>
    <br>
    <div class="special">
            <div class="currentSpan" @click="goLogin"><b-icon icon="chevron-left" style="margin-right:3px;" font-scale="1.25"></b-icon><span>{{ currentLang === 'ru' ? currentSpan.descRU : currentLang === 'uk' ? currentSpan.descUK : currentSpan.descEN  }}</span></div>
            <div class="specialSelect">
                <SelectLangs @changeLang="changeLang($event)" :utilityLang="currentLang" :limited="language" />
            </div>
        </div>
    <hr>
    <div v-if="currentLang ==='ru'">
        <h1 style="text-align: center">УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</h1>
        <div class="info-text">
            <h3>Общее</h3>
            <!-- <br/><br/> -->
            <p style="color:black;">
                Эти условия использования ("Условия"), а также связанные с ними дополнения и Политика конфиденциальности, несут в себе обязательные требования при использовании веб-сайта skyservice.pro и услуг, предлагаемых через разработанное программное приложение SkyService POS.<br/><br/>
                Все права, не предоставленные здесь явно по использованию программного приложения, принадлежат законному правообладателю SkyService POS (“Мы”/“нас”).
                Вам предоставляется ограниченная, неисключительная, непередаваемая, отзывная лицензия на использование SkyService POS на территории Украины в соответствии с настоящими Условиями.
                <br/><br/>
                Если Вы не согласны с любым из этих Условий, то не используйте SkyService POS, так как такое использование предполагает полное и безоговорочное согласие, включая принятие ответственности за их нарушение.
                <br/><br/>
                Эти Условия использования были обновлены 16 мая 2017 года.
            </p>
            <h3>Визначення</h3>
            <p style="color:black;">
                В этих Условиях термины имеют следующее значение:
                <br />
                <ul>
                    <li>
                        "SkyService POS" – совокупность программного обеспечения, Приложений, Сайтов, служб, документации, текстовых, графических и других материалов, позволяющих пользоваться Услугами.
                    </li>
                    <li>
                        "Услуги" - услуги, предоставляемые с помощью Сайта и/или Приложения.
                    </li>
                    <li>
                        "Приложение" - программное обеспечение, позволяющее управлять продажами товаров и услуг, а также любые другие обновления, технологии или функции, предлагаемые нами время от времени, с информацией о которых можно познакомиться на Сайте.
                    </li>
                    <li>
                        "Сайт" означает веб-сайт skyservice.pro
                    </li>
                    <li>
                        "Вы/Ваш" или "Пользователь" - любое лицо, использующее Приложение и/или Сайт.
                    </li>
                </ul>
            </p>
            <h3>Изменения</h3>
            <p style="color:black;">
                Эти Условия могут быть изменены или дополнены. Мы стремимся уведомлять Вас о таких изменениях по электронной почте или путем отображения сообщения через Приложение SkyService POS. В случае, если Вы продолжаете использовать Приложение SkyService POS после каких-либо изменений, такое использование будет регулироваться новой версией Условий.
                <br /><br />
                Время от времени могут добавляться, вноситься изменения или удаляться полностью или частично компоненты функциональности Приложения. В таких случаях Вам может потребоваться установить новую и/или обновленную версию Приложения для дальнейшего использования. Это так же означает, что мы можем принять решение удалить некоторые компоненты Приложения в любое время, и ничто в настоящих Условиях не следует рассматривать как гарантию того, что само Приложение или любой из компонентов SkyService POS всегда будет доступен в их нынешнем виде или в виде последующих обновленных версий.
            </p>
            <h3>Информация об Аккаунте</h3>
            <p style="color:black;">
                Аккаунт — создаваемая Пользователем учётная запись Пользователя, которая представляет собой хранимую совокупность данных о Пользователе на удаленном облачном сервисе, необходимую для его опознавания (аутентификации) в программе SkyService POS и предоставления доступа к его данным, настройкам, а также к иной информации, доступной через Приложение.<br /><br />
                При регистрации Аккаунта предоставляется адрес электронной почты, который необходим для коммуникации и последующей идентификации Пользователя, включая название торговой точки (заведение, магазин и т.п.).<br /><br />
                При использовании SkyService POS Вы соглашаетесь и гарантируете, что являетесь совершеннолетним и дееспособным лицом, способным быть в полной мере не только носителем прав, но и нести ответственность за свои действия.<br /><br />
                Для полноценного использования Приложения Вам необходимо зарегистрироваться. Вы несете персональную ответственность за все действия, совершенные при использовании Аккаунта непосредственно Вами или любыми другими третьими лицами, которым Вы самостоятельно предоставили доступ для совместного использования.<br /><br />
                Вы обязуетесь безотлагательно связаться с нами, если считаете, что какая-либо третья сторона несанкционированно использует Ваш Аккаунт.
                Размещая через Приложение информацию о торговых марках или другие сведения, защищенные авторским правом (например, логотип), Вы гарантируете наличие надлежащих прав на такое размещение. В случае отсутствия надлежащих прав на такое размещение и/или публикацию, Вы обязуетесь уклониться от таких действий.
                <br /><br />
                Мы не несем ответственность за точность, доступность и достоверность любой информации, предоставляемой Вами, иного содержания, товаров, данных, мнений, советов или заявлений. Это означает, что, используя SkyService POS, Вы самостоятельно несете ответственность за информацию, которая стала доступной благодаря Вам, включая текст, фотографии, сообщения и другие виды информации, а также за соблюдение всех действующих норм и законов, которые могут регулировать такое размещение, публикацию, оповещение и тому подобное.<br /><br />
                SkyService POS может попросить Пользователя предоставить более полную информацию о его бизнесе, например, такую как название компании, адрес, номер служебного телефона, налоговый идентификационный номер (или другой аналогичный идентификационный номер), включая другую информацию, которая также может считаться обязательной, по мнению действующего законодательства Украины.<br /><br />
                При регистрации Аккаунта Вы должны:
                <ul>
                    <li style="list-style-type: decimal;">
                        Не создавать учетную запись для других лиц или в их интересах.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не использовать имеющиеся сведения чужого лица с намерением выдать себя за это лицо.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не использовать в названии учетной записи слова, которые могут быть оскорбительными, вульгарными, непристойными или незаконными.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не создавать другой Аккаунт, если предыдущий был заблокирован.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не использовать SkyService POS способом, который выходит за рамки понимания добросовестного или законного, в зависимости от обстоятельств. Никто не может извлекать преимущество (выгоду) из своего незаконного или недобросовестного поведения.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не предоставлять доступ к Вашему Аккаунту посторонним лицам.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не нарушать авторские или другие права и интересы третьих лиц (например, использование в продвижении товаров или услуг чужой торговой марки или брэнда без соответствующего разрешения).
                    </li>
                </ul>

            </p>
            <h3>Особенности использования</h3>
            <p style="color:black;">
                SkyService POS не собирает персональные данные, которые позволяют каким-либо образом идентифицировать Вас лично.<br /><br />
                Пользователь принимает и соглашается, что использование SkyService POS осуществляется в границах и на основе принципов справедливости, разумности и добросовестности, закрепленных действующими законами Украины и/или обычаями делового оборота.<br /><br />
                Пользователь понимает и соглашается с тем, что SkyService POS используется на условиях «как есть» и «как доступно», без каких-либо гарантий, кроме прямо указанных в данных Условиях.<br /><br />
                SkyService POS может содержать ссылки на веб-сайты, программы, фото-, видео-, аудио-, графические и текстовые материалы, принадлежащие третьим лицам. Пользователь может переходить по ссылкам на веб-сайты третьих лиц, устанавливать программное обеспечение таких третьих лиц и использовать их материалы исключительно на свой страх и риск.<br /><br />
                Вы соглашаетесь, что SkyService POS может использовать услуги некоторых третьих лиц, чтобы обеспечить наилучшее качество услуг. Действия или бездействие таких третьих лиц может быть вне нашего контроля, и SkyService POS не несет никакой ответственности за любые убытки или ущерб, причиненный в результате какого-либо действия или бездействия такого третьего лица.<br /><br />
                Пользователь не вправе использовать SkyService POS, если законодательство Украины запрещает и/или устанавливает иные, в том числе возрастные, ограничения на использование такого программного обеспечения. В этом случае ответственность за использование SkyService POS услуг несёт сам Пользователь.<br />
                Мы можем блокировать действие Аккаунта любого Пользователя в любое время, если, по нашему добросовестному усмотрению, Вы совершили существенное нарушение этих Условий или любых других требований действующего законодательства Украины.<br /><br />
                Вы можете удалить свой Аккаунт, обратившись в техническую поддержку через Приложение или на Сайте.<br /><br />
                Если Ваш Аккаунт удаляется: <br />а) все предоставленные Вами данные автоматически удаляются; <br />б) все Ваши права, предоставляемые в соответствии с настоящими Условиями, немедленно прекращаются. Ваши данные не могут быть восстановлены при повторной регистрации. SkyService POS не несет ответственности за любой ущерб или убытки, которые могут возникнуть в результате удаления Вашего Аккаунта, и это Ваша ответственность убедиться, что любое содержимое или данные, которые вы хотите сохранить, были скопированы своевременно.<br /><br />
                За использование SkyService POS предусмотрена ежемесячная абонентская плата. <br />Зачисление поступающих на баланс пользователя не могут быть возвращены.<br /><br />
            </p>
            <p style="color:black;">
                Система автоматически устанавливает тариф согласно <a href="https://skyservice.pro/ru/prices/" style="color: #42a144;font-family: 'Montserrat';">тарифным планам</a>. Если после оплаты тарифного плана у вас добавился новый товар, технические карты или торговая точка, система автоматически пересчитает ваш тарифный план в соответствии с вашим новым действующим тарифом без каких-либо предупреждений в системе.<br />
                Тариф может быть перерассчитан по актуальному курсу валюты в вашем регионе. <br />
                При оплате тарифа через личный кабинет, или при покупки товара добавляется дополнительная комиссия в размере 2% от стоимости подписки или также 2,75% от стоимости товара. Комиссия взимается за удобство оплаты онлайн.<br /><br />

                Техническая поддержка доступна в чате или по почте, также вы можете позвонить в наш колл центр где вам предоставят техническую поддержку согласно компетенции или возможностями оператора, если оператор не может помочь в телефонном режиме нужно обратиться в чат технической поддержки или на почту.<br /><br />

                Для каждого тарифа на месяц выделяется 50 минут общений по телефону с оператором, если это время будет превышено система автоматически может сменить на тариф на более высокий с учетом голосовой поддержки. Если вам нужна индивидуальная поддержка напишите на почту для установления индивидуального тарифа с технической поддержкой по телефону. Цены на индивидуальную техническую поддержку начинаются от 50 $ в месяц.<br />
                Во время обращения в техническую поддержку или колл-центр, пожалуйста следуйте правила делового общения. Оператор или менеджер может отказать в дальнейшем общении если:<br />
                1. Используется НЕ цензурная лексика или брань<br />
                2. Употребляются слова, чтобы обидеть к оператору<br />
                3. Другое не корректное поведение, выходящее за рамки делового общения.<br />
                Также может быть увеличен тариф на следующий по тарифной сетке из-за некорректно общения с сотрудником компании.<br /><br />

                Все тестовые опции которые были включены могут начать тарифицироваться без предупреждений.
            </p>
            <h3>История транзакций. Чеки</h3>
            <p style="color:black;">
                История транзакций и Чеков хранится в базе данных SkyService POS, к которой Вы имеете доступ. Если по какой-то причине Вы примете решение удалить свой Аккаунт, то такие сведения будут удалены автоматически.<br /><br />
                Внимание! Чек, который формируется в Приложении при продаже товаров и предоставлении услуг, носит лишь информационный характер и используется для предоставления статистических данных при использовании Приложения, но при этом может не иметь всех необходимых реквизитов, которые требует действующее законодательство Украины. Это Ваша исключительная ответственность, а не SkyService POS, выдать расчетный документ в соответствии с требованиями действующего законодательства по требованию лица.<br /><br />
                Вы самостоятельно несете ответственность в отношении предоставления полной и недвусмысленной информации, как того требует законодательство о защите прав потребителей или иной другой закон, регулирующий данную сферу отношений.
            </p>
            <h3>Отсутствие гарантий</h3>
            <p style="color:black;">
                Использование SkyService Pos не предусматривает какие-либо гарантии, явные, подразумеваемые или предусмотренные законом, включая (без ограничений) подразумеваемые гарантии отсутствия нарушений.<br />
                Использование SkyService Pos не дает никаких гарантий, что (i) SkyService Pos будет отвечать всем вашим требованиям, (ii), SkyService Pos будет непрерывно, безопасно, или без ошибок работать, (iii) результаты, которые могут быть получены от использования SkyService Pos будут точными и надежными или (iv) качество любых услуг, информации или других материалов, ставших доступных вам при использовании SkyService Pos, будет отвечать всем вашим требованиям.
            </p>
            <h3>Ограничение ответственности</h3>
            <p style="color:black;">
                Вы понимаете и согласны с тем, что используя SkyService Pos, вы самостоятельно несете ответственность за какой-либо косвенный, случайный, специальный убыток или ущерб, за потерю прибыли, включая (без ограничений) потерю репутации, использования личных данных или других нематериальных активов, на основе соглашения, правонарушения, халатности, строгой ответственности или в иных случаях (без ограничений), в результате: (i) использования или невозможности использованияSkyService Pos; (ii) расходов на приобретения услуг, товаров, их замены и/или иного, в результате полученных от SkyService Pos данных, информации, сообщений и/или иного; (iii) несанкционированного доступа или изменения ваших сообщений или данных; (iv) заявлений или поведения какой-либо третьей стороны при использовании SkyService Pos; или (v) любых других проблем, связанных с использованием SkyService Pos.
            </p>
            <h3>Запрет вредоносного и незаконного использования</h3>
            <p style="color:black;">
                Вы не должны: (а) использовать SkyService POS любым незаконным образом, для любых незаконных или мошеннических целей, или каким-либо образом, несовместимым с настоящими Условиями; (б) нарушать наши права на интеллектуальную собственность или любого третьего лица при использовании SkyService POS; (в) использовать SkyService POS для распространения любых дискредитирующих либо оскорбительных материалов; (г) использовать SkyService POS таким образом, который может вывести из строя, нарушить или ослабить наши системы безопасности; (д) раскрывать третьим лицам или распространять информацию, относящуюся к другим пользователям, если таковая стала доступна при использовании SkyService POS и/или использовать информацию любого другого пользователя для каких-либо маркетинговых целей, если Вы не имеете соответствующего разрешения такого пользователя; или (е) совершать другие действия, запрещенные действующим законодательством Украины.<br /><br />
                При использовании SkyService POS запрещается распространение рекламного материала, доносящего информацию о лотереях; медицинских услугах и медицинских препаратах; пищевых добавках, диетических добавках; о политических организациях, партиях, движениях и течениях; информацию о товарах или услугах, которые подлежат обязательному лицензированию/сертификации, или информацию о несовершеннолетних лицах, конфиденциальную информацию о лице без его согласия на распространение такой информации, или информацию, которая предоставляется в виде перекрестной ссылки (например, предоставление информации, источником которой не является Пользователь или информации, содержание которой не соответствует тематике сообщений и / или не соответствует теме / торговой марке Пользователя, и т.д.).<br /><br />
                Запрещается отправлять какие-либо оповещения в процессе использования SkyService POS, содержащие информацию и / или имеющие признаки рекламы / информации об алкогольной, табачной продукции, знаков для товаров и услуг, других объектов права интеллектуальной собственности, под которыми выпускаются алкогольные напитки и табачные изделия; нецензурные слова (брань) и выражения, признаки недостоверной, конфиденциальной информации; информации, которая была получена преступным путем и / или которая нарушает авторские или смежные права третьих лиц; информацию эротического и / или порнографического характера; информацию, которая противоречит требованиям общественной морали; информацию, разжигающую межнациональную или межконфессиональную рознь, подстрекающую к этническим, религиозным и другим конфликтам, нарушающую основы государственности и конституционного строя и / или другую информацию, распространение которой запрещено законодательством Украины.
            </p>
            <h3>Интеллектуальная собственность</h3>
            <p style="color:black;">
                SkyService POS и его локализованные (переведенные на другие языки) версии, в том числе исходный и объектный код, графика, пользовательский интерфейс, любые программные элементы, музыкальное сопровождение и иное содержание, составляют и (или) содержат в себе объекты интеллектуальной собственности, исключительное право на которые принадлежит их законным правообладателям.<br /><br />
                Пользователь соглашается, что использование SkyService POS не может быть расценено как передача и (или) уступка исключительного права на SkyService POS либо его части от правообладателей SkyService POS к Пользователю.<br /><br />
                Авторские права на материалы, размещённые в SkyService POS Пользователем, принадлежат создавшему их Пользователю или иным законным правообладателям.<br /><br />
                Категорически запрещается любое использование SkyService POS, включая объекты интеллектуальной собственности или их части, без предварительного письменного разрешения правообладателей, кроме случаев, указанных в настоящих Условиях.<br /><br />
                Товарные знаки третьих лиц, упомянутые в SkyService POS или её описании, принадлежат их законным правообладателям.
            </p>
            <h3>Заключительные положения</h3>
            <p style="color:black;">
                Если одно или несколько положений настоящих Условий будут в установленном порядке признаны недействительными в соответствии со вступившим в силу судебным решением, остальные положения настоящего Соглашения остаются в силе.<br /><br />
                Все претензии, в том числе претензии, связанные с любой информацией, содержанием, товарами, данными, мнениями, советами, заявлениями и т.п., ставшими доступными в связи с использованием SkyService POS, предъявляются лицу, от которого возникла такая информация. В таких случаях SkyService POS не является надлежащей стороной возникновении каких-либо споров, разногласий, конфликтов и их разрешений. SkyService POS оставляет за собой право, но не обязанность, участвовать и/или способствовать справедливому разрешению таких споров, разногласий, конфликтов, как того требует закон или обычаи делового оборота.<br /><br />
                Все спорные вопросы по настоящим Условиям подлежат разрешению путём переписки и переговоров с использованием обязательного досудебного (претензионного) порядка.<br /><br />
                Все вопросы, связанные с использованием SkyService POS, направляйте на электронную почту m@skyservice.pro
            </p>
        </div>
    </div>
    <div v-if="currentLang ==='uk'">
        <h1 style="text-align: center">УМОВИ ВИКОРИСТАННЯ</h1>
        <div class="info-text">
            <h3>Загальне</h3>
            <p style="color:black;">
                Дані умови використання ("Умови"), а також пов'язані з ними доповнення та Політика конфіденційності, містять обов'язкові вимоги при використанні веб-сайту SkyService.pro і послуг, що пропонуються через розроблене програмне забезпечення SkyService POS.<br/><br/>
                Всі права, які не є явно представленими по використанню програмного додатку, належать законному правовласнику SkyService POS ("Ми" / "нас").<br/><br/>
                Вам надається обмежена, невиключна, непередавана, відклична ліцензія на використання SkyService POS на території України у відповідності з цими Умовами.<br/><br/>
                Якщо Ви не згодні із будь-якою з цих Умов, то не використовуйте SkyService POS, так як таке використання передбачає повну та беззастережну згоду, включаючи прийняття відповідальності за їх порушення.<br/><br/>
                Ці Умови використання були оновлені 16 травня 2017 року.

            </p>
            <h3>Визначення</h3>
            <p style="color:black;">
                У цих Умовах терміни вживаються в такому значенні:<br />
                <ul>
                    <li>
                        "SkyService POS" – сукупність програмного забезпечення, Додатків, Сайтів, служб, документації, текстових, графічних та інших матеріалів, що дозволяють користуватися Послугами.
                    </li>
                    <li>
                        "Послуги" – послуги, що надаються за допомогою Сайту та/або Додатку.
                    </li>
                    <li>
                        "Додаток" – програмне забезпечення, що дозволяє управляти продажами товарів і послуг, а також будь-які інші оновлення, технології або функції, запропоновані нами, з інформацією про які можна ознайомитися на Сайті.
                    </li>
                    <li>
                        "Сайт" означає веб-сайт SkyService.pro
                    </li>
                    <li>
                        "Ви/Ваш" або "Користувач" – будь-яка особа, що використовує Додаток та/або Сайт.
                    </li>
                </ul>
            </p>
            <h3>Зміни</h3>
            <p style="color:black;">
                Ці Умови можуть бути змінені або доповнені. Ми прагнемо повідомляти Вас про такі зміни електронною поштою або шляхом відображення повідомлення через Додаток SkyService POS. Якщо Ви продовжуєте користуватися Додатком SkyService POS після будь-яких таких змін, таке використання буде регулюватись новою версією Умов.<br /><br />
                Час від часу можуть додаватися, вноситися зміни чи видалятися повністю або частково функціональні компоненти Додатку. Якщо Ви використовуєте SkyService POS, Вам, можливо,стане необхідно встановити нову або оновлену версію Додатку перед тим, як Ви зможете скористатися Додатком. Це також означає, що ми можемо прийняти рішення видалити деякі компоненти Додатку в будь-який час, і ніщо у даних Умовах не слід розглядати як гарантію того, що сам Додаток або будь-який з компонентів SkyService POS завжди буде доступний в їхньому нинішньому вигляді або у вигляді наступних оновлених версій.
            </p>
            <h3>Інформація про Аккаунт</h3>
            <p style="color:black;">
                Аккаунт - створюваний Користувачем обліковий запис Користувача, який становить собою збережену сукупність даних про Користувача на віддаленому хмарному сервісі, необхідну для його розпізнавання (аутентифікації) в програмі SkyService POS і надання доступу до його даних, налаштувань, а також до іншої інформації, доступної через Додаток.<br /><br />
                При реєстрації Аккаунту обов’язково надається Ваш номер телефону, який необхіднтй для комунікації і подальшої ідентифікації Користувача, включаючи назву торгової точки (заклад, магазин і т.і.).<br /><br />
                При використанні SkyService POS Ви погоджуєтесь та гарантуєте, що є повнолітньою і дієздатною особою, здатною повною мірою бути не тільки носієм прав, а й нести відповідальність за свої дії.<br /><br />
                Для повноцінного використання Додатку Вам необхідно підтвердити свою електронну пошту. У разі непідтвердження вказаної Вами при реєстрації електронної адреси, Додаток буде доступний тільки для ознайомлення з його роботою і через 30 календарних днів Аккаунт буде автоматично видалений, включаючи всі дані, які були надані або збережені Вами раніше.<br /><br />
                Ви несете персональну відповідальність за всі дії, вчинені під своїм Аккаунтом, незалежно від того, здійснюється використання безпосередньо Вами, вашими співробітниками або іншими третіми особами, у випадках надання їм доступу до Аккаунту.<br /><br />
                Ви зобов'язуєтесь невідкладно зв'язатися з нами, якщо вважаєте, що будь-яка третя сторона несанкціоновано використовує Ваш Аккаунт.<br /><br />
                Розміщуючи через Додаток інформацію про торгові марки або інші відомості, захищені авторським правом (наприклад, логотип), Ви гарантуєте наявність належних прав на таке розміщення.<br /><br />
                Ми не несемо відповідальності за точність, доступність і достовірність будь-якої інформації, наданої Вами, іншого змісту, товарів, даних, думок, порад чи заяв. Це означає, що, використовуючи SkyService POS, Ви самостійно несете відповідальність за інформацію, яка стала доступною завдяки Вам, включаючи текст, фотографії, повідомлення та інші види інформації, а також за дотримання всіх чинних норм і законів, які можуть регулювати таке розміщення, публікацію, оповіщення тощо.<br /><br />
                SkyService POS може попросити Користувача надати більш повну інформацію про його бізнес, наприклад, таку як: назву компанії, адресу, номер службового телефону, податковий ідентифікаційний номер (або інший аналогічний ідентифікаційний номер), включаючи іншу інформацію, яка також може вважатися обов'язковою на вимогу чинного законодавства України.<br /><br />
                При реєстрації Аккаунту Ви повинні:
                <ul>
                    <li style="list-style-type: decimal;">
                        Не створювати обліковий запис для інших осіб або в їхніх інтересах.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не використовувати наявні відомості чужої особи з наміром видати себе за цю особу.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не використовувати в назві облікового запису слова, які можуть бути образливими, вульгарними, непристойними або незаконними.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не створювати інший Аккаунт, якщо попередній був заблокований або видалений.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не використовувати SkyService POS у спосіб, який виходить за рамки розуміння добросовісного або законного, залежно від обставин. Ніхто не може здобувати перевагу (вигоду) зі своєї незаконної або недобросовісної поведінки.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не надавати доступ до Вашого Аккаунту стороннім особам.
                    </li>
                    <li style="list-style-type: decimal;">
                        Не порушувати авторські або інші права та інтереси третіх осіб (наприклад, використання в просуванні товарів або послуг чужої торгової марки або бренду без відповідного дозволу).
                    </li>
                </ul>

            </p>
            <h3>Особливості використання</h3>
            <p style="color:black;">
                SkyService POS не вимагає Вас надати персональні дані, які дозволяють будь-яким чином ідентифікувати особу.<br /><br />
                Користувач приймає і погоджується, що використання SkyService POS здійснюється в межах і на основі принципів справедливості, розумності та добросовісності, закріплених діючими законами України та/або звичаями ділового обороту. <br /><br />
                Користувач розуміє і справжнім погоджується з тим, що SkyService POS використовується на умовах «як є» і «як доступно», без будь-яких гарантій, крім прямо зазначених у даних Умовах.<br /><br />
                SkyService POS може містити посилання на веб-сайти, програми, фото-, відео-, аудіо-, графічні і текстові матеріали, що належать третім особам. Користувач може переходити за посиланнями на веб-сайти третіх осіб, встановлювати програмне забезпечення таких третіх осіб і використовувати їхні матеріали виключно на свій страх і ризик.<br /><br />
                Ви погоджуєтесь, що SkyService POS може використовувати сторонніх постачальників, щоб забезпечити найкращу якість Послуг. Дії або бездіяльність цих сторонніх постачальників можуть бути поза контролем SkyService POS, і ми не несемо ніякої відповідальності за будь-які збитки чи шкоду, заподіяні в результаті якої-небудь дії або бездіяльності будь-якого стороннього постачальника.<br /><br />
                Користувач не має права використовувати SkyService POS, якщо законодавство України забороняє та/або встановлює інші, у тому числі вікові, обмеження на використання такого програмного забезпечення. В цьому випадку відповідальність за використання SkyService POS несе сам Користувач.
                Ми можемо видалити або блокувати дію Аккаунта будь-якого Користувача в будь-який час, якщо, на наш добросовісний погляд, Ви зробили істотне порушення цих Умов або будь-яких інших вимог чинного українського законодавства.<br /><br />
                Ви можете видалити свій Акаунт, слідуючи інструкціям, увійшовши до свого облікового запису через Додаток або на Сайті.<br /><br />
                Якщо Ваш Аккаунт видаляється: (а) всі надані Вами дані автоматично видаляються; (б) всі Ваші права, які надаються згідно з цими Умовами, негайно припиняються. Ваші дані не можуть бути відновлені при повторній реєстрації. SkyService POS не несе відповідальності за будь-який збиток або шкоду, які можуть виникнути в результаті видалення Вашого Акаунта, і це Ваша відповідальність переконатися, що вміст або дані, які ви хочете зберегти, були скопійовані до видалення.<br /><br />
                За використання SkyService POS стягується кожного місяця як абонентська. Зарахування які надходять на баланс користувача не можуть бути повернені.<br /><br />

            </p>
            <p style="color:black;">
                <!-- Система автоматично встановлює тариф згідно з тарифними планами <a href="https://skyservice.pro/uk/prices/">(посилання)</a>. Якщо після оплати тарифного плану у вас додався новий товар, технічні карти чи торгова точка, система автоматично перерахує ваш тарифний план згідно з вашим новим чинним тарифом без будь-яких попереджень у системі.<br /><br /> -->

                Система автоматично встановлює тариф згідно з <a href="https://skyservice.pro/uk/prices/" style="color: #42a144;font-family: 'Montserrat';">тарифними планами</a>. Якщо після оплати тарифного плану у вас додався новий товар, технічні карти чи торгова точка, система автоматично перерахує ваш тарифний план згідно з вашим новим чинним тарифом без будь-яких попереджень у системі.<br />
                Тариф може бути перерахований за актуальним курсом валюти у вашому регіоні.<br />
                При оплаті тарифу через особистий кабінет, або купівлі товару додається додаткова комісія у розмірі 2% від вартості підписки чи також 2,75% від вартості товару. Комісія стягується за зручність оплати онлайн.<br /><br />

                Технічна підтримка доступна у чаті чи через пошту, також ви можете зателефонувати в наш колл-центр де вам нададуть технічну підтримку згідно з компетенцією чи можливостями оператора, якщо оператор не може допомогти у телефонному режимі потрібно звернутися у чат технічної підтримки чи на пошту.<br /><br />

                Для кожного тарифу на місяць виділяється 50 хвилин спілкування через телефон з оператором, якщо цей час буде перевищено система автоматично може змінити на тариф на більш високий з урахуванням голосової технічної підтримки. Якщо вам потрібно індивідуальна технічна підтримка напишіть на пошту для встановлення індивідуального тарифу з технічною підтримкою через телефон. Ціни на індивідуальну технічну підтримку починаються від 50$ на місяць.<br />
                Під час звертання у технічну підтримку чи колл-центр, будь-ласка дотримуйтесь умов ділового спілкування. <br />
                Оператор чи менеджер може відмовити у подальшому спілкуванні якщо:<br />
                1. Використовується не цензурна лексика чи лайка<br />
                2. Вживаєте слова, щоб образити оператора<br />
                3. Інша не коректна поведінка, яка виходить за рамки ділового спілкування.<br />
                Також може бути збільшений тариф на наступний по тарифній сітці за не коректне спілкування з співробітником компанії.<br /><br />

                Всі тестові опції які були увімкнені можуть почати тарифікуватись без попереджень.
            </p>
            <h3>Історія транзакцій. Чеки</h3>
            <p style="color:black;">
                Історія транзакцій і чеків зберігається в базі даних SkyService POS, до якої Ви маєте доступ. Якщо з якоїсь причини Ви приймете рішення видалити свій Аккаунт, то такі відомості будуть видалені автоматично.<br /><br />
                Увага! Чек, який формується в Додатку при продажу товарів і надання послуг, носить лише інформаційний характер та використовується для надання статистичних даних при використанні Додатку, але при цьому може не мати всіх необхідних реквізитів, яких вимагає чинне законодавство України. Це виключно Ваша відповідальність, а не SkyService POS, видати розрахунковий документ відповідно до вимог чинного законодавства на вимогу особи.<br /><br />
                Ви самостійно несете відповідальність за надання повної і недвозначної інформації, як цього вимагає законодавство про захист прав споживачів або інший закон, який регулює дану сферу відносин.
            </p>
            <h3>Відсутність гарантій</h3>
            <p style="color:black;">
                Використання  SkyService POS не передбачає будь-яких гарантій, явних, обов'язкових або передбачених законом, включаючи (але не обмежуючись) гарантії відсутності порушень. використання  SkyService POS не гарантує, що: (i)  SkyService POS буде відповідати всім вашим вимогам; (ii)  SkyService POS буде безперервно, безпечно або без помилок працювати; (iii) результати, які можуть бути отримані від використання  SkyService POS, будуть точними і надійними; або (iv) якість будь-яких послуг, інформації чи інших матеріалів, що стали доступними вам під час використання  SkyService POS, буде відповідати усім вашим вимогам.
            </p>
            <h3>Обмеження відповідальності</h3>
            <p style="color:black;">
                Ви розумієте і згодні з тим, що, використовуючи  SkyService POS, ви самостійно несете відповідальність за будь-який непрямий, випадковий, спеціальний збиток або збитки, втрату прибутку, виключаючи (але не обмежуючись) втрату репутації, використання особистих даних або інших нематеріальних активів, на основі угоди, правопорушення, халатності, суворої відповідальності чи в інших випадках (але не обмежуючись) в результаті: (i) використання або неможливості використання  SkyService POS; (ii) витрат на придбання послуг, товарів, їхньої заміни та/або іншого, в результаті отриманих від  SkyService POS даних, інформації, повідомлень та/або іншого; (iii) несанкціонованого доступу або зміни ваших повідомлень чи даних; (iv) заяв або поведінки будь-якої третьої сторони при використанні  SkyService POS; або (v) будь-яких інших проблем, пов'язаних із використанням  SkyService POS.
            </p>
            <h3>Заборона шкідливого і незаконного використання</h3>
            <p style="color:black;">
                Ви не повинні: (а) використовувати SkyService POS будь-яким незаконним чином, для будь-яких незаконних або шахрайських цілей, або яким-небудь чином, несумісним з цимиУмовами; (б) порушувати наші права на інтелектуальну власність або будь-якої третьої особи при використанні SkyService POS; (в) використовувати SkyService POS для поширення будь-яких дискредитуючих або образливих матеріалів; (г) використовувати SkyService POS таким чином, який може вивести з ладу, порушити або послабити наші системи безпеки; (д) розкривати третім особам або поширювати інформацію, що належить іншим користувачам, якщо така стала доступною при використанні SkyService POS та/або використовувати інформацію будь-якого іншого користувача для яких-небудь маркетингових цілей, якщо Ви не маєте відповідного дозволу такого користувача; або (е) здійснювати інші дії, заборонені чинним законодавством України.<br /><br />
                При використанні SkyService POS забороняється розповсюдження рекламного матеріалу, що несе інформацію про лотереї; медичні послуги та медичні препарати; харчові добавки, дієтичні добавки; про політичні організації, партії, рухи і течії; інформацію про товари або послуги, які підлягають обов'язковому ліцензуванню/сертифікації, або інформацію про неповнолітніх осіб, конфіденційну інформацію про особу без її згоди на поширення такої інформації, або інформацію, яка надається у вигляді перехресного посилання (наприклад, надання інформації, джерелом якої не є Користувач чи інформації, зміст якої не відповідає тематиці повідомлень та/або не відповідає темі/торговій марці Користувача, і т.п.).<br /><br />
                Забороняється відправляти будь-які сповіщення за допомогою використання SkyService POS, що містять інформацію та/або мають ознаки реклами/інформації про алкогольну, тютюнову продукцію, знаки для товарів і послуг, інших об'єктів права інтелектуальної власності, під якими випускаються алкогольні напої та тютюнові вироби; нецензурні слова (лайка) і вирази, ознаки недостовірної, конфіденційної інформації; інформації, яка була отримана злочинним шляхом та/або яка порушує авторські або суміжні права третіх осіб; інформацію еротичного та/або порнографічного характеру; інформацію, що суперечить вимогам суспільної моралі; інформацію, що розпалює міжнаціональну або міжконфесійну ворожнечу, підбурює до етнічних, релігійних і інших конфліктів, що порушує основи державності і конституційного ладу та/або іншу інформацію, поширення якої заборонено законодавством України.

            </p>
            <h3>Інтелектуальна власність</h3>
            <p style="color:black;">
                SkyService POS і його локалізовані (перекладені на інші мови) версії, в тому числі вихідний та об'єктний код, графіка, інтерфейс користувачів, будь-які програмні елементи, музичний супровід та інше, складають та (або) містять в собі об'єкти інтелектуальної власності, виключне право на які належить їхнім законним правовласникам.<br /><br />
                Користувач погоджується, що використання SkyService POS не може бути розцінено як передача та (або) переуступка виняткового права на SkyService POS або його частини від правовласників SkyService POS до Користувача.<br /><br />
                Авторські права на матеріали, розміщені Користувачем в SkyService POS, належать Користувачеві, який створив їх, або іншим законним правовласникам.<br /><br />
                Категорично забороняється будь-яке використання SkyService POS, включаючи об'єкти інтелектуальної власності або їх частини, без попередньої письмової згоди правовласників, крім випадків, зазначених у цих Умовах.<br /><br />
                Товарні знаки третіх осіб, згадані в SkyService POS або її описі, належать їхнім законним правовласникам.
            </p>
            <h3>Заключні положення</h3>
            <p style="color:black;">
                Якщо одне або кілька положень даних Умов будуть в установленому порядку визнані недійсними згідно з судовим рішенням, що набрало законної сили, інші положення цієї Угоди залишаються в силі.<br /><br />
                Всі претензії, в тому числі претензії, пов'язані з будь-якою інформацією, змістом, товарами, даними, думками, порадами, заявами і т.і., що стали доступними в зв'язку з використанням SkyService POS, пред'являються особі, від якої виникла така інформація. У таких випадках SkyService POS не є належною стороною у разі виникнення будь-яких спорів, розбіжностей, конфліктів і їхнього вирішення. SkyService POS залишає за собою право, але не обов'язок, брати участь та/або сприяти справедливому вирішенню таких спорів, розбіжностей, конфліктів, як того вимагає закон або звичаї ділового обороту.<br /><br />
                Всі спірні питання за цими Умовами підлягають вирішенню шляхом переписки і переговорів з використанням обов'язкового досудового (претензійного) порядку.<br /><br />
                Усі питання, пов'язані з використанням SkyService POS, надсилайте на електронну пошту m@skyservice.pro
            </p>
        </div>
    </div>
    <div v-if="currentLang ==='en'">
        <h1 style="text-align: center">TERMS OF USE</h1>
        <div class="info-text">
            <h3>General</h3>
            <!-- <br/><br/> -->
            <p style="color:black;">
                Before you can use the services provided by SkyService POS, please read carefully these Terms of Use. These Terms of Use contain mandatory requirements that must be carried out when you use the www.SkyService.pro web site, as well as services, applications and content provided by SkyService POS.
                <br/><br/>
                When you register with and / or use SkyService POS in any way, including, but not limited to, accessing, or browsing the Site, you agree to all the terms and conditions set forth herein (hereinafter – the "Terms of Use"), which also include the Privacy Policy, Advertising Policy, and Cookies Policy, as well as all other conditions, rules or guidelines, which are periodically added to the Site as a reference and are updated by SkyService POS with no prior notice.
                <br/><br/>
                In addition, some services that are provided via SkyService POS may be subject to additional SkyService POS terms and conditions. The use of such services is governed by these additional terms and conditions, referenced in these Terms of Use.
                <br /><br />
                These Terms of Use were updated on 17 May 2017.
            </p>
            <h3>Definitions</h3>
            <p style="color:black;">
                Under these Terms of Use and the Privacy Policy, the following terms have the following meanings, unless the context otherwise requires:
                <br />
                <ul>
                    <li>
                        "SkyService POS" – the collection of Software, Applications, Sites, services, documents, text, graphics and other materials that make possible using the Services.
                    </li>
                    <li>
                        "Services" – services provided by means of the Site and / or SkyService POS applications.
                    </li>
                    <li>
                        "Application" means the software provided by SkyService POS, including the Merchant application, providing the Merchant with an opportunity to carry out his/her own loyalty program, to send special offers to the User and the User's application, which provides the services of SkyService POS, any updates, and any other software that enables one to use SkyService POS
                    </li>
                    <li>
                        "Site" means the www.SkyService.pro website, including, also, any other websites that will emerge in the future in accordance with these Terms of Use.
                    </li>
                    <li>"Content" – text, graphics, audio and video materials, documentation, information posted in SkyService POS (on the Site and / or in the Application), perceivable by a person or with the help of special devices.</li>
                    <li>“Merchant” – any person using the Merchant app, sales as well as the SkyService POS Site.</li>

                    <li>“User” – any person using the User application, SkyService POS Site and / or who has given the consent to the Merchant to be registered with SkyService POS.</li>

                    <li>"Rewards" – these are points, bonuses, discounts and other offers that are provided by the Merchant to the Users and which act exclusively in the shops of such Merchants.</li>

                    <li>"User Account" – a collection of user data stored in a computer system, necessary for his/her identification (authentication) and the provision of access to his/her personal data and settings in SkyService POS and which is assigned a unique identifier, ID-code, stored in the SkyService POS database.</li>

                    <li>"Personal information" –  information provided by the Merchant and / or User during registration (obtaining personal password / login), and at the creation of the User Account.</li>

                    <li>"You / Yours" refers to the User or the Merchant (where appropriate).</li>

                </ul>
            </p>
            <h3>Use of the Site and Services</h3>
            <p style="color:black;">
                The description of the Services provided by SkyService POS, as well as the links to the Applications is listed on the Site. In accordance with these Terms of Use, you can use the services independently and can not use them on behalf of and / or in the interest of third parties.<br /><br />

                Using the Site and browsing the Content does not require registration.<br /><br />

                The User can start using SkyService POS without establishing a User Account, by telling the Merchant his/her telephone number; but to use certain features of the Applications, one must be registered with the SkyService POS and create a User Account. By this, You guarantee that the registration information provided by You (including, among others, Your name ("User Name"), telephone number and password that You will use to access SkyService POS, is provided in good faith and does not infringe the rights of third persons. Failure to comply will be considered a violation of these Terms of Use, which may lead to the immediate deletion of your User Account from SkyService POS.<br /><br />

                The User can use the Application without confirming the e-mail address he/she specified (provided). The confirmation of the e-mail addresses can be completed at a convenient time, and is a prerequisite for the use of rewards accrued to him/her, if any.
                The use of the information obtained during the registration process is governed by the Privacy Policy.<br /><br />

                Persons under 13 years of age (or under another age established by law in the relevant jurisdiction) may not use SkyService POS. Persons under the age of majority, may use SkyService POS, with or without registration, only with the approval of a parent or guardian, unless otherwise provided by the law in the relevant jurisdiction.<br /><br />

                Your use of SkyService POS does not imply any responsibility by SkyService POS for the accuracy, availability, and reliability of any information, content, products, data, opinions, advice or statements that have become available due to the use of SkyService POS. This means that using SkyService POS, You alone are responsible for the information that has become available due to You, including text, photos, messages and other types of information, as well as compliance with all regulations and laws that may govern such a placement, publication, notification etc.<br /><br />

                The decision on the purchase of goods and / or agreement with the terms of receipt of Rewards offered by the Merchant are taken by Users independently and do not involve any direct or indirect approval and / or recommendations, as well as control, on the side of SkyService POS.<br /><br />

                All claims, including claims related to any information, content, products, data, opinions, advice, statements, etc., made available in connection with the use SkyService POS, shall be presented to the person who placed and / or published such information in SkyService POS. In such cases, SkyService POS does not purport a proper party in the event of any dispute, controversy, conflict, and conflict resolution. SkyService POS reserves the right, but not the obligation, to participate in and / or contribute to a just settlement of such disputes, disagreements, and conflicts, as required by law or the business practice.<br /><br />

            </p>
            <h3>User Account, password, and security</h3>
            <p style="color:black;">
            At registration, You yourself decide the information You want to tell about yourself. At the time of the registration of Your User Account, the system assigns You a unique ID-code to identify You as a Merchant and / or User. SkyService POS does not require your real name to use as the User name, but can ask the Merchant to provide information about its business, for example, such as the name of the business, its address, business phone number, tax identification number (or other similar identification number), including other information which can also be considered public, according to the law in force on the territory where the Merchant conducts his/her activity.<br /><br />

            Below are listed some obligations that you take up at registration and which are required for the registration of your User Account:<br /><br />

            Do not create a User Account for other persons without their permission.<br /><br />

            Do not use personal information of any person with the intent to impersonate that person.<br /><br />

            Do not use a nickname that is offensive, vulgar, obscene or illegal.<br /><br />

            If your account will be blocked, do not create another one without permission from SkyService POS.<br /><br />

            Persons under the age of 13 (or under another age prescribed by law in the relevant jurisdiction) may not use SkyService POS.<br /><br />

            The minors may be limited in using SkyService POS unless otherwise provided by the law in the relevant jurisdiction.<br /><br />

            You shall specify precise information when creating a User Account in order to avoid errors when using SkyService POS, as well as for maintaining appropriate relationships between the Merchant and the User.<br /><br />

            You will not grant access to Your User Account to unauthorized persons and will not perform any other actions that may endanger the safety of Your User Account.<br /><br />

            When You are choosing a user name or a similar means of identification for Your User Account, You agree that the User Account can be removed or have restricted access (for example, if the owner of a trademark (brand) complains about the person who violates his rights and interests by using his/her User Account the name of a known brand or trademark).<br /><br />

            Immediate identification, which may imply the exchange of personal data of the Merchant and / or User, occurs in real life, at the time of the sale of goods and services, in accordance with the requirements of the law and / or business practice, while for the purposes of using SkyService POS there is no need in personal identification.<br /><br />

            The information provided by the Merchant and / or User when creating User Accounts and receiving individual password / login may include the name, nickname, gender, company name, e-mail address, mobile phone number, work phone number, the address of the websites, photos and / or other information covered by the Privacy Policy. This information is provided on a voluntary basis in order to acquaintance the Merchant and the User for purposes of further cooperation on terms that they choose and to which they agree independently.<br /><br />

            You agree that Your User Account may be removed or have restricted access in the case of bad-faith use of SkyService POS, as well as other cases in accordance with these Terms of Use and / or provided for by law, or public morality.<br /><br />

            The data provided by You to register the User Account can be changed to your liking, with the exception of the email address specified at the registration of Your User Account.<br /><br />

            If you forget the password of your User Account, You can restore it by following the instructions sent to you by e-mail, at the address provided during Your registration. If the access to the e-mail specified during the User Account registration, for whatever reasons, is limited or not possible, then the password for this account can not be subject to recovery.<br /><br />

            You bear the sole responsible for the maintenance and security of Your password and / or mobile device, and You alone are responsible for all activities that involve Your User Account.<br /><br />

            You agree that, by providing any information when using SkyService POS, You confirm that You have that right. In this case, you will automatically pass to SkyService POS the non-exclusive, royalty-free and perpetual license, without territorial restrictions, to use this information in any manner (including, without limitation, editing, copying, modifying, transferring, reformatting, using for other materials, making derivative works, advertising, mass distribution and publishing of the content, in part or in whole, in any format or carrier).<br /><br />

            User Account information may be disclosed to a third party, if the third party lodges a reasoned complaint, in accordance with the legal requirements, that such information violates its copyright, right to confidentiality and / or legal requirements. For more information about the disclosure of personal information please see our Privacy Policy.<br /><br />

            You acknowledge and accept the lack of guarantees related to round the clock access to Your SkyService POS User Account, as well as the SkyService POS quality, accuracy, functionality, efficiency, due to unforeseen circumstances, scheduled maintenance works or for any other reason.<br /><br />

            You can report the unauthorized (without your knowledge) use of Your SkyService POS User Account or any other breach of security by contacting the support or using the feedback form. In this case, depending on the circumstances, measures are taken to either lockout or remove Your User Account.<br /><br />

            You can also complain about the owner of a User Account (for example, Merchant) if it does not correspond to reality. The number of complaints, in this case, is not the determining factor for carrying out any action in relation to a User’s Account; it is taken into account the validity of claims based on the violations of the law, business practices, dictates of public conscience.<br /><br />

            If You download or use the SkyService POS app, then You agree that from time to time, the application can download and install updates in order to improve and further enhance the quality of the work<br /><br />

            </p>
            <h3>Rewards</h3>
            <p style="color:black;">
                SkyService POS allows Merchants and persons authorized by the Merchant to edit and correct data about their items, services and / or SkyService POS activities, using and self-modeling a system of Rewards for the Users, hence between the Merchant and the Users arise relationships based on terms accepted by them individually, to agree or disagree with which is the exclusive right and responsibility of each party.<br /><br />

                All the Rewards, available in relation to the use of SkyService POS, are provided directly by the Merchant responsible for the given Rewards and are guaranteed exclusively for the corresponding goods or services by the given Merchant. The User can benefit of accumulated Rewards only at the Merchant who provided that for him/her.<br /><br />

                The Merchant who provides the Rewards shall be fully and solely responsible for ensuring the guaranteed conditions for the Users to receive the Rewards.<br /><br />

                You agree and are taking into account the fact that SkyService POS shall not be liable: a) if the Merchant, for any reason, refuses or is not able to fulfill the promise, and b) if the User has not been accrued rewards in connection with incorrectly specified information during the registration of the User Account.<br /><br />

                Merchant Account can be deleted, changed or blocked, either temporarily or permanently, with or without prior notice in the case of the existence of facts supporting the violation of the laws in the relevant jurisdiction. In this case, the Merchant is solely responsible for the violations he/she has committed.<br /><br />

                The above condition does not exclude the possibility of a User to lodge a complaint with the SkyService POS Support, but also does not guarantee its satisfaction, as for the settlement of any contentious relationship of the Merchant and User there can be provided a special settlement procedure. This also means that the relationships of the Merchant and / or User are based on the principles of fairness, reasonableness and / or the business practices, and in specific cases, on the basis of norms and provisions of existing laws, where the Merchant and the User are only accountable to one another.
                There is a monthly subscription for using SkyService POS. Crediting incoming to the user's balance cannot be returned.

            </p>
            <h3>Mobile services and notifications</h3>
            <p style="color:black;">
                SkyService POS includes certain features that are available via your mobile device, including (I) the ability to download mobile SkyService POS App, (II) the opportunity to get acquainted with the functioning of SkyService POS software, (III) the ability to access certain SkyService POS features via mobile Application.<br /><br />

                When using mobile devices, personal computers, tablets, and other devices that allow the use of SkyService POS, You shall take into account the quality of the services by the Internet service provider, the Internet speed. The Internet connection tariff and speed are determined by the Internet provider You chose.<br /><br />

                You understand and agree that by using SkyService POS, you can be subject to sending text messages, push-notifications (brief notifications popping-up on your mobile phone, informing about events and updates), and / or other notifications and that certain information about your use of mobile communications can be passed to SkyService POS.<br /><br />

                SkyService POS does not favor spam. Customers / Merchants using SkyService POS can be notified regarding the accrual / writing off of rewards, site updates, and maintenance works, as well as other notifications.<br /><br />

                Users may opt out of any notifications by Merchants by following the steps outlined in such notifications, or using the SkyService POS application settings.<br /><br />

                Merchant undertakes to use in good faith the opportunity of informational notification, that is not to abuse of such opportunity, and / or otherwise harass the User.<br /><br />

                Notifications and alerts sent by the Merchant shall comply with the Advertising Policy, as well as other rules and / or laws of the relevant jurisdiction for the violation of which the Merchant bears personal responsibility.<br /><br />

                The unwanted use or misuse of SkyService POS possibilities can lead to an immediate lockout or deletion of the Merchant and / or User Account without any reciprocal obligation for such actions.
                Otherwise, you assume any liability, if any.

            </p>
            <h3>The fee for the use of SkyService POS</h3>
            <p style="color:black;">
                In certain cases use of SkyService POS may require paying a subscription fee as described in the Pricing section of this website. Some subscriptions may include a free trial period. All prices given are exclusive of any and all taxes and payment of such taxes, when applicable, remains your responsibility. EU customers may be charged value-added tax (VAT).<br /><br />
                To pay for SkyService POS services, you must provide a valid credit or debit card. Accounts are billed on monthly or annual basis depending on the chosen billing cycle. All charges are non-refundable without exceptions. If you paid your subscription for a month (or any other period) in advance, SkyService POS will not provide any refund or credit for any remaining prepaid period of the subscription.<br /><br />
                You can cancel your subscription at any time. Upon cancellation, you can still use the part of Service provided for free. You are responsible for any charges you committed to or incurred before the cancellation. No refunds will be provided in case the cancellation occurs before the end of a prepaid period.<br /><br />
                Prices are subject to change at any time. SkyService POS will inform you of such changes by email and/or support chat 30 days in advance.
                SkyService POS reserves the right to discontinue or modify any part of the service on reasonable notice. Such notice may not be provided in case of emergencies of technical or other nature. Neither you or any third party shall hold SkyService POS liable for any price change, modification or discontinuation of the service.

            </p>
            <h3>Bona fides</h3>
            <p style="color:black;">
                You understand that the responsibility for all the information, including data, text, music, sound, photographs, graphics, video, messages or other materials ("Content"), whether publicly or privately posted, falls on the person who provided such Content. This means that You are responsible for all the Content that You, including, but not limited to, upload, post, email or otherwise transmit using SkyService POS.<br /><br />

                Using SkyService POS, you should not violate, in any way, the rights and interests of third parties protected by law. You understand and agree that no one can derive benefits from his/her illegal or dishonest behavior.<br /><br />

                Merchant and User interaction by means of SkyService POS should be based on honesty, good faith, rationality and respect for the interests of the other party.<br /><br />

                For example, the Merchant’s good faith in giving the promised rewards to the Users can be defined as "an honest purpose" and "sincere desire" to fulfill promises.<br /><br />

                When User are registered with the SkyService POS loyalty program by the Merchant, the latter guarantees the voluntary consent of such Users to participate in the SkyService POS loyalty program, including their acceptance of the Terms of Use and Privacy Policy and accepts full responsibility, if any, regardless of the circumstances that caused it, in relation to such registration.<br /><br />

                Under no circumstances is SkyService POS responsible for any materials posted by Merchants and / or Users, including for any errors or omissions in any Content, or for any loss or damage of any kind incurred as a result of misuse of such Content via SkyService POS and / or third parties.<br /><br />

                If you become aware of any violations that may be considered offensive, indecent or objectionable, violating Your rights, as well as the rights and interests of third parties, you may submit a reasoned appeal to SkyService POS support service, describing the relevant facts. In this case, SkyService POS has the right (but not the obligation), in its sole discretion, to take appropriate actions, depending on the circumstances.<br /><br />

                In the case of misuse, You agree to indemnify SkyService POS any damages on the basis of the claims, and the costs of court decisions related to compensation and the payment of taxes or other mandatory payments, material and moral damages, and other costs (including, but not limited to, attorneys' and other professionals’ fees), arising out of or in any way connected with Your use SkyService POS, due to Your violation of these Terms of Use or violation of any rights of any other party.
            </p>
            <h3>Prohibited uses</h3>
            <p style="color:black;">
                You agree that you will not use SkyService POS to perform any type of illegal activity or to take any action that may adversely affect SkyService POS. You agree to shy away from the following activities, as well as involving a third party into that, namely: (1) attempts to gain unauthorized access to SkyService POS or other User / Merchant Accounts, (2) attempts to circumvent any security features, (3) actions aimed at violating the laws or these Terms of Use, Privacy Policy and Advertising Policy (4) reproduce, duplicate, copy, sell or resell SkyService POS for any purposes whatsoever, (5) participate in any activity that is offensive or prevents / impedes SkyService POS to progress. If your account has been blocked or the access to it has been limited, you agree not to carry out any action to circumvent such blocking. It is prohibited to use SkyService POS in conjunction with any agreements related to illicit goods and / or services.<br /><br />

                In the case of violation of the above conditions, You agree and bear in mind that Your account may be temporarily blocked or permanently deleted, or otherwise limited to use SkyService POS, without any reciprocal obligations on the part of SkyService POS.

            </p>
            <h3>Intellectual property rights</h3>
            <p style="color:black;">
                You acknowledge and agree that SkyService POS may have Content that is protected by copyright, patent, trademark, trade secret or other proprietary rights and laws governing such legal relations.<br /><br />

                Except as expressly provided by the Terms and Conditions, You agree not to use such Content in bad faith, in whole or in part, including, but not limited to, because such use would be in direct violation of copyright rights and interests, the rights and interests of other Users and / or Merchants.<br /><br />

                Any use of SkyService POS, except as specifically stated in these Terms of Use, is prohibited. Technology and computer software, applications, content, design, images and the like underlying or relating to SkyService POS, are protected by copyright and other laws. You agree not to carry out the following actions, namely: copy, modify, create derivative works of, reverse engineer, disassemble or otherwise attempt to derive the source code, sell, assign, sublicense or otherwise transfer rights to SkyService POS. Any rights not expressly granted herein are reserved to SkyService POS. No part of these Terms of Use shall be construed as granting, by implication, or otherwise, any license or right to use intellectual property rights for SkyService POS products or parts thereof.<br /><br />

                By posting on the Site or SkyService POS application information on trademarks and other data protected by copyright, You represent and warrant the availability of appropriate rights for this placement.

            </p>
            <h3>Third-party websites</h3>
            <p style="color:black;">
                SkyService POS may contain links to other sites and resources on the Internet, and such sites or resources could conceivably contain links to the SkyService POS Site. The Terms of Use and Privacy Policy of SkyService POS do not apply to such sites or resources; those are governed by the terms of use and privacy policies of such sites or resources. When you go to the sites and the resources of third parties, you do so at your own risk. You agree and acknowledge that SkyService POS cannot and is not responsible for the content, functions, accuracy, legality, suitability and other parameters of such sites or resources. The presence of any such link does not imply endorsement or recommendation by the SkyService POS to use them. You understand and agree that SkyService POS bears no direct or indirect responsibility for any loss or damage caused or allegedly caused by the use or reliance on any content, goods or services available on such third party site or resource. <br /><br />

                Any agreements which actually occur between the Merchants and Users, resulting in the use of SkyService POS loyalty programs, are carried out voluntarily and at your own risk, for which SkyService POS is not liable for any losses or claims, if any.
            </p>
            <h3>Use of cookies</h3>
            <p style="color:black;">
                Please note that by using SkyService POS, You agree that we may use cookies in accordance with the terms of Cookies Policy.<br /><br />

                Cookies – these are small files consisting of letters and digits that are downloaded onto your computer or mobile device when you visit the Site or use a SkyService POS application. Then the cookies are sent back to the website each subsequent visit, or to any website that recognizes the cookie. The use of cookies is convenient, anonymous and does not contain any personal information about You.
            </p>
            <h3>Disclaimer of warranties</h3>
            <p style="color:black;">
                The use of the SkyService POS software is carried out at your own risk. the software is provided "as is" and "as available". the use of SkyService POS does not provide any warranties, express, implied, or statutory, including (without limitation) implied non-infringement warranties. the use of SkyService POS does not provide any warranties that (i) skyservice pos will meet all your requirements, (ii), skyservice pos will work in an uninterrupted, secure, or error-free fashion, (iii) the results that may be obtained from using SkyService POS, will be accurate or reliable or (iv) the quality of any products, services, information, or other material purchased from merchants using SkyService POS will meet all your requirements.
            </p>
            <h3>Disclaimer</h3>
            <p style="color:black;">
                You understand and agree that by using SkyService POS, you alone are responsible and shall not hold SkyService POS liable for any indirect, incidental, special damages, or damages for loss of profits, including (without limitation) loss of reputation, use of personal data or other intangibles on the basis of the agreement, offenses, negligence, strict liability or otherwise (without limitation), resulting from: (i) the use or inability to use skyservice pos; (ii) spending on goods, their replacement and / or other as a result of data, information, communications received from skyservice pos and / or otherwise; (iii) unauthorized access to or alteration of your communications or data; (iv) statements or conduct of any third party using SkyService POS; or (v) any other matter relating to the use of SkyService POS. <br /><br />

                The above conditions are caused by the fact that SkyService POS, designed for the convenience and ease of the User–Merchant relationships is not a guide to action, and does not control the good faith of its use; therefore, the use of the functionality and capabilities of SkyService POS is the sole responsibility of the User and the Merchant, individually.
            </p>
            <h3>Disputes</h3>
            <p style="color:black;">
                SkyService POS does not welcome any disputes between Users and Merchants, which lead to differences in the use of SkyService POS, but does not exclude the possibility that such may occur.<br /><br />

                You agree that by using SkyService POS, You shall be solely responsible for any action in relation with such use.<br /><br />

                In the event of a disputed relationship, including the relationship between the Merchant and the User, SkyService POS bears no responsibility or liability with regard to any party in their decision. However, SkyService POS reserves the right, but not the obligation, to participate in any of these disputes aiming to achieve reconciliation, to seek the truth and justice, as well as to bring to justice the responsible ones, if any.
            </p>
            <h3>Support and feedback</h3>
            <p style="color:black;">
                Please contact us at m@skyservice.pro, to receive answers to your questions or to make suggestions.
            </p>
            <h3>Contact information</h3>
            <p style="color:black;">
                SkyServicePOS OÜ <br />
                Randla street 13-201, North Tallinn district, Tallinn city, Harjumaa, 10315 , Republic of Estonia<br />
                m@skyservice.pro
            </p>
        </div>
    </div>
  </div>
</template>

<script>
import { store } from "@/store.js";
import SelectLangs from '../LangSelectComponent.vue';
import headloginreg from "../heads/HeaderLoginAndReg.vue";
export default {
  components: {
    headloginreg,
    SelectLangs
  },
  data(){
      return{
        currentLang: "",
        language: [
                { name: 'uk', text: 'UK' },
                { name: 'ru', text: 'RU' },
                { name: 'en', text: 'EN' }
            ],
        currentSpan: {
            descUK: 'Авторизація',
            descRU: 'Авторизация',
            descEN: 'Authorization'
        }
      }
  },
  created() {
        // костыль для политик
        switch(store.lang){
            case "ru":  
            case "uk":
            case "en":
                this.currentLang = store.lang;
                break;
            default:
                this.currentLang = 'en';
                break;

        }
  },
  methods: {
    changeLang(l) {
        localStorage.setItem("lang_user", l);
        store.lang = l;
        window.lang = store.langs[store.lang]();
        this.currentLang = l
    },
    goLogin() {
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.currentSpan{
    cursor: pointer;
    color:#106090;
    margin-top: 12px;
}
.custom-select{
    cursor: pointer;
}
.special {
    display: flex;
    justify-content: space-between;
    height: 35px;
}
body{
    overflow: scroll;
}
</style>